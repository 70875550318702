import Select from 'components/FormComponents/Select/Select'
import ModalPopup from 'components/ModalPopup/ModalPopup'
import { useState } from 'react'
import { useCameraGroupData, columnAcessors } from './useCameraGroupData'
import { CameraRecordingDataRow } from 'lib/types'
import { LensType, ModalPopupSize } from 'lib/enums'
import { getDefaultRecordingDataRow } from 'reducers/api/cameraRecording/cameraRecordingSlice'
import './bulkApplyModal.scss'

const { quantity, fps, motion, complexity, resolution, bitrateKbit, lensTypes } = columnAcessors
const defaultValues = getDefaultRecordingDataRow()

const BulkApplyModal = ({ isModalDisplayed, displayModal, workspaceId, updateCameraRecording }: BulkApplyModalProps) => {
  const [selectedBulkApplyColumn, setBulkApplyColumn] = useState(quantity)
  const [bulkApplyValue, setBulkApplyValue] = useState(defaultValues[quantity])
  const { bulkApplyElements, bulkApplyOptions, cameraRecordings } = useCameraGroupData(workspaceId)

  const applyBulkSettings = () => {
    for (const recording of cameraRecordings) {
      if (cannotBulkApply(recording)) {
        continue
      }
      
      const recordingToUpdate = {
        ...recording,
        [selectedBulkApplyColumn]: isComplexDataCell(selectedBulkApplyColumn) ? { continuous: bulkApplyValue, motion: bulkApplyValue } : bulkApplyValue
      }
      updateCameraRecording(recordingToUpdate, selectedBulkApplyColumn)
    }
    displayModal(false)
  }

  const isComplexDataCell = (columnAcessor: string): boolean => {
    return [fps, motion, complexity, resolution, bitrateKbit].includes(columnAcessor)
  }

  const cannotBulkApply = (recording: CameraRecordingDataRow): boolean => {
    return selectedBulkApplyColumn === lensTypes && (recording.lensTypes === LensType.MultiLensChild || recording.lensTypes === LensType.MultiLensParent) && bulkApplyValue === LensType.MultiLensParent
  }

  const handleBulkApplySelector = (selectedColumnAccessor: string) => {
    setBulkApplyColumn(selectedColumnAccessor)
    setBulkApplyValue(getDefaultPropertyValue(selectedColumnAccessor))
  }

  const getDefaultPropertyValue = (accessor): string => {
    return defaultValues[selectedBulkApplyColumn].motion === undefined ? defaultValues[accessor] : defaultValues[accessor].motion 
  }

  return (
    <ModalPopup
      size={ModalPopupSize.Small}
      showModal={isModalDisplayed}
      onCloseButtonclick={() => displayModal(false)}
      button1Label="Apply"
      headline="Bulk apply setting"
      description="Choose the setting you want to edit and choose a value for it. This value will be applied to all your created camera groups."
      button1OnClickHandler={applyBulkSettings}>
      <div className="my-8">
        <Select className="xsd-bulk-apply-select pb-4" options={bulkApplyOptions} handleDataChange={handleBulkApplySelector} />
        {bulkApplyElements[selectedBulkApplyColumn](setBulkApplyValue, getDefaultPropertyValue(selectedBulkApplyColumn))}
      </div>
    </ModalPopup>
  )
}

type BulkApplyModalProps = {
  isModalDisplayed: boolean
  displayModal: (state: boolean) => void
  workspaceId: number
  updateCameraRecording: (recording: CameraRecordingDataRow, columnId: string) => void
}

export default BulkApplyModal
