import { EditableTextCell } from "components/Table/EditableTextCell"
import Table from "components/Table/Table"
import { useNavigate, useParams } from "react-router-dom"
import { deleteSiteDesign, duplicateSiteDesign, getSiteDesigns, postSiteDesign, putSiteDesign } from "reducers/api/siteDesign/siteDesignActions"
import { selectSiteDesigns, selectSiteDesignStatus } from "reducers/api/siteDesign/siteDesignSlice"
import { useApiData, useAppDispatch } from "reducers/hooks"
import { NavigateAction } from "components/Table/NavigateAction"
import { getSitesThunk } from "reducers/api/sites/action"
import { getWorkspaces } from "reducers/api/workspace/workspaceActions"
import { DeleteRowAction } from "components/Table/DeleteRowAction"
import BackButton from "components/Button/BackButton"
import { selectSites, selectStatus } from 'reducers/api/sites/sitesSlice'
import { DuplicateRowAction } from "components/Table/DuplicateRowAction"
import axios from "axios"
import { API_URL } from "config"
import { postCameraGroup } from "reducers/api/cameraRecording/cameraRecordingActions"
import { defaultOptionsSetup } from "reducers/api/optionsSetup/optionsSetupActions"

const SiteDesign = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const search = useParams()
    const { projectId, siteId } = useParams()
    const projectIdNumber = parseInt(projectId)
    const siteIdNumber = parseInt(siteId)
    const siteDesigns = useApiData(getSiteDesigns({ projectId: projectIdNumber, siteId: siteIdNumber }), selectSiteDesigns(projectIdNumber, siteIdNumber), selectSiteDesignStatus(projectIdNumber, siteIdNumber))
    const sitesData = useApiData(getSitesThunk(projectId), selectSites(projectId), selectStatus(projectId));
    const workspaces = useApiData(getWorkspaces(), state => state.workspace.workspaces, state => state.workspace.workspacesStatus)
    const columns = [
        {
            Header: "actions", Cell: ({ row, deleteClickHandler, duplicateClickHandler }) =>
            <div className="row-action-container">
                    <DeleteRowAction rowIndex={row.index} deleteClickHandler={deleteClickHandler} />
                    <DuplicateRowAction rowIndex={row.index} duplicateClickHandler={duplicateClickHandler} />
                    <NavigateAction url={`/site-design-editor/workspace/${workspaces[row.original.id]?.workspaceId}`} />
                </div>
        },
        { Header: "Name", accessor: "name", Cell: EditableTextCell },
        { Header: "Created", accessor: "formattedCreatedUtc" },
        { Header: "Modified", accessor: "formattedUpdatedUtc" },
        { Header: "Site", accessor: "site.name" },
    ]

    const navigateBackToProjects = () => {
        navigate(`/project/${projectId}/site`);
    }

    const addSiteDesign = () => {
        dispatch(postSiteDesign({
            projectId: projectIdNumber, siteId: siteIdNumber, dispatch, SiteDesignInsertPayload: {
                name: "Site Design " + (siteDesigns.length + 1)
            }
        })).then(postSiteDesignAction => {
            if (postSiteDesignAction.meta.requestStatus === "fulfilled") {
                insertCameraAndServerSetup(postSiteDesignAction.payload.data.id)
            }
        })
    }

    const updateSiteDesign = (siteDesign) => {
        dispatch(putSiteDesign({ projectId: projectIdNumber, siteId: siteIdNumber, siteDesign, dispatch }))
    }

    const removeSiteDesign = (designId: string) => {
        dispatch(deleteSiteDesign({ projectId: projectIdNumber, siteId: siteIdNumber, designId: siteDesigns.find(design => design.id === parseInt(designId)).id }))
    }

    const duplicate = (siteDesignId: number) => {
        dispatch(duplicateSiteDesign({ projectId: projectIdNumber, siteId: siteIdNumber, siteDesignId }))
            .then(() => dispatch(getWorkspaces()))
    }

    const insertCameraAndServerSetup = (siteDesignId: number) => {
        dispatch(getWorkspaces()).then(async getWorkspacesAction => {
            if (getWorkspacesAction.meta.requestStatus === "fulfilled") {
                const newWorkspace = getWorkspacesAction.payload.data.find(workspace => workspace.siteDesignId === siteDesignId)
                if (newWorkspace != null) {
                    const cameraConfigResponse = await axios.post(
                        `${API_URL()}/user/workspace/${newWorkspace.id}/cameraconfig`,
                        { name: "" }
                      );
                      await axios.put(`${API_URL()}/user/workspace/${newWorkspace.id}/optionssetup`, defaultOptionsSetup)
                      dispatch(postCameraGroup({ workspaceId: newWorkspace.id, cameraConfigId: cameraConfigResponse.data.id }))
                }
            }
        })
    }

    return (
        <div className="container msds-block-padding-small">
            <div className="row">
                <div className="col-12">
                    <BackButton id={search.siteId} onClickHandler={navigateBackToProjects} label={"Back to Sites"}></BackButton>
                </div>
                <h1 className="msds-text-header-1">{sitesData.find(site => site.id.toString() === siteId)?.name}</h1>
                <Table
                    addButtonLabel="Add Site Design"
                    addButtonLabelIcon="plus"
                    initialData={siteDesigns}
                    tableColumns={columns}
                    handleAddDataRow={addSiteDesign}
                    handleUpdateDataRow={updateSiteDesign}
                    handleDeleteDataRow={removeSiteDesign}
                    handleDuplicateRow={duplicate}
                    deleteModalHeadline="Are you sure you want to delete this site design?" 
                    deleteModalDescription="By deleting this site design, you will also remove this site design's configurations"
                    />
            </div>
        </div>
    )
}

export default SiteDesign