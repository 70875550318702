import Routing from "routing/Routing";
import "./app.scss";
import "@milestone-sys/web-design-system/main.css";
import Navigation from "components/Navigation/Navigation";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";

function App() {
  return (
    <div className="app">
      <Navigation />
      <Routing />
      <Footer />
      <Loader />
    </div>
  );
}

export default App;
