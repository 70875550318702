import Input from "components/FormComponents/Input/Input"

// Create an editable cell renderer
export const EditableTextCell = ({
    value,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    isDisabled,
    label,
    inputCreator
}: EditableTextCellProps) => {
    const handleInputChange = value => {
        if (inputCreator) {
            updateMyData(index, id, inputCreator(value))
        }
        else {
            updateMyData(index, id, value)
        }
    }

    return <Input label={label} isSmall initialValue={value} isDisabled={isDisabled} handleDataChange={handleInputChange}/>
}

type EditableTextCellProps = {
    value?: string
    row: { index: number }
    column: { id: number }
    updateMyData: (index: number, id: number, value: string) => void
    isDisabled?: boolean
    label?: string
    inputCreator?: (inputValue: string) => any
}