import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { API_URL } from 'config'
import { SiteType } from 'lib/types'

export const getSitesThunk = createAsyncThunk<
  SiteType[],
  string, // args type
  { rejectValue: string } // rejection response value type
>('sites/getSites', async (projectId, { rejectWithValue }) => {
  try {
    const apiResponse = await axios.get<SiteType[]>(`${API_URL()}/project/${projectId}/site`)
    return apiResponse.data
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export const deleteSiteThunk = createAsyncThunk<
  { error?: string }, // response type
  { projectId: string; siteId: string }, // args type
  { rejectValue: string } // rejection response value type
>('sites/deleteSite', async (deleteSiteData, { rejectWithValue }) => {
  try {
    await axios.delete(`${API_URL()}/project/${deleteSiteData.projectId}/site/${deleteSiteData.siteId}`)
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export const postSiteThunk = createAsyncThunk<
  SiteType, // response type
  { projectId: string; siteName: string }, // args type
  { rejectValue: string } // rejection response value type
>('sites/postSite', async (postSiteData, { rejectWithValue }) => {
  try {
    const apiResponse = await axios.post<SiteType>(`${API_URL()}/project/${postSiteData.projectId}/site`, { name: postSiteData.siteName })
    return apiResponse.data
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export const updateSiteThunk = createAsyncThunk<
  SiteType, // response type
  SiteType, // args type
  { rejectValue: string } // rejection response value type
>('sites/updateSite', async (siteData: SiteType, { rejectWithValue }) => {
  try {
    const apiResponse = await axios.put(`${API_URL()}/project/${siteData.project.id}/site/${siteData.id}`, siteData)
    return apiResponse.data
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export const duplicateSite = createAsyncThunk<SiteType, { siteId: number; projectId: number }, { rejectValue: string }>('sites/duplicate', async ({ siteId, projectId }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${API_URL()}/project/${projectId}/site/${siteId}/copy`)
    return response.data
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})
