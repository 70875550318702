import { createAsyncThunk } from "@reduxjs/toolkit"
import axios, { AxiosError } from "axios"
import { API_URL } from "config"
import { SiteDesignType } from "lib/types"
import { getWorkspaces } from "../workspace/workspaceActions"

export type SiteDesignGetPayload = {
    data?: SiteDesignType[],
    projectId: number,
    siteId: number,
    error?: string
}

export const getSiteDesigns = createAsyncThunk<SiteDesignGetPayload, { projectId: number, siteId: number }, { rejectValue: SiteDesignGetPayload }>("siteDesign/getSiteDesigns",
    async (args, { rejectWithValue }) => {
        try {
            const apiResponse = await axios.get<SiteDesignType[]>(`${API_URL()}/project/${args.projectId}/site/${args.siteId}/sitedesign`)
            return { data: apiResponse.data, projectId: args.projectId, siteId: args.siteId }
        } catch (err) {
            const error: AxiosError<string> = err;
            return rejectWithValue({ error: error.message, projectId: args.projectId, siteId: args.siteId })
        }
    })

export type SiteDesignInsertPayload = {
    data?: SiteDesignType,
    projectId: number,
    siteId: number,
    error?: string
}

export const postSiteDesign = createAsyncThunk<SiteDesignInsertPayload, { projectId: number, siteId: number, SiteDesignInsertPayload: {}, dispatch }, { rejectValue: SiteDesignInsertPayload }>("siteDesign/postSiteDesigns",
    async (args, { rejectWithValue }) => {
        try {
            const siteDesignResponse = await axios.post<SiteDesignType>(`${API_URL()}/project/${args.projectId}/site/${args.siteId}/sitedesign`, args.SiteDesignInsertPayload)

            return { data: siteDesignResponse.data, projectId: args.projectId, siteId: args.siteId }
        } catch (err) {
            const error: AxiosError<string> = err;
            return rejectWithValue({ error: error.message, projectId: args.projectId, siteId: args.siteId })
        }
    })

export type SiteDesignUpdatePayload = {
    data?: SiteDesignType,
    projectId: number,
    siteId: number,
    error?: string
}

export const putSiteDesign = createAsyncThunk<SiteDesignUpdatePayload, { projectId: number, siteId: number, siteDesign: SiteDesignType, dispatch }, { rejectValue: SiteDesignUpdatePayload }>("siteDesign/putSiteDesigns",
    async (args, { rejectWithValue }) => {
        try {
            const apiResponse = await axios.put<SiteDesignType>(`${API_URL()}/project/${args.projectId}/site/${args.siteId}/sitedesign/${args.siteDesign.id}`, args.siteDesign)
            args.dispatch(getWorkspaces())
            return { data: apiResponse.data, projectId: args.projectId, siteId: args.siteId }
        } catch (err) {
            const error: AxiosError<string> = err;
            return rejectWithValue({ error: error.message, projectId: args.projectId, siteId: args.siteId })
        }
    })

export type SiteDesignDeletePayload = {
    designId: number,
    projectId: number,
    siteId: number,
    error?: string
}

export const deleteSiteDesign = createAsyncThunk<SiteDesignDeletePayload, { projectId: number, siteId: number, designId: number }, { rejectValue: SiteDesignDeletePayload }>("siteDesign/deleteSiteDesigns",
    async (args, { rejectWithValue }) => {
        try {
            const apiResponse = await axios.delete<SiteDesignType>(`${API_URL()}/project/${args.projectId}/site/${args.siteId}/sitedesign/${args.designId}`)
            return { data: apiResponse.data, projectId: args.projectId, siteId: args.siteId, designId: args.designId }
        } catch (err) {
            const error: AxiosError<string> = err;
            return rejectWithValue({ error: error.message, projectId: args.projectId, siteId: args.siteId, designId: args.designId })
        }
    })

export const duplicateSiteDesign = createAsyncThunk<SiteDesignInsertPayload, { projectId: number, siteId: number, siteDesignId: number }, { rejectValue: string }>("siteDesign/duplicate",
    async ({ projectId, siteId, siteDesignId }, { rejectWithValue }) => {
        try {
            const siteDesignResponse = await axios.post<SiteDesignType>(`${API_URL()}/project/${projectId}/site/${siteId}/sitedesign/${siteDesignId}/copy`)

            return { data: siteDesignResponse.data, projectId, siteId: siteId }
        } catch (err) {
            const error: AxiosError<string> = err;
            return rejectWithValue(error.message)
        }
    })