import { useNavigate } from 'react-router-dom';
import BackButton from 'components/Button/BackButton'

export const ReleaseNotes = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    return (

        <div className="container msds-block-padding-small">
            <div className="row">
                <div className='col-12 mb-6'>
                    <BackButton id={"goBack"} onClickHandler={goBack} label={"Back"}></BackButton>
                </div>
                <div className='col-12'>
                    <div className='msds-text-display-3-regular msds-text-display-3 msds-text-gray-10 mb-9'>Release Notes</div>

                    <div className='msds-text-header-6 msds-text-gray-10 mb-6'>October 9th, 2023</div>
                    <div className="msds-divider msds-divider--gray-5 mb-6"></div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>What's New</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        The new server calculator developed by Milestone is an innovative tool which has been designed to empower Integrators and presales, providing them with a feature-rich and simplistic approach to solution design.
                        <br /> <br />
                        We have listened to your feedback and had the primary objective to create an efficient, effective solution tool, that can take away the burden of designing server solutions.
                        <br /> <br />
                        To achieve this ambitious goal, we implemented a design change with carefully constructed user input elements, that default to best practice and logical choices. Coupled with a much-simplified user experience (UX) flow. This means that the tool is fast, intuitive, and easy to use, allowing users to quickly input the necessary information and generate results.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Realtime</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        Furthermore, we now have a <b>real-time</b> design proposal feature. This dynamic and responsive nature of the calculator allows users to experiment with different scenarios and configurations on the fly. You can adjust variables, such as camera specifications, or server variables and witness how these modifications affect the proposed solution in real-time. By providing instant feedback, the calculator empowers users to make informed decisions and fine-tune their designs effortlessly.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Lens Type</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        When creating a new camera group the default is single lens, but you can now select Multi lens, which allows you to select additional streams. The benefit here is the overall output indicates the correct camera count whilst taking all of the streams into account performance and storage wise.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Defaults</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        For simplicity, and speed, the servers TAB has default logic built in,  which means a standard new project assumes that the user wants an all-in-one server without archiving, with spinning hard disks and a RAID 5. If you select archiving then the defaults dynamically adjust to a RAID 10 for the live array with SSD disks, and a RAID 5 with spinning disks for the archive. It is possible to manually tweak and change these settings.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>UI Tab for the Recording parameters</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        This is a new simple UI design, where a simple drop down allows you to quickly decide which recording parameter should be used for each camera group.
                        <br /> <br />
                        Options are constant, motion based and speedup.
                        <br /> <br />
                        Speedup is redesigned, and provides 2 entries for frame rate “FPS”. Baseline is the constant slow rate (here we recommend 1 FPS, also known as keyframe recording), and then your speedup FPS of choice. The reason setting 1 FPS as your desired FPS, is best, is due to how H.264/5 works. 1 FPS gives your desired FPS results a smooth transition. Where any other FPS, say 5, can result in a reinitialization of the stream, and a small blip in the recording.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Bulk Apply</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        Now, apply specific camera settings to all camera groups simultaneously with ease. This feature is a time-saver, especially when re-editing a design where changes like retention period or other settings need to be uniformly applied to all cameras.
                        <br /> <br />
                        For instance, if you need to modify the retention period across all camera groups, the Bulk Apply feature allows you to make this adjustment swiftly and efficiently, ensuring consistency across all settings.
                        <br /> <br />
                        Explore the available options and see how the Bulk Apply feature can streamline your workflow. Refer to the image below for a detailed view of the available options.
                        <br /> <br />
                        <img className='d-block mx-auto' src={require("./Images/bulk-apply.png")} alt={"Bulk Apply"} />
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Projects</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        This enhancement allows designs of large-scale projects encompassing multiple locations and sites.
                        <br /> <br />
                        The intention is that each site and server design use a suitable naming convention which correlates with its purpose.
                        <br /> <br />
                        Refer to the image below to visualize the concept and gather inspiration for implementing this innovative feature in your projects.
                        <br /> <br />
                        Note, a fast-track project start will be implemented for small projects.
                        <br /> <br />
                        <img className='d-block mx-auto' src={require("./Images/projects.png")} alt={"Projects"} />
                        <br /> <br />
                    </div>

                </div>

                <div className='col-12 mb-9'>
                    <div className='msds-text-display-3-regular msds-text-display-3 msds-text-gray-10 my-6'>Release caveats</div>

                    <div className="msds-divider msds-divider--gray-5 mb-6"></div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>SSD Disks for Live</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        When selecting SSD disks for the Live database, the engine can list a lower disk quantity than it should. We often see 2 SSD Disks proposed, and a RAID 10. However, a RAID 10 requires a minimum quantity of 4 disks.
                        <br /> <br />
                        This issue will be fixed, however for the time being, please double check with presales when selecting SSD for Live.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Storage overhead</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        We recommend that you add in suitable storage buffer, such as 10-15% as an example, especially in cases, where your aware that a site will grow in the future.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>CPU Models</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        Please note that the current CPU models in the calculator are due for an update. There are some old generation Intel CPU models that are being recommended. If in doubt which substitute model to use, please reach out to presales. New performance metrics, and New CPU models will be coming in the future once testing is complete.
                        <br /> <br />
                        A message displaying this limit is displayed. This will be made dynamic in the near future.
                        <br /> <br />
                    </div>
                </div>
            </div>
        </div>
    )
}
