import { ButtonGroupAlignment } from "lib/enums";
import { ModalPopupProps } from "lib/types";
import classNames from "classnames";
import Button from "components/Button/Button";
import MsdsModal from "./MsdsModal";

const ModalPopup = ({
  id,
  headline,
  description,
  size,
  showModal,
  onCloseButtonclick,
  button1Style,
  button1Size,
  button1Label,
  button1OnClickHandler,
  button2Style,
  button2Size,
  button2Label,
  button2OnClickHandler,
  buttonGroupAlignment,
  children
}: ModalPopupProps) => {

  const content = (
    <div className={classNames("msds-modal__content", size ? size : "")} >
      <div className="msds-modal__headline msds-text-header-2 msds-text-gray-10">
        {headline}
      </div>
      <div className="msds-modal__description msds-text-body-1 msds-text-gray-9">
        {description}
      </div>
      {children}
      <div className={classNames("msds-btn-group mt-8", buttonGroupAlignment ? buttonGroupAlignment : ButtonGroupAlignment.Center)}>
        {button2Label && <Button id="button2Id"
          label={button2Label}
          size={button2Size}
          styling={button2Style}
          onClickHandler={button2OnClickHandler}
        />}
        <Button id="button1Id"
          label={button1Label}
          size={button1Size}
          styling={button1Style}
          onClickHandler={button1OnClickHandler}
        />
      </div>
    </div>
  );

  return (
    <MsdsModal id={id} content={content} showModal={showModal} onCloseButtonclick={onCloseButtonclick} />
  );
};

export default ModalPopup;
