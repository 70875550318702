import './proposals.scss'
import '../print.scss'
import { ServerRoles, StorageRoles } from 'lib/enums'
import { Server, Storage } from 'lib/types'
import { getOptionsSetup } from 'reducers/api/optionsSetup/optionsSetupActions'
import { getCalculation } from 'reducers/api/workspace/workspaceActions'
import { useApiData } from 'reducers/hooks'
import { WorkspaceDetails } from 'reducers/api/workspace/workspaceSlice'
import { toMbValueString, toGbValueString, toTbValueString, quantityLabel } from 'lib/helpers'
import { getCameraRecording } from 'reducers/api/cameraRecording/cameraRecordingActions'

const ProposalsPdf = ({ workspace }: ProposalsProps) => {
    const { workspaceId } = workspace ?? {}
    let totalCamerasPerServer;

    const cameraRecordings = useApiData(
        getCameraRecording(workspaceId),
        state => state.cameraRecording.data[workspaceId],
        state => state.cameraRecording.statuses[workspaceId]
    )

    const proposals = useApiData(
        getCalculation({ workspaceId }),
        state => state.workspace.proposals[workspaceId],
        state => state.workspace.proposalStatuses[workspaceId]
    )

    const optionsSetup = useApiData(
        getOptionsSetup(workspaceId),
        state => state.optionsSetup.data[workspaceId],
        state => state.optionsSetup.statuses[workspaceId]
    )

    const getServer = (serverRole: ServerRoles): Server => proposals?.preferredSolution?.servers?.find(server => server.role.name === serverRole)

    const getStorageDevice = (serverRole: ServerRoles, storageRole: StorageRoles): Storage => getServer(serverRole)?.storageConfigurations?.find(device => device.role.name === storageRole)

    const recording = getServer(ServerRoles.Recording)

    const recordingLive = getStorageDevice(ServerRoles.Recording, StorageRoles.Live)

    const archivingLive = getStorageDevice(ServerRoles.Recording, StorageRoles.Archive)

    if (cameraRecordings && recording) {
        totalCamerasPerServer = Math.ceil(cameraRecordings?.reduce((sum, recording) => sum + recording.quantity, 0) / recording?.quantity)
    }    

    return (
        <>
            <div className="px-10 print-page-break">
                <div className="msds-text-header-2 py-1 mt-5">Proposal recommendation</div>
                <div className="msds-text-header-3 msds-text-clear-blue py-2 mt-1">Recording Servers</div>

                <div className='row xsd-proposals-pdf'>
                    <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
                        <div className="xsd-proposals-pdf__server-setting-col">
                            <div className="server-settings__header msds-text-body-2-bold mt-2">Recording Server Specification</div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                                <div>Quantity of servers:</div>
                                <div> {recording?.quantity}</div>
                            </div>
                            {optionsSetup && (
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Cameras per server:</div>
                                    <div>{totalCamerasPerServer}</div>
                                </div>
                            )}
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>Machine Type: </div>
                                <div>{optionsSetup?.machineType?.name}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>CPU Type: </div>
                                <div>{recording?.cpuName}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>OS Disk: </div>
                                <div>{`${recording?.systemDriveCount} x ${recording?.systemDriveCapacity?.to_GB?.toFixed(2)} GB ${recording?.systemDriveRedundancy ? 'RAID 1' : ''}`}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>RAM: </div>
                                <div>{`${recording?.memoryTotalSize?.to_GB?.toFixed(2)} GB`}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>OS Version please <a href="#osVersion">see information below</a></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
                        <div className="xsd-proposals-pdf__server-setting-col">
                            <div className="server-settings__header msds-text-body-2-bold mt-2">Live Database Storage Information</div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                                <div>{quantityLabel(recordingLive?.connectivity)}:</div>
                                <div> {recordingLive?.numberOfDevices}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>Disk Type:</div>
                                <div> {recordingLive?.device?.type?.name}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>Disk Size:</div>
                                <div> {toTbValueString(recordingLive?.device?.capacity?.to_TB, false, 2)}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>Raid Type:</div>
                                <div>{recordingLive?.raidType?.name == "RAID 10" ? "Raid 1/ Raid 10" : recordingLive?.raidType?.name}</div>
                            </div>
                            <div className="msds-text-body-2-bold xsd-proposals-pdf__card-info">
                                <div>Required Capacity:</div>
                                <div>{toTbValueString(recordingLive?.requiredCapacity?.to_TB, false, 3)}</div>
                            </div>
                            <div className="msds-text-body-2-bold xsd-proposals-pdf__card-info">
                                <div>Formatted Capacity:</div>
                                <div>{toTbValueString(recordingLive?.totalCapacity?.to_TB, false, 2)}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>Connectivity:</div>
                                <div>{recordingLive?.connectivity?.name}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>IOPS:</div>
                                <div>{recordingLive?.requiredIOPS}</div>
                            </div>
                            <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                <div>Throughput:</div>
                                <div>{toMbValueString(recordingLive?.requiredSpeed?.to_MBps, false, 2)}</div>
                            </div>
                        </div>
                    </div>
                    {archivingLive && (
                        <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
                            <div className="xsd-proposals-pdf__server-setting-col">
                                <div className="server-settings__header msds-text-body-2-bold mt-2">Archive Database Storage Information</div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                                    <div>{quantityLabel(archivingLive?.connectivity)}:</div>
                                    <div>{archivingLive?.numberOfDevices}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Disk Type:</div>
                                    <div>{archivingLive?.device?.type?.name}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Disk Size:</div>
                                    <div>{toTbValueString(archivingLive?.device?.capacity?.to_TB, false, 2)}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Raid Type:</div>
                                    <div>{archivingLive?.raidType?.name}</div>
                                </div>
                                <div className="msds-text-body-2-bold xsd-proposals-pdf__card-info">
                                    <div>Required Capacity:</div>
                                    <div>{toTbValueString(archivingLive?.requiredCapacity?.to_TB, false, 3)}</div>
                                </div>
                                <div className="msds-text-body-2-bold xsd-proposals-pdf__card-info">
                                    <div>Formatted Capacity:</div>
                                    <div>{toTbValueString(archivingLive?.totalCapacity?.to_TB, false, 3)}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Connectivity:</div>
                                    <div>{archivingLive?.connectivity?.name}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>IOPS:</div>
                                    <div>{archivingLive?.requiredIOPS}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Throughput:</div>
                                    <div>{toMbValueString(archivingLive?.requiredSpeed?.to_MBps, false, 2)}</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {recording?.networks && (
                        <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
                            <div className="xsd-proposals-pdf__server-setting-col">
                                <div className="server-settings__header msds-text-body-2-bold mt-2">Network Interface</div>

                                {recording?.networks?.map((network) =>
                                    network.networkInterfaceCards.map(nInterface => (
                                        <div key={network.id}>
                                            <div key={`${network.id}-${nInterface.id}`}>
                                                <div className="msds-text-body-2-bold xsd-proposals-pdf__card-info mt-2">
                                                    <div>Usage: </div>
                                                    <div>{network.role.name}</div>
                                                </div>
                                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                                    <div>Speed: </div>
                                                    <div>{toGbValueString(nInterface?.bandwidth?.to_Gbps, true, 2)}</div>
                                                </div>
                                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                                    <div>Required Bitrate: </div>
                                                    <div>{toMbValueString(network?.requiredBandwidth?.to_Mbps, true, 2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )))}
                            </div>
                        </div>
                    )}
                </div>
                <div className='xsd-proposals-pdf__divider mt-4'></div>
            </div>
        </>
    )
}

type ProposalsProps = {
    workspace: WorkspaceDetails
}

export default ProposalsPdf
