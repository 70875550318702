import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { API_URL } from "config";
import { Calculation, OptionsSetup, Workspace } from "lib/types";
import { defaultStorageSetup } from "../optionsSetup/optionsSetupActions";
import { postCameraGroup } from "../cameraRecording/cameraRecordingActions";

const defaultOptionsSetup: OptionsSetup = {
  systemOverheadPercentage: 0,
  archivesPerDay: 6,
  archiveStorageSetup: null,
  archiving: false,
  failoverConfiguration: null,
  liveStorageSetup: defaultStorageSetup,
  machineType: { domainValueId: 2 },
  maxCameras: 200,
  viewedStreams: 1,
  managementServerOnBoM: false,
  managementServerNumberOfDevices: 0,
  maxDisksPerServer: 0,
  raiD1ForOS: true
}

export type GetCalculationPayload = {
  data?: Calculation;
  error?: string;
};
export const getCalculation = createAsyncThunk<
  GetCalculationPayload,
  { workspaceId: number },
  { rejectValue: GetCalculationPayload }
>("workspace/getCalculation", async ({ workspaceId }, { rejectWithValue }) => {
  try {
    const apiResponse = await axios.get(
      `${API_URL()}/user/workspace/${workspaceId}/calculate`
    );
    return { data: apiResponse.data };
  } catch (err) {
    const error: AxiosError<string> = err;
    return rejectWithValue({ error: error.message });
  }
});

export type GetWorkspacesPayload = {
  data?: Workspace[];
  error?: string;
};
export const getWorkspaces = createAsyncThunk<
  GetWorkspacesPayload,
  void,
  { rejectValue: GetWorkspacesPayload }
>("workspace/getWorkspaces", async (_, { rejectWithValue }) => {
  try {
    const apiResponse = await axios.get(`${API_URL()}/user/workspace`);
    return { data: apiResponse.data };
  } catch (err) {
    const error: AxiosError<string> = err;
    return rejectWithValue({ error: error.message });
  }
});

export type PostWorkspacesPayload = {
  data?: Workspace;
  error?: string;
};
export const postWorkspace = createAsyncThunk<
  PostWorkspacesPayload,
  { siteDesignId: number, dispatch },
  { rejectValue: PostWorkspacesPayload }
>("workspace/postWorkspace", async (args, { rejectWithValue }) => {
  try {
    const workspaceResponse = await axios.post(`${API_URL()}/user/workspace`, {
      siteDesignId: args.siteDesignId,
      sessionId: 'dummy',
    });
    const cameraConfigResponse = await axios.post(
      `${API_URL()}/user/workspace/${workspaceResponse.data.id}/cameraconfig`,
      { name: "" }
    );

    await axios.put(`${API_URL()}/user/workspace/${workspaceResponse.data.id}/optionssetup`, defaultOptionsSetup)
    args.dispatch(postCameraGroup({ workspaceId: workspaceResponse.data.id, cameraConfigId: cameraConfigResponse.data.id }))
    return { data: workspaceResponse.data };
  } catch (err) {
    const error: AxiosError<string> = err;
    return rejectWithValue({ error: error.message });
  }
});