import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./reducers/store";
import { IDENTITY_MANAGER_CONFIG, loadConfig } from "config";
import { AuthenticationProvider } from "components/Authentication/AuthenticationProvider";

loadConfig();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <AuthenticationProvider config={IDENTITY_MANAGER_CONFIG()}>
                    <App />
                </AuthenticationProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
