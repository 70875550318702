import "./loader.scss"
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg"
import { useAppSelector } from "reducers/hooks"
import { selectAreProjectsLoading } from "reducers/api/projects/projectsSlice"
import { selectAreCamerasLoading } from "reducers/api/cameraRecording/cameraRecordingSlice"
import { selectAreDomainsLoading } from "reducers/api/domain/domainSlice"
import { selectAreOptionsLoading } from "reducers/api/optionsSetup/optionsSetupSlice"
import { selectAreSiteDesignsLoading } from "reducers/api/siteDesign/siteDesignSlice"
import { selectAreSitesLoading } from "reducers/api/sites/sitesSlice"
import { selectAreWorkspacesLoading } from "reducers/api/workspace/workspaceSlice"

function Loader() {
  const areProjectsLoading = useAppSelector(selectAreProjectsLoading)
  const areCamerasLoading = useAppSelector(selectAreCamerasLoading)
  const areDomainsLoading = useAppSelector(selectAreDomainsLoading)
  const areOptionsLoading = useAppSelector(selectAreOptionsLoading)
  const areSiteDesignsLoading = useAppSelector(selectAreSiteDesignsLoading)
  const areSitesLoading = useAppSelector(selectAreSitesLoading)
  const areWorkspacesLoading = useAppSelector(selectAreWorkspacesLoading)
  const isLoading = areProjectsLoading 
    || areCamerasLoading
    || areDomainsLoading
    || areOptionsLoading
    || areSiteDesignsLoading
    || areSitesLoading
    || areWorkspacesLoading

  return (
    <>
      {isLoading && (
        <div className="content-overlay">
          <div className="loader">
            <svg className="loader__msds-loader">
              <use href={`${spritemap}#loader-animated`} />
            </svg>
          </div>
        </div>
      )}
    </>
  )
}

export default Loader;
