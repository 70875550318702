import './server-settings.scss'
import Input from 'components/FormComponents/Input/Input'
import Select from 'components/FormComponents/Select/Select'
import Accordion from 'components/Accordion/Accordion'
import { useCallback } from 'react'
import AccordionItem from 'components/Accordion/AccordionItem'
import Checkbox from 'components/Checkbox/Checkbox'
import { useAppDispatch } from 'reducers/hooks'
import { defaultStorageSetup, deleteFailoverConfig, /*postFailoverConfig,*/ putFailoverConfig, putOptionsSetup } from 'reducers/api/optionsSetup/optionsSetupActions'
import { cloneDeep } from 'lodash'
import { OptionsSetup, StorageSetup } from 'lib/types'
import ServerSettingsStorageSetup from './ServerSettingsStorageSetup'
import { getCalculation } from 'reducers/api/workspace/workspaceActions'
import { DESKTOP_CLASS, ENTERPRISE_HDD, SERVER_CLASS, SURVEILLANCE_HDD, useServerSettingsData } from './useServerSettingsData'

const ServerSettings = ({ workspaceId }: ServerSettingsProps) => {
  const dispatch = useAppDispatch()
  const { optionsSetup, isFailoverEnabled, isOptionsSetupLoaded, xProtectPlatforms, machineTypes, archiveFrequencies, supportedCameras, raiD1ForOSOptions, serverDiskQuantities } =
    useServerSettingsData(workspaceId)

  const upsertOptionsSetup = useCallback(
    (isFailover?: boolean, setter?: (optionsSetup: OptionsSetup) => void) => {
      if (optionsSetup) {
        let optionsSetupClone = cloneDeep(optionsSetup)
        setter && setter(optionsSetupClone)
        optionsSetupClone.archiveStorageSetup = optionsSetupClone.archiving ? optionsSetupClone.archiveStorageSetup ?? {} : null
        optionsSetupClone.managementServerOnBoM = optionsSetupClone.xProtectPlatform?.userCanSelectManagementServer ? optionsSetupClone.managementServerOnBoM : false
        if (!optionsSetupClone.xProtectPlatform?.failoverSupport && isFailoverEnabled) {
          dispatch(deleteFailoverConfig({ workspaceId, failoverConfig: optionsSetup.failoverConfiguration }))
        }
        isFailover
          ? dispatch(putFailoverConfig({ workspaceId: workspaceId, failoverConfig: optionsSetupClone.failoverConfiguration })).then(() => dispatch(getCalculation({ workspaceId })))
          : dispatch(putOptionsSetup({ workspaceId: workspaceId, optionsSetup: optionsSetupClone })).then(() => dispatch(getCalculation({ workspaceId })))
      }
    },
    [dispatch, optionsSetup, workspaceId, isFailoverEnabled]
  )

  const handleValueChange = (propertyName: string, isFailover?: boolean) => (value: string | boolean) =>
    upsertOptionsSetup(isFailover, optionsSetupClone => (isFailover ? (optionsSetupClone.failoverConfiguration[propertyName] = value) : (optionsSetupClone[propertyName] = value)))

  const handleDomainChange = (propertyName: string, isFailover?: boolean) => (value: string) =>
    upsertOptionsSetup(isFailover, optionsSetupClone => {
      if (isFailover) {
        optionsSetupClone.failoverConfiguration[propertyName] = { domainValueId: value }
      } else {
        optionsSetupClone[propertyName] = { domainValueId: value }
      }
      if (propertyName === 'machineType') {
        setDefaultsForMaxDisk(value, optionsSetupClone)
        setDefaultForDiskType(value, optionsSetupClone, 'live')
        setDefaultForDiskType(value, optionsSetupClone, 'archiving')
        setDefaultForDiskType(value, optionsSetupClone, 'failover')
      }
    })

  const setDefaultsForMaxDisk = (machineType: string, optionsSetup: OptionsSetup) => {
    if (machineType === DESKTOP_CLASS) {
      optionsSetup.maxDisksPerServer = 8
    } else if (machineType === SERVER_CLASS) {
      optionsSetup.maxDisksPerServer = 24
    }
  }

  const setDefaultForDiskType = (machineType: string, optionsSetup: OptionsSetup, storageType: 'live' | 'archiving' | 'failover') => {
    let storageToSet = null

    if (storageType === 'live') {
      storageToSet = optionsSetup.liveStorageSetup
    } else if (storageType === 'archiving' && optionsSetup.archiving) {
      storageToSet = optionsSetup.archiveStorageSetup
    } else if (storageType === 'failover' && isFailoverEnabled) {
      storageToSet = optionsSetup.failoverConfiguration?.liveStorageSetup
    }

    if (storageToSet === null) {
      return
    }

    if (machineType === DESKTOP_CLASS) {
      storageToSet.deviceType = storageToSet.deviceType.domainValueId === ENTERPRISE_HDD ? { domainValueId: SURVEILLANCE_HDD } : storageToSet.deviceType
    } else if (machineType === SERVER_CLASS) {
      storageToSet.deviceType = storageToSet.deviceType.domainValueId === SURVEILLANCE_HDD ? { domainValueId: ENTERPRISE_HDD } : storageToSet.deviceType
    }
  }

  const handleStorageSetupChange = (storageType: 'live' | 'archiving' | 'failover') => (storageSetup: StorageSetup) =>
    upsertOptionsSetup(storageType === 'failover', optionsSetupClone => {
      switch (storageType) {
        case 'live':
          optionsSetupClone.liveStorageSetup = storageSetup
          break
        case 'archiving':
          optionsSetupClone.archiveStorageSetup = storageSetup
          break
        case 'failover':
          optionsSetupClone.failoverConfiguration.liveStorageSetup = storageSetup
          break
        default:
          break
      }
    })

  // const handleFailoverCheckboxChange = (value: boolean) => value
  //     ? dispatch(postFailoverConfig({ workspaceId, failoverConfig: optionsSetup.failoverConfiguration }))
  //     : dispatch(deleteFailoverConfig({ workspaceId, failoverConfig: optionsSetup.failoverConfiguration }))

  const handleArchiveCheckboxChange = (value: boolean) =>
    value
      ? upsertOptionsSetup(false, optionsSetupClone => {
        optionsSetupClone.archiving = true
        optionsSetupClone.archiveStorageSetup = defaultStorageSetup        
        optionsSetupClone.archiveStorageSetup.deviceType.domainValueId = optionsSetup.machineType.domainValueId == 2 ? 2 : 1
        optionsSetupClone.archivesPerDay = 6
        optionsSetupClone.archiveStorageSetup.raidType.domainValueId = 4
        optionsSetupClone.liveStorageSetup.raidType.domainValueId = 6
        optionsSetupClone.liveStorageSetup.deviceType.domainValueId = 3
      })
      : handleValueChange('archiving')(false)

  const handleXProtectPlatformChange = (xProtectPlatformId: number) => {
    const xProtectPlatform = xProtectPlatforms.find(platform => platform.originalData.domainValueId == xProtectPlatformId)

    upsertOptionsSetup(false, optionsSetupClone => {
      optionsSetupClone.xProtectPlatform = {
        domainValueId: xProtectPlatformId
      }
      optionsSetupClone.maxCameras = xProtectPlatform.originalData?.cameraQtyDefault ?? optionsSetupClone.maxCameras
    })
  }

  return (
    <>
      {isOptionsSetupLoaded && (
        <>
          <div className="row align-items-center mt-6 mb-n8">
            <Select
              className="col-3"
              defaultOption={{ label: 'Select XProtect Version', value: '' }}
              options={xProtectPlatforms}
              handleDataChange={handleXProtectPlatformChange}
              initialValue={optionsSetup?.xProtectPlatform?.domainValueId?.toString()}
            />
            {/* <Checkbox className='col-2' id="failover-settings" label="Add Failover Server" handleChange={handleFailoverCheckboxChange} initialValue={isFailoverEnabled} isDisabled={!optionsSetup?.xProtectPlatform?.failoverSupport} /> */}
            <Checkbox
              className="col-3"
              id="management-settings"
              label="Add Management Server"
              handleChange={handleValueChange('managementServerOnBoM')}
              initialValue={optionsSetup?.managementServerOnBoM}
              isDisabled={!optionsSetup?.xProtectPlatform?.userCanSelectManagementServer}
            />
            {optionsSetup?.xProtectPlatform.name === 'XProtect Express+' && <div className="col-12 mt-4 ml-1 msds-text-body-3 msds-text-gray-10">XProtect Express+ only supports up to 48 cameras</div>}
          </div>
          <Accordion>
            <AccordionItem title="Recording Server Settings" forceExpand>
              <div className="row justify-content-start mb-4">
                <div className="server-settings__header msds-text-body-0-bold col-12 my-4">Main Settings</div>
                <Input
                  className="col-3 ml-4"
                  label="System overhead (%)"
                  isSmall
                  handleDataChange={handleValueChange('systemOverheadPercentage')}
                  initialValue={optionsSetup?.systemOverheadPercentage?.toString()}
                />
                <Input
                  className="col-3"
                  label="Max cameras per server"
                  type="number"
                  maxValue={optionsSetup.xProtectPlatform.cameraQtyMax}
                  isSmall
                  handleDataChange={handleValueChange('maxCameras')}
                  initialValue={optionsSetup?.maxCameras?.toString()}
                />
                <Input className="col-3" label="Concurrent Smart Client Streams" isSmall handleDataChange={handleValueChange('viewedStreams')} initialValue={optionsSetup?.viewedStreams?.toString()} />
              </div>
              <div className="row justify-content-start mb-4 align-items-center">
                <div className="server-settings__header msds-text-body-0-bold col-12 my-4">Advanced Settings</div>
                <Select
                  className="col-3 ml-4 dropdownlist__machine-type"
                  defaultOption={{ label: 'Machine Type', value: '' }}
                  options={machineTypes}
                  isSmall
                  handleDataChange={handleDomainChange('machineType')}
                  initialValue={optionsSetup?.machineType?.domainValueId?.toString()}
                />
                <Select
                  className="col-3"
                  defaultOption={{ label: 'Server Disk Quantity', value: '' }}
                  options={serverDiskQuantities}
                  isSmall
                  handleDataChange={handleValueChange('maxDisksPerServer')}
                  initialValue={optionsSetup?.maxDisksPerServer?.toString()}
                />
                <Select
                  className="col-3"
                  defaultOption={{ label: 'Raid OS', value: '' }}
                  options={raiD1ForOSOptions}
                  isSmall
                  handleDataChange={handleValueChange('raiD1ForOS')}
                  initialValue={optionsSetup?.raiD1ForOS?.toString()}
                />
                <Checkbox className="col-2" id="archiving" label="Archiving" handleChange={handleArchiveCheckboxChange} initialValue={optionsSetup?.archiving} />
              </div>
              <ServerSettingsStorageSetup
                header="Live Database"
                handleDataChange={handleStorageSetupChange('live')}
                storageSetup={optionsSetup?.liveStorageSetup}
                machineType={optionsSetup.machineType.domainValueId}
              />
              {optionsSetup?.archiving && (
                <ServerSettingsStorageSetup
                  header="Archiving"
                  handleDataChange={handleStorageSetupChange('archiving')}
                  storageSetup={optionsSetup?.archiveStorageSetup}
                  machineType={optionsSetup.machineType.domainValueId}>
                  <Select
                    className="col-3 mb-4"
                    defaultOption={{ label: 'Archive Frequency (times per day)', value: '' }}
                    options={archiveFrequencies}
                    isSmall
                    handleDataChange={handleValueChange('archivesPerDay')}
                    initialValue={optionsSetup?.archivesPerDay?.toString()}
                  />
                </ServerSettingsStorageSetup>
              )}
            </AccordionItem>
            {/* <AccordionItem title='Failover Server Settings' isDisabled={!isFailoverEnabled || !optionsSetup?.xProtectPlatform?.failoverSupport} forceExpand={isFailoverEnabled}>
                        <div className='row justify-content-start align-items-end mb-4'>
                            <div className='server-settings__header msds-text-body-0-bold col-12 my-4'>Main Settings</div>
                            <Select className='col-3 ml-4' defaultOption={{ label: "Failover Type", value: "" }} options={failoverModes} isSmall handleDataChange={handleDomainChange('failoverMode', true)} initialValue={optionsSetup?.failoverConfiguration?.failoverMode?.domainValueId?.toString()} />
                            <Select className='col-3' defaultOption={{ label: "Quantity", value: "" }} isSmall handleDataChange={handleValueChange('numberOfFailoverServers', true)} initialValue={optionsSetup?.failoverConfiguration?.numberOfFailoverServers?.toString()} options={failoverServerQuantities} />
                            <Input className='col-3' label='Retention' isSmall handleDataChange={handleValueChange('retentionDays', true)} initialValue={optionsSetup?.failoverConfiguration?.retentionDays?.toString()} />
                        </div>
                        <div className='row justify-content-start'>
                            <div className='server-settings__header msds-text-body-0-bold col-12 mt-4'>Advanced Settings</div>
                        </div>
                        <ServerSettingsStorageSetup header='Live Database' handleDataChange={handleStorageSetupChange('failover')} storageSetup={optionsSetup?.failoverConfiguration?.liveStorageSetup} machineType={optionsSetup.machineType.domainValueId} />
                    </AccordionItem> */}
            <AccordionItem
              title="Managment Server Settings"
              isDisabled={!optionsSetup?.managementServerOnBoM || !optionsSetup?.xProtectPlatform?.userCanSelectManagementServer}
              forceExpand={optionsSetup?.managementServerOnBoM}>
              <div className="row justify-content-start mb-4">
                <Select
                  className="col-3"
                  defaultOption={{ label: 'Supported Cameras', value: '' }}
                  options={supportedCameras}
                  isSmall
                  handleDataChange={handleValueChange('managementServerNumberOfDevices')}
                  initialValue={optionsSetup?.managementServerNumberOfDevices?.toString()}
                />
              </div>
            </AccordionItem>
          </Accordion>
        </>
      )}
    </>
  )
}

export default ServerSettings

type ServerSettingsProps = {
  workspaceId: number
}
