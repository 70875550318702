import Table from 'components/Table/Table';
import { ProjectType } from 'lib/types';
import { getProjectsThunk, postProjectThunk, updateProjectThunk, deleteProjectThunk, duplicateProject } from 'reducers/api/projects/action';
import { useApiData, useAppDispatch } from 'reducers/hooks'
import { ProjectsColumns } from './ProjectsColumns';
import { getWorkspaces } from 'reducers/api/workspace/workspaceActions';

const Projects = () => {
    const projectsData = useApiData(getProjectsThunk(), state => state.projects.data, state => state.projects.projectsStatus)
    const dispatch = useAppDispatch()

    const addProject = () => {
        dispatch(postProjectThunk("Project " + (projectsData.length + 1)))
    }

    const updateProject = (project: ProjectType) => {
        dispatch(updateProjectThunk(project))
    }

    const deleteProject = (projectId: string) => {
        dispatch(deleteProjectThunk({projectId}))
    }

    const duplicate = (projectId: number) => {
        dispatch(duplicateProject({ projectId }))
            .then(() => dispatch(getWorkspaces()))
    }

    return (
        <div className="container msds-block-padding-small">
            <div className="row">
                <h1 className="msds-text-header-1">Projects</h1>
                {projectsData && (
                    <Table
                        tableColumns={ProjectsColumns}
                        initialData={projectsData}
                        addButtonLabel="Add project"
                        addButtonLabelIcon="plus"
                        handleAddDataRow={addProject}
                        handleUpdateDataRow={updateProject}
                        handleDeleteDataRow={deleteProject}
                        handleDuplicateRow={duplicate}
                        deleteModalHeadline="Are you sure you want to delete this project?"
                        deleteModalDescription="By deleting this project, you will also remove all sites and site designs that have been setup for this project"
                    />
                )}
            </div>
        </div>
    )
}

export default Projects