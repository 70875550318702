import { Route, Routes } from "react-router-dom";
import { RequireAuthorization } from "components/Authentication/RequireAuthorization";
import Projects from "pages/Projects/Projects";
import SiteDesignEditor from 'pages/SiteDesignEditor/SiteDesignEditor'
import Sites from "pages/Sites/Sites";
import SiteDesign from "pages/SiteDesign/SiteDesign";
import { RouteConfig } from "lib/types";
import { useContext } from "react";
import { AuthenticationContext } from "components/Authentication/AuthenticationProvider";
import { ReleaseNotes } from "pages/ReleaseNotes/ReleaseNotes";
import { Discalimer } from "pages/Disclaimer/Discalimer";

const routes: RouteConfig[] = [
  { path: '/', pageComponent: <Projects />, requireAuthorization: true },
  { path: '/project/:projectId/site', pageComponent: <Sites />, requireAuthorization: true },
  { path: '/project/:projectId/site/:siteId/sitedesign', pageComponent: <SiteDesign />, requireAuthorization: true },
  { path: '/site-design-editor/workspace/:workspaceId', pageComponent: <SiteDesignEditor />, requireAuthorization: true },
  { path: '/release-notes/', pageComponent: <ReleaseNotes />, requireAuthorization: false },
  { path: '/disclaimer/', pageComponent: <Discalimer />, requireAuthorization: false },
]

const Routing = () => {
  const authenticationContext = useContext(AuthenticationContext)
  return (
    <Routes>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <>
              {route.requireAuthorization && <RequireAuthorization>{route.pageComponent}</RequireAuthorization>}
              {!route.requireAuthorization && route.pageComponent}
            </>
          }
        />
      ))}
      {authenticationContext.authCallbackRoute}
    </Routes>
  )
}


export default Routing