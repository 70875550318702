import Input from "components/FormComponents/Input/Input"
import Select from "components/FormComponents/Select/Select"
import { DomainName } from "lib/enums"
import { StorageSetup } from "lib/types"
import { useApiDomainData } from "reducers/hooks"
import { DESKTOP_CLASS, ENTERPRISE_HDD, ENTERPRISE_SSD, SURVEILLANCE_HDD } from './useServerSettingsData'

const ServerSettingsStorageSetup = ({ storageSetup, handleDataChange, header, children, machineType }: ServerSettingsStorageSetupProps) => {
    const raidTypes = useApiDomainData(DomainName.RaidType)
    const storageDeviceTypes = useApiDomainData(DomainName.StorageDeviceType, domain => ({ value: domain.domainValueId.toString(), label: domain.displayName }))
        ?.filter(device => machineType.toString() === DESKTOP_CLASS
            ? device.value === ENTERPRISE_SSD.toString() || device.value === SURVEILLANCE_HDD.toString()
            : device.value === ENTERPRISE_SSD.toString() || device.value === ENTERPRISE_HDD.toString())
    const storageConnectivities = useApiDomainData(DomainName.ConnectionType)
    const storageSetupState = storageSetupToState(storageSetup)

    const handleInputChange = (propertyName: string) => (value: string) => {
        const newState = { ...storageSetupState, [propertyName]: value }
        handleDataChange(stateToStorageSetup(newState))
    }

    return (
        <>
            <div className='row justify-content-start align-items-end ml-2'>
                <div className='server-settings__subheader msds-text-body-1 col-12 my-4'>{header}</div>                
                <Select className='col-3 mb-4 dropdownlist__storage-connectivity' 
                        defaultOption={{ label: "Storage Connectivity", value: "" }} 
                        options={storageConnectivities} 
                        isSmall 
                        handleDataChange={handleInputChange('connectionType')} 
                        initialValue={storageSetupState.connectionType} />
                <Select className='col-3 mb-4 dropdownlist__raid-array' 
                        defaultOption={{ label: "Raid Array", value: "" }} 
                        options={raidTypes} 
                        isSmall 
                        handleDataChange={handleInputChange('raidType')} 
                        initialValue={storageSetupState.raidType} />
                {children}
            </div>
            <div className='row justify-content-start align-items-end ml-2'>
                <Select className='col-3 mb-4' 
                        defaultOption={{ label: "Disk Type", value: "" }} 
                        options={storageDeviceTypes} 
                        isSmall 
                        handleDataChange={handleInputChange('deviceType')} 
                        initialValue={storageSetupState.deviceType} />
                <Input className='col-3 mb-4' 
                       label='Disk Size Min (TB)' 
                       isSmall 
                       handleDataChange={handleInputChange('deviceMinSize')} 
                       initialValue={storageSetupState.deviceMinSize} />
                <Input className='col-3 mb-4' 
                       label='Disk Size Max (TB)' 
                       isSmall 
                       handleDataChange={handleInputChange('deviceMaxSize')} 
                       initialValue={storageSetupState.deviceMaxSize} />
            </div>
        </>
    )
}

const toTb = (bytes: number): string => {
    return (bytes / 1000000000000).toString()
}

const toBytes = (tb: string): number => {
    return parseInt(tb) * 1000000000000
}

const storageSetupToState = (storageSetup: StorageSetup): StorageSetupState => {
    return {
        connectionType: storageSetup?.connectionType?.domainValueId.toString(),
        deviceType: storageSetup?.deviceType?.domainValueId.toString(),
        deviceMaxSize: toTb(storageSetup?.deviceMaxSize?.bytes ?? 0),
        deviceMinSize: toTb(storageSetup?.deviceMinSize?.bytes ?? 0),
        id: storageSetup?.id,
        raidType: storageSetup?.raidType?.domainValueId.toString(),
    }
}

const stateToStorageSetup = (state: StorageSetupState): StorageSetup => {
    return {
        connectionType: state.connectionType ? { domainValueId: parseInt(state.connectionType) } : null,
        deviceType: state.deviceType ? { domainValueId: parseInt(state.deviceType) } : null,
        deviceMaxSize: state.deviceMaxSize ? { bytes: toBytes(state.deviceMaxSize) } : null,
        deviceMinSize: state.deviceMinSize ? { bytes: toBytes(state.deviceMinSize) } : null,
        id: state.id,
        raidType: state.raidType ? { domainValueId: parseInt(state.raidType) } : null
    }
}

export default ServerSettingsStorageSetup

type ServerSettingsStorageSetupProps = {
    storageSetup: StorageSetup,
    handleDataChange: (storageSetup: StorageSetup) => void,
    header: string,
    children?: JSX.Element | JSX.Element[],
    machineType: number
}

type StorageSetupState = {
    deviceMinSize: string,
    deviceMaxSize: string,
    id: number,
    raidType: string,
    connectionType: string,
    deviceType: string
}