import { useApiData, useAppSelector } from 'reducers/hooks'
import { ProposalsProps } from './Proposals'
import { getOptionsSetup } from 'reducers/api/optionsSetup/optionsSetupActions'
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg"
import { ReactComponent as MilestoneLogo } from '../../../components/Navigation/milestone-logo.svg'
import '../print.scss'
import { getProjectsThunk } from 'reducers/api/projects/action'
import { useParams } from 'react-router-dom'
import { getSitesThunk } from 'reducers/api/sites/action'
import { RootState } from 'reducers/store'
import { SiteType } from 'lib/types'
import { Status } from 'lib/enums'

const ProposalsHeaderPdf = ({ workspace }: ProposalsProps) => {
    
    const { workspaceId, siteDesignName, updatedUtc, projectName, siteName } = workspace ?? {}
    const optionsSetup = useApiData(
        getOptionsSetup(workspaceId),
        state => state.optionsSetup.data[workspaceId],
        state => state.optionsSetup.statuses[workspaceId]
    )
    
    //get correct project name
    const projectId = workspace.projectId
    const projectsData = useApiData(getProjectsThunk(), state => state.projects.data, state => state.projects.projectsStatus);
    const project = projectsData.find(project => project.id == projectId)  
    let currentProjectName = projectName;  
    if(project.name != currentProjectName ) {
        currentProjectName = project.name
    }

    //get correct site name    
    const siteId = workspace.siteId    
    const siteData = useApiData(getSitesThunk(projectId), selectSites(projectId), selectStatus(projectId));    
    const site = siteData.find(site => site.id == siteId)    
    let currentSiteName = siteName;  
    if(site.name != currentSiteName ) {
        currentSiteName = site.name
    }

    const userData = useAppSelector(state => state.user.userData)

    const getDate = (): string => {
        const date = new Date(updatedUtc)
        const [month, day, year] = [date.toLocaleString('default', { month: 'long' }), date.getDate(), date.getFullYear()]
        return `${month} ${day}, ${year}`
    }

    return (
        <div className='xsd-proposals-pdf msds-bg-clear-blue msds-text-white container-fluid px-10 py-10'>
            <div className='row'>
                <div className="col-12 pb-2 xsd-proposals-pdf__milestone-logo-container">
                    <MilestoneLogo className="xsd-proposals-pdf__milestone-logo" />
                </div>
                <div className="col-12 msds-text-header-2-bold pb-2">Proposal</div>
                <div className="col-5 offset-7 msds-text-header-3 pb-4">Integrator details</div>

                <div className="col-3">
                    <div className="msds-text-body-0">Configuration Date:</div>
                    <div className="msds-text-body-0">Project name: </div>
                    <div className="msds-text-body-0">Site:</div>
                    <div className="msds-text-body-0">Site design:</div>
                    <div className="msds-text-body-0">Product:</div>
                </div>
                <div className="col-3 d-flex flex-column align-items-end">
                    <div className="msds-text-body-0">{getDate()}</div>
                    <div className="msds-text-body-0">{currentProjectName}</div>
                    <div className="msds-text-body-0">{currentSiteName}</div>
                    <div className="msds-text-body-0">{siteDesignName}</div>
                    <div className="msds-text-body-0">{optionsSetup?.xProtectPlatform?.name}</div>
                </div>
                <div className="col-3 offset-1">
                    <div className="msds-text-body-0">{userData.name} {userData.lastName}</div>
                    <div className="msds-text-body-0 pb-4">{userData.companyName}</div>
                    <div>
                        {userData.email &&
                            <div className="xsd-proposals-pdf__contact msds-text-body-0 d-flex align-end" >
                                <svg className="mr-4">
                                    <use href={`${spritemap}#email`} />
                                </svg>
                                {userData.email}
                            </div>
                        }
                        {userData.phone &&
                            <div className="xsd-proposals-pdf__contact msds-text-body-0 d-flex align-end" >
                                <svg className="mr-4">
                                    <use href={`${spritemap}#phone`} />
                                </svg>
                                {userData.phone}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export const selectSites = (projectId: string) => (state: RootState): SiteType[] => state.sites.data[projectId] ? state.sites.data[projectId] : []
export const selectStatus = (projectId: string) => (state: RootState): Status => state.sites.sitesStatus[projectId] ? state.sites.sitesStatus[projectId] : Status.Idle

export default ProposalsHeaderPdf
