import { useApiData, useApiDomainData, useAppSelector } from 'reducers/hooks'
import { DomainName, ManagementServerConfig, Status } from 'lib/enums'
import { getOptionsSetup } from 'reducers/api/optionsSetup/optionsSetupActions'
import { DomainType, OptionsSetup } from 'lib/types'

export const DESKTOP_CLASS = '1'
export const SERVER_CLASS = '2'
export const SURVEILLANCE_HDD = 1
export const ENTERPRISE_HDD = 2
export const ENTERPRISE_SSD = 3

type ServerSettingsData = {
    optionsSetup: OptionsSetup
    isFailoverEnabled: boolean
    isOptionsSetupLoaded: boolean
    xProtectPlatforms: { value: string, label: string, originalData?: DomainType }[]
    machineTypes: { value: string, label: string}[],
    archiveFrequencies: { value: string, label: string}[],
    supportedCameras: { value: string, label: string}[],
    raiD1ForOSOptions: { value: string, label: string}[],
    serverDiskQuantities: { value: string, label: string}[],
    supportedCamerasSelectedOption: string
}

export const useServerSettingsData = (workspaceId: number): ServerSettingsData => {
    const optionsSetup = useApiData(getOptionsSetup(workspaceId), state => state.optionsSetup.data[workspaceId], state => state.optionsSetup.statuses[workspaceId])
    const isFailoverEnabled = optionsSetup?.failoverConfiguration?.numberOfFailoverServers > 0
    const isOptionsSetupLoaded = useAppSelector(state => state.optionsSetup.statuses[workspaceId]) === Status.Success
    const xProtectPlatforms = useApiDomainData(DomainName.XProtectPlatform)
    const machineTypes = useApiDomainData(DomainName.MachineType)
    // const failoverModes = useApiDomainData(DomainName.FailoverMode)
    const archiveFrequencies = Array.from({ length: 25 }, (_, index) => index).map(number => ({ label: number.toString(), value: number.toString() }))
    // const failoverServerQuantities = Array.from({ length: 5 }, (_, index) => index + 1).map(number => ({ label: number.toString(), value: number.toString() }))
    const supportedCameras = [
        { label: "Auto", value: ManagementServerConfig.Auto.toString() },
        { label: "1 - 300", value: ManagementServerConfig.MsConfig1.toString() },
        { label: "301 - 1200", value: ManagementServerConfig.MsConfig2.toString() },
        { label: "1201 - 2500", value: ManagementServerConfig.MsConfig3.toString() },
        { label: "2501 - 5000", value: ManagementServerConfig.MsConfig4.toString() }
    ]
    const raiD1ForOSOptions = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
    ]
    const serverDiskQuantities = [
        { label: 'Auto select', value: '0' },
        ...Array.from({ length: 999 }, (_, index) => index + 1).map(number => ({ label: number.toString(), value: number.toString() }))
    ]
    const supportedCamerasSelectedOption = supportedCameras.find(supportedCamera => supportedCamera.value === optionsSetup?.managementServerNumberOfDevices?.toString())?.label

    return {
        optionsSetup,
        isFailoverEnabled,
        isOptionsSetupLoaded,
        xProtectPlatforms,
        machineTypes,
        archiveFrequencies,
        supportedCameras,
        raiD1ForOSOptions,
        serverDiskQuantities,
        supportedCamerasSelectedOption
    }
}