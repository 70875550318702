import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Status } from "lib/enums"
import { toShortDate } from "lib/helpers"
import { SiteDesignType } from "lib/types"
import { RootState } from "reducers/store"
import { SiteDesignGetPayload, getSiteDesigns, postSiteDesign, SiteDesignInsertPayload, putSiteDesign, SiteDesignDeletePayload, deleteSiteDesign, SiteDesignUpdatePayload, duplicateSiteDesign } from "./siteDesignActions"

type SiteDesignState = {
    data: { [id: number]: { [id: number]: SiteDesignType[] } },
    statuses: { [id: number]: { [id: number]: Status } },
    upsertStatus: Status
}

const initialState: SiteDesignState = {
    data: {},
    statuses: {},
    upsertStatus: Status.Idle
}

const siteDesignSlice = createSlice({
    name: 'siteDesign',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => builder
        .addCase(getSiteDesigns.pending, (state: SiteDesignState, action: PayloadAction<SiteDesignGetPayload, string, { arg: { projectId: number, siteId: number } }>) => {
            state.statuses[action.meta.arg.projectId] = {
                ...state.statuses[action.meta.arg.projectId],
                [action.meta.arg.siteId]: Status.Pending
            }
        })
        .addCase(getSiteDesigns.fulfilled, (state: SiteDesignState, action: PayloadAction<SiteDesignGetPayload>) => {
            state.data[action.payload.projectId] = {
                ...state.data[action.payload.projectId],
                [action.payload.siteId]: updateDataDate(action.payload.data.sort((a, b) => b.id - a.id))
            }

            state.statuses[action.payload.projectId] = {
                ...state.statuses[action.payload.projectId],
                [action.payload.siteId]: Status.Success
            }
        })
        .addCase(getSiteDesigns.rejected, (state: SiteDesignState, action: PayloadAction<SiteDesignGetPayload>) => {
            state.statuses[action.payload.projectId] = {
                ...state.statuses[action.payload.projectId],
                [action.payload.siteId]: Status.Rejected
            }
            console.log(action.payload.error)
        })
        .addCase(postSiteDesign.pending, state => {
            state.upsertStatus = Status.Pending
        })
        .addCase(postSiteDesign.fulfilled, (state, action: PayloadAction<SiteDesignInsertPayload>) => {
            state.data[action.payload.projectId] = {
                ...state.data[action.payload.projectId],
                [action.payload.siteId]: updateDataDate([
                    ...state.data[action.payload.projectId][action.payload.siteId],
                    action.payload.data
                ]).sort((a, b) => b.id - a.id)
            }
            state.upsertStatus = Status.Success
        })
        .addCase(putSiteDesign.pending, state => {
            state.upsertStatus = Status.Pending
        })
        .addCase(putSiteDesign.fulfilled, (state, action: PayloadAction<SiteDesignUpdatePayload>) => {
            const siteDesignIndex = state.data[action.payload.projectId][action.payload.siteId].findIndex(siteDesign => siteDesign.id === action.payload.data.id)
            const newState = [...state.data[action.payload.projectId][action.payload.siteId]]
            newState[siteDesignIndex] = action.payload.data

            state.data[action.payload.projectId] = {
                ...state.data[action.payload.projectId],
                [action.payload.siteId]: updateDataDate(newState.sort((a, b) => b.id - a.id))
            }
            state.upsertStatus = Status.Success
        })
        .addCase(deleteSiteDesign.rejected, (_, action: PayloadAction<SiteDesignDeletePayload>) => {
            console.log(action.payload.error)
        })
        .addCase(deleteSiteDesign.fulfilled, (state, action: PayloadAction<SiteDesignDeletePayload>) => {
            const newState = state.data[action.payload.projectId][action.payload.siteId].filter(siteDesign => siteDesign.id !== action.payload.designId)
            state.data[action.payload.projectId][action.payload.siteId] = newState
        })
        .addCase(duplicateSiteDesign.pending, state => {
            state.upsertStatus = Status.Pending
        })
        .addCase(duplicateSiteDesign.fulfilled, (state, action: PayloadAction<SiteDesignInsertPayload>) => {
            const newState = updateDataDate([
                ...state.data[action.payload.projectId][action.payload.siteId],
                action.payload.data
            ]).sort((a, b) => b.id - a.id)

            state.data[action.payload.projectId] = {
                ...state.data[action.payload.projectId],
                [action.payload.siteId]: newState
            }
            state.upsertStatus = Status.Success
        })
})

export const selectSiteDesigns = (projectId: number, siteId: number) => (state: RootState): SiteDesignType[] => {
    const sites = state.siteDesign.data[projectId]
    if (sites == null) {
        return []
    }
    else {
        return sites[siteId] ?? []
    }
}

export const selectSiteDesignStatus = (projectId: number, siteId: number) => (state: RootState): Status => {
    const siteStatuses = state.siteDesign.statuses[projectId]
    if (siteStatuses == null) {
        return Status.Idle
    }
    else {
        return siteStatuses[siteId] ?? Status.Idle
    }
}

export const selectAreSiteDesignsLoading = (state: RootState): boolean => Object.values(state.siteDesign.statuses)
    .flatMap(statuses => Object.values(statuses))
    .some(status => status === Status.Pending) || state.siteDesign.upsertStatus === Status.Pending

const updateDataDate = (siteDesign: SiteDesignType[]) => {
    siteDesign.forEach(p => {
        p.formattedCreatedUtc = toShortDate(p.createdUtc)
        p.formattedUpdatedUtc = toShortDate(p.updatedUtc ?? p.createdUtc)
    })
    return siteDesign
}

export default siteDesignSlice.reducer