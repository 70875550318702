// CPU Names
export const serverClassReplacements = {
    Bronze3408u: "Xeon Bronze 3408u or similar",
    Silver4410y: "Xeon Silver 4410y or similar"
}

export const desktopClassReplacements = {
    Corei3: "Intel Core i3 12100 or similar",
    Corei5: "Intel Core i5 13400 or similar"
}

//CPU count
export const cpuCountReplacement = {
    dual: "Dual "
}