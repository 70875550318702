import './print.scss'
import BackButton from 'components/Button/BackButton'
import TabItem from 'components/Tabs/TabItem'
import Tabs from 'components/Tabs/Tabs'
import { useNavigate, useParams } from 'react-router-dom'
import { getWorkspaces } from 'reducers/api/workspace/workspaceActions'
import { useApiData } from 'reducers/hooks'
import CameraGroupTable from './CameraGroupTable/CameraGroupTable'
import CamerasGroupTablePdf from './CameraGroupTable/CameraGroupTablePdf'
import ServerSettings from './ServerSettings/ServerSettings'
import Proposals from './Proposals/Proposals'
import ProposalsHeader from './Proposals/ProposalsHeaderPdf'
import ServerSettingsPdf from './ServerSettings/ServerSettingsPdf'
import ProposalsPdf from './Proposals/ProposalsPdf'
import ProposalManagementPdf from './Proposals/ProposalsManagementPdf'
import ProposalsDisclaimerPdf from './Proposals/ProposalsDisclaimerPdf'

const SiteDesignEditor = () => {
  const { workspaceId } = useParams()
  const navigate = useNavigate()
  const workspaceIdNumber = parseInt(workspaceId)
  const workspaces = useApiData(
    getWorkspaces(),
    state => state.workspace.workspaces,
    state => state.workspace.workspacesStatus
  )
  const workspace = Object.values(workspaces).find(value => value.workspaceId === workspaceIdNumber)

  return (
    <>
      {workspace && (
        <div className="container-fluid">
          <div className="mx-8 no-print-container">
            <div className="msds-text-header-1 my-6">{workspace?.siteDesignName}</div>
            <BackButton cssClass="mb-6" onClickHandler={() => navigate(`/project/${workspace?.projectId}/site/${workspace?.siteId}/sitedesign`)} label={'Back to Site Designs'}></BackButton>
            <Tabs>
              <TabItem tabTitle="Cameras">
                <CameraGroupTable workspaceId={workspaceIdNumber} />
              </TabItem>
              <TabItem tabTitle="Servers">
                <ServerSettings workspaceId={workspaceIdNumber} />
              </TabItem>
            </Tabs>
            <Proposals workspace={workspace} />
          </div>
          <div className="print-container">
            <ProposalsHeader workspace={workspace} />
            <CamerasGroupTablePdf workspaceId={workspaceIdNumber} />
            <ServerSettingsPdf workspaceId={workspaceIdNumber} />
            <ProposalsPdf workspace={workspace} />
            <ProposalManagementPdf workspace={workspace} />
            <ProposalsDisclaimerPdf />
          </div>
        </div>
      )}
    </>
  )
}

export default SiteDesignEditor
