import './camera-group-table.scss'
import Table from 'components/Table/Table'
import { LensType, RecordingType } from 'lib/enums'
import { CameraRecordingComplexCell, CameraRecordingDataRow } from 'lib/types'
import {
  copyCameraGroup,
  deleteCameraGroup,
  deleteCameraStream,
  postCameraGroup,
  postCameraStream,
  putCameraGroup,
  putCameraRecording,
  putCameraRecordingBitrate,
  putCameraStream
} from 'reducers/api/cameraRecording/cameraRecordingActions'
import { getCalculation } from 'reducers/api/workspace/workspaceActions'
import { useAppDispatch } from 'reducers/hooks'
import { columnAcessors, useCameraGroupData } from './useCameraGroupData'
import Button from 'components/Button/Button'
import { useState } from 'react'
import BulkApplyModal from './BulkApplyModal'

const CameraGroupTable = ({ workspaceId }: CameraGroupTableProps) => {
  const { quantity, lensTypes, recordingType, hoursPerDay, retentionDays, codec, fps, motion, complexity, resolution, bitrateKbit } = columnAcessors
  const [isModalDisplayed, displayModal] = useState(false)
  const dispatch = useAppDispatch()
  const { cameraRecordings, cameraConfigId, pageTableColumns } = useCameraGroupData(workspaceId)

  const updateCameraRecording = (recording: CameraRecordingDataRow, columnId: string) => {
    switch (columnId) {
      case retentionDays:
        dispatch(putCameraStream({ workspaceId: workspaceId, recording })).then(() => dispatch(getCalculation({ workspaceId })))
        break
      case quantity:
        dispatch(putCameraGroup({ workspaceId: workspaceId, recording })).then(() => dispatch(getCalculation({ workspaceId })))
        break
      case complexity:
      case resolution:
      case codec:
      case fps:
        dispatch(putCameraRecordingBitrate({ workspaceId: workspaceId, recording })).then(() => dispatch(getCalculation({ workspaceId })))
        break
      case recordingType:
        handleRecordingChange(recording)
        break
      case hoursPerDay:
      case bitrateKbit:
      case motion:
        dispatch(putCameraRecording({ workspaceId: workspaceId, recording })).then(() => dispatch(getCalculation({ workspaceId })))
        break
      case lensTypes:
        handleLensTypeChange(recording)
        break
      default:
        break
    }
  }

  const addCameraGroup = () => {
    dispatch(postCameraGroup({ workspaceId, cameraConfigId })).then(() => dispatch(getCalculation({ workspaceId })))
  }
  const removeCameraGroup = (recordingId: string) => {
    dispatch(
      deleteCameraGroup({
        workspaceId,
        cameraConfigId,
        cameraGroupId: cameraRecordings.find(recording => recording.id === parseInt(recordingId)).cameraGroupId
      })
    ).then(() => dispatch(getCalculation({ workspaceId })))
  }
  const addCameraStream = (recordingId: number) => {
    const recording = cameraRecordings.find(recording => recording.id === recordingId)
    dispatch(postCameraStream({ workspaceId, cameraConfigId: recording.cameraConfigId, cameraGroupId: recording.cameraGroupId })).then(() => dispatch(getCalculation({ workspaceId })))
  }
  const duplicateCameraGroup = (recordingId: number) => {
    const recording = cameraRecordings.find(recording => recording.id === recordingId)
    dispatch(copyCameraGroup({ workspaceId, cameraConfigId: recording.cameraConfigId, cameraGroupId: recording.cameraGroupId })).then(() => dispatch(getCalculation({ workspaceId })))
  }

  const handleRecordingChange = (recording: CameraRecordingDataRow) => {
    const currentRecording = cameraRecordings.find(currentRecording => currentRecording.id === recording.id)
    dispatch(
      putCameraRecordingBitrate({
        workspaceId: workspaceId,
        recording: {
          ...recording,
          motion: recording.recording === RecordingType.Event || recording.recording === RecordingType.Speedup ? { motion: 40, continuous: 0 } : recording.motion,
          fps: getFps(recording),
          resolution: getResolution(currentRecording),
          complexity: getComplexity(currentRecording)
        }
      })
    ).then(() => dispatch(getCalculation({ workspaceId })))
  }

  const handleLensTypeChange = (recording: CameraRecordingDataRow) => {
    if (recording.lensTypes === LensType.MultiLensParent) {
      addCameraStream(recording.id)
    } else {
      const cameraStreamsToDelete = cameraRecordings
        .filter(cameraRecording => cameraRecording.cameraGroupId === recording.cameraGroupId && cameraRecording.lensTypes === LensType.MultiLensChild)
        .map(cameraRecording => cameraRecording.cameraStreamId)
      dispatch(
        deleteCameraStream({
          streamIds: cameraStreamsToDelete,
          cameraConfigId: cameraConfigId,
          workspaceId: workspaceId,
          cameraGroupId: recording.cameraGroupId
        })
      ).then(() => dispatch(getCalculation({ workspaceId })))
    }
  }

  const getFps = (recording: CameraRecordingDataRow): CameraRecordingComplexCell<number> => {
    switch (recording.recording) {
      case RecordingType.Constant:
        return { continuous: 15, motion: 0 }
      case RecordingType.Event:
        return { continuous: 0, motion: 15 }
      case RecordingType.Speedup:
        return { continuous: 15, motion: 15 }
      default:
        return recording.fps
    }
  }

  const getResolution = (recording: CameraRecordingDataRow): CameraRecordingComplexCell<string> => {
    switch (recording.recording) {
      case RecordingType.Constant:
        return { continuous: recording.resolution.continuous, motion: recording.resolution.continuous }
      case RecordingType.Event:
        return { continuous: recording.resolution.motion, motion: recording.resolution.motion }
      default:
        return recording.resolution
    }
  }

  const getComplexity = (recording: CameraRecordingDataRow): CameraRecordingComplexCell<string> => {
    switch (recording.recording) {
      case RecordingType.Constant:
        return { continuous: recording.complexity.continuous, motion: recording.complexity.continuous }
      case RecordingType.Event:
        return { continuous: recording.complexity.motion, motion: recording.complexity.motion }
      default:
        return recording.complexity
    }
  }

  return (
    <>
      <div className="xsd__camera-group-table">
        {cameraRecordings && (
          <Table
            tableColumns={pageTableColumns}
            initialData={cameraRecordings}
            disableSearch={true}
            addButtonLabel="Add Camera Group"
            addButtonLabelIcon="plus"
            handleUpdateDataRow={updateCameraRecording}
            handleAddDataRow={addCameraGroup}
            handleDeleteDataRow={removeCameraGroup}
            handleAddSubRow={dataRowId => addCameraStream(parseInt(dataRowId))}
            handleDuplicateRow={duplicateCameraGroup}
            isDoubleCell
            deleteModalHeadline="Are you sure you want to delete this camera group?"
            customButtons={[<Button key="bulk-settings" label="Bulk Apply Setting" onClickHandler={() => displayModal(true)} />]}
          />
        )}
      </div>
      <BulkApplyModal displayModal={displayModal} isModalDisplayed={isModalDisplayed} updateCameraRecording={updateCameraRecording} workspaceId={workspaceId} />
    </>
  )
}

type CameraGroupTableProps = {
  workspaceId: number
}

export default CameraGroupTable
