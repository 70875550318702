import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg"
import "./row-action.scss"

// Create an cell renderer
export const DeleteRowAction = ({
    rowIndex,
    deleteClickHandler, // This is a custom function that we supplied to our table instance
}) => {
    return (
        <button className="row-action" type="button" onClick={() => deleteClickHandler(rowIndex)} title="Delete">
            <svg className="msds-icon">
                <use href={`${spritemap}#trash`} />
            </svg>
        </button>
    )
}

