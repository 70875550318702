import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import { useEffect, useState } from "react";

const Select = ({
  options,
  defaultOption,
  initialValue,
  handleDataChange,
  isSmall,
  className,
  isDisabled
}: SelectProps) => {
  const [value, setValue] = useState<string>(initialValue ?? '');

  const optionElements = options.map((opt) => {
    return (
      <option key={opt.label} value={opt.value}>
        {opt.label}
      </option>
    );
  });

  useEffect(() => {
    if (initialValue) {
        setValue(initialValue)
    }
  }, [initialValue]);

  const handleSelectChange = (value) => {
    setValue(value)
    handleDataChange(value)
  }

  return (
    <div className={`${className ? className : ''}`}>
      <label className="msds-text-body-2 msds-text-gray-10 d-block  mb-3">{defaultOption?.label}</label>
      <div className='msds-select-input'>
        <select
          value={value}
          autoComplete="no"
          className={`msds-select-input__select ${isSmall ? 'msds-select-input__select--small' : ''}`}
          onChange={(e) => handleSelectChange(e.target.value)}
          disabled={isDisabled}
        >        
          {optionElements}
        </select>
        <div className="msds-select-input__icon">
          <svg>
            <use href={`${spritemap}#right-arrow`} />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Select;

type SelectProps = {
  options: { label: string; value: string }[];
  defaultOption?: { label: string; value: string };
  initialValue?: string;
  handleDataChange?: (newValue: any) => void;
  isSmall?: boolean;
  className?: string
  isDisabled?: boolean
};
