let environment = "localhost";

const CONFIG = {
    "xsd.milestonesys.wdev": {
        MILESTONE_URL: "https://www.milestonesys.wdev",
        API_URL: "https://xsdapi.milestonesys.xyz/api",
        IDENTITY_MANAGER_CONFIG: {
            authority: "https://sso2.milestonesys.xyz",
            client_id: "milestone_xsd",
            redirect_uri: "https://xsd.milestonesys.wdev/authentication-callback",
            response_type: "code",
            scope: "openid profile email offline_access",
            post_logout_redirect_uri: "https://www.milestonesys.wdev",
            accessTokenExpiringNotificationTime: 10,
            monitorSession: false
        }
    },
    "xsd.milestonesys.wtst": {
        MILESTONE_URL: "https://www.milestonesys.wtst",
        API_URL: "https://xsdapi.milestonesys.xyz/api",
        IDENTITY_MANAGER_CONFIG: {
            authority: "https://sso2.milestonesys.xyz",
            client_id: "milestone_xsd",
            redirect_uri: "https://xsd.milestonesys.wtst/authentication-callback",
            response_type: "code",
            scope: "openid profile email offline_access",
            post_logout_redirect_uri: "https://www.milestonesys.wtst",
            accessTokenExpiringNotificationTime: 10,
            monitorSession: false
        }
    },
    "xsd.milestonesys.xyz": {
        MILESTONE_URL: "https://www.milestonesys.xyz",
        API_URL: "https://xsdapi.milestonesys.xyz/api",
        IDENTITY_MANAGER_CONFIG: {
            authority: "https://sso2.milestonesys.xyz",
            client_id: "milestone_xsd",
            redirect_uri: "https://xsd.milestonesys.xyz/authentication-callback",
            response_type: "code",
            scope: "openid profile email offline_access",
            post_logout_redirect_uri: "https://www.milestonesys.xyz",
            accessTokenExpiringNotificationTime: 10,
            monitorSession: false
        }
    },
    "xsd.milestonesys.com": {
        MILESTONE_URL: "https://www.milestonesys.com",
        API_URL: "https://xsdapi.milestonesys.com/api",
        IDENTITY_MANAGER_CONFIG: {
            authority: "https://sso2.milestonesys.com",
            client_id: "milestone_xsd",
            redirect_uri: "https://xsd.milestonesys.com/authentication-callback",
            response_type: "code",
            scope: "openid profile email offline_access",
            post_logout_redirect_uri: "https://www.milestonesys.com",
            accessTokenExpiringNotificationTime: 10,
            monitorSession: false
        }
    },
    localhost: {
        MILESTONE_URL: "https://www.milestonesys.wdev",
        API_URL: "https://xsdapi.milestonesys.xyz/api",
        IDENTITY_MANAGER_CONFIG: {
            authority: "https://sso2.milestonesys.xyz",
            client_id: "milestone_xsd",
            redirect_uri: "http://localhost:3000/authentication-callback",
            response_type: "code",
            scope: "openid profile email offline_access",
            post_logout_redirect_uri: "http://localhost:3000/",
            accessTokenExpiringNotificationTime: 10,
            monitorSession: false
        },
    },
};

export const loadConfig = () => {
    environment = window.location.host.split(":")[0];
};

export const API_URL = () => CONFIG[environment].API_URL;
export const MILESTONE_URL = () => CONFIG[environment].MILESTONE_URL;
export const ENV = () => environment;

export const IDENTITY_MANAGER_CONFIG = () =>
    CONFIG[environment].IDENTITY_MANAGER_CONFIG;
