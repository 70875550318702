import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LensType, RecordingType, Status } from "lib/enums"
import { CameraRecording, CameraRecordingComplexCell, CameraRecordingDataRow } from "lib/types"
import { RootState } from "reducers/store"
import { CameraGroupDeletePayload, CameraGroupPostPayload, CameraGroupPutPayload, CameraRecordingGetPayload, CameraRecordingUpsertPayload, CameraStreamDeletePayload, CameraStreamPostPayload, CameraStreamPutPayload, copyCameraGroup, defaultGroup, defaultRecording, defaultStream, deleteCameraGroup, deleteCameraStream, getCameraRecording, postCameraGroup, postCameraStream, putCameraGroup, putCameraRecording, putCameraRecordingBitrate, putCameraStream } from "./cameraRecordingActions"

type CameraRecordingState = {
    data: { [id: number]: CameraRecordingDataRow[] },
    statuses: { [id: number]: Status },
    workspaceToConfigMap: { [id: number]: number }
}

const initialState: CameraRecordingState = {
    data: {},
    statuses: {},
    workspaceToConfigMap: {}
}

const cameraRecordingSlice = createSlice({
    name: "cameraRecording",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            //camera recording
            .addCase(getCameraRecording.pending, (state, action: PayloadAction<CameraRecordingGetPayload, string, { arg: number }>) => {
                state.statuses[action.meta.arg] = Status.Pending
            })
            .addCase(getCameraRecording.fulfilled, (state, action: PayloadAction<CameraRecordingGetPayload>) => {
                if (action.payload.data && action.payload.data.length > 0) {
                    //API supports multiple camera configs but ux/ui design and web app not, so only first camera config is selected
                    //Web app has to make sure that only one camera config is inserted
                    const firstCameraConfig = action.payload.data[0]
                    state.data[action.payload.workspaceId] = firstCameraConfig.cameraGroups
                        .flatMap(group => group.cameraStreams
                            .flatMap(stream => stream.cameraRecordings
                                ?.map(recording => recordingDtoToDataRow(
                                    recording,
                                    stream.retentionDays,
                                    group.quantity,
                                    group.id,
                                    action.payload.data[0].id,
                                    group.cameraStreams?.length > 1
                                        ? group.cameraStreams.length > 1 && group.cameraStreams[0].id !== recording.cameraStreamId
                                            ? LensType.MultiLensChild
                                            : LensType.MultiLensParent
                                        : LensType.SingleLens
                                ))))
                        .sort((recordingA, recordingB) => recordingB.cameraGroupId - recordingA.cameraGroupId)
                    state.workspaceToConfigMap[action.payload.workspaceId] = firstCameraConfig.id
                }
                state.statuses[action.payload.workspaceId] = Status.Success
            })
            .addCase(getCameraRecording.rejected, (state, action: PayloadAction<CameraRecordingGetPayload>) => {
                state.statuses[action.payload.workspaceId] = Status.Rejected
                console.log(action.payload.error)
            })
            .addCase(putCameraRecording.fulfilled, (state, action: PayloadAction<CameraRecordingUpsertPayload>) => {
                const recordingIndex = state.data[action.payload.workspaceId].findIndex(recording => recording.id === action.payload.data.id)
                state.data[action.payload.workspaceId][recordingIndex] = recordingDtoToDataRow(
                    action.payload.data,
                    action.payload.recording.retentionDays,
                    action.payload.recording.quantity,
                    action.payload.recording.cameraGroupId,
                    action.payload.recording.cameraConfigId,
                    action.payload.recording.lensTypes)
            })
            .addCase(putCameraRecording.rejected, (_, action: PayloadAction<CameraRecordingUpsertPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(putCameraRecordingBitrate.fulfilled, (state, action: PayloadAction<CameraRecordingUpsertPayload>) => {
                const recordingIndex = state.data[action.payload.workspaceId].findIndex(recording => recording.id === action.payload.data.id)
                state.data[action.payload.workspaceId][recordingIndex] = recordingDtoToDataRow(
                    action.payload.data,
                    action.payload.recording.retentionDays,
                    action.payload.recording.quantity,
                    action.payload.recording.cameraGroupId,
                    action.payload.recording.cameraConfigId,
                    action.payload.recording.lensTypes)
            })
            .addCase(putCameraRecordingBitrate.rejected, (_, action: PayloadAction<CameraRecordingUpsertPayload>) => {
                console.log(action.payload.error)
            })
            //camera group
            .addCase(putCameraGroup.fulfilled, (state, action: PayloadAction<CameraGroupPutPayload>) => {
                const recordingIndex = state.data[action.payload.workspaceId].findIndex(recording => recording.id === action.payload.recording.id)
                state.data[action.payload.workspaceId][recordingIndex] = {
                    ...state.data[action.payload.workspaceId][recordingIndex],
                    quantity: action.payload.data.quantity
                }
            })
            .addCase(putCameraGroup.rejected, (_, action: PayloadAction<CameraGroupPutPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(postCameraGroup.fulfilled, (state, action: PayloadAction<CameraGroupPostPayload>) => {
                const newCameraRecording = recordingDtoToDataRow(
                    action.payload.recording,
                    action.payload.stream.retentionDays,
                    action.payload.group.quantity,
                    action.payload.group.id,
                    action.payload.cameraConfigId,
                    action.payload.group.cameraStreams?.length > 1 ? LensType.MultiLensParent : LensType.SingleLens)
                const currentRecordings = state.data[action.payload.workspaceId] ?? []
                state.data[action.payload.workspaceId] = [...currentRecordings, newCameraRecording]
                    .sort((recordingA, recordingB) => recordingB.cameraGroupId - recordingA.cameraGroupId)
            })
            .addCase(postCameraGroup.rejected, (_, action: PayloadAction<CameraGroupPostPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(deleteCameraGroup.fulfilled, (state, action: PayloadAction<CameraGroupDeletePayload>) => {
                const newState = state.data[action.payload.workspaceId].filter(recording => recording.cameraGroupId !== action.payload.groupId)
                state.data[action.payload.workspaceId] = newState
            })
            .addCase(deleteCameraGroup.rejected, (_, action: PayloadAction<CameraGroupDeletePayload>) => {
                console.log(action.payload.error)
            })
            .addCase(copyCameraGroup.fulfilled, (state, action: PayloadAction<CameraGroupPostPayload>) => {
                const newCameraRecordings = action.payload.group.cameraStreams.flatMap(stream => stream.cameraRecordings
                    ?.map(recording => recordingDtoToDataRow(
                        recording,
                        stream.retentionDays,
                        action.payload.group.quantity,
                        action.payload.group.id,
                        action.payload.cameraConfigId,
                        action.payload.group.cameraStreams?.length > 1
                            ? action.payload.group.cameraStreams.length > 1 && action.payload.group.cameraStreams[0].id !== recording.cameraStreamId
                                ? LensType.MultiLensChild
                                : LensType.MultiLensParent
                            : LensType.SingleLens
                    )))
                const currentRecordings = state.data[action.payload.workspaceId] ?? []
                state.data[action.payload.workspaceId] = [...currentRecordings, ...newCameraRecordings]
                    .sort((recordingA, recordingB) => recordingB.cameraGroupId - recordingA.cameraGroupId)
            })
            .addCase(copyCameraGroup.rejected, (_, action: PayloadAction<CameraGroupPostPayload>) => {
                console.log(action.payload.error)
            })
            //camera stream
            .addCase(putCameraStream.fulfilled, (state, action: PayloadAction<CameraStreamPutPayload>) => {
                const recordingIndex = state.data[action.payload.workspaceId].findIndex(recording => recording.id === action.payload.recording.id)
                state.data[action.payload.workspaceId][recordingIndex] = {
                    ...state.data[action.payload.workspaceId][recordingIndex],
                    retentionDays: action.payload.data.retentionDays
                }
            })
            .addCase(putCameraStream.rejected, (_, action: PayloadAction<CameraStreamPutPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(postCameraStream.fulfilled, (state, action: PayloadAction<CameraStreamPostPayload>) => {
                const newCameraRecording = recordingDtoToDataRow(
                    action.payload.recording,
                    action.payload.stream.retentionDays,
                    0,
                    action.payload.cameraGroupId,
                    action.payload.cameraConfigId,
                    LensType.MultiLensChild)
                const newState = [...state.data[action.payload.workspaceId]]
                const parentStreamIndex = newState.findIndex(recording => recording.cameraGroupId === action.payload.cameraGroupId)
                newState[parentStreamIndex].lensTypes = LensType.MultiLensParent
                state.data[action.payload.workspaceId] = [...newState, newCameraRecording]
                    .sort((recordingA, recordingB) => recordingB.cameraGroupId - recordingA.cameraGroupId)
            })
            .addCase(postCameraStream.rejected, (_, action: PayloadAction<CameraStreamPostPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(deleteCameraStream.fulfilled, (state, action: PayloadAction<CameraStreamDeletePayload>) => {
                const newState = state.data[action.payload.workspaceId].filter(recording => !action.payload.streamIds.includes(recording.cameraStreamId))
                const parentStreamIndex = newState.findIndex(recording => recording.cameraGroupId === action.payload.cameraGroupId)
                newState[parentStreamIndex].lensTypes = LensType.SingleLens
                state.data[action.payload.workspaceId] = newState
            })
            .addCase(deleteCameraStream.rejected, (_, action: PayloadAction<CameraStreamDeletePayload>) => {
                console.log(action.payload.error)
            })
    }
})

const getRecordingType = (recording: CameraRecording): RecordingType => {
    if (recording.continuousEnabled && recording.motionEnabled) {
        return RecordingType.Speedup
    }
    else if (recording.continuousEnabled) {
        return RecordingType.Constant
    }
    else {
        return RecordingType.Event
    }
}

const getMotion = (recording: CameraRecording): CameraRecordingComplexCell<number> => {
    if (recording.continuousEnabled && recording.motionEnabled) {
        return { continuous: 100 - recording.motionPercentage, motion: recording.motionPercentage }
    }
    else {
        return { continuous: 0, motion: recording.motionPercentage }
    }
}

const recordingDtoToDataRow = (recording: CameraRecording, retentionDays: number, quantity: number, groupId: number, configId: number, lensType: LensType): CameraRecordingDataRow => {
    return {
        id: recording.id,
        cameraConfigId: configId,
        cameraGroupId: groupId,
        cameraStreamId: recording.cameraStreamId,
        quantity: quantity,
        lensTypes: lensType,
        recording: getRecordingType(recording),
        hoursPerDay: recording.hoursPerDay,
        retentionDays: retentionDays,
        codec: recording.codec?.domainValueId.toString(),
        fps: { continuous: recording.continuousFps, motion: recording.motionFps },
        motion: getMotion(recording),
        complexity: { continuous: recording.continuousComplexity?.domainValueId.toString(), motion: recording.motionComplexity?.domainValueId.toString() },
        resolution: { continuous: recording.continuousResolution?.domainValueId.toString(), motion: recording.motionResolution?.domainValueId.toString() },
        bitrateKbit: { continuous: Math.trunc(recording.continuousUserBitrate?.to_kbps ?? 0), motion: Math.trunc(recording.motionUserBitrate?.to_kbps ?? 0) }
    }
}

export const getDefaultRecordingDataRow = (): CameraRecordingDataRow => recordingDtoToDataRow(defaultRecording, defaultStream.retentionDays, defaultGroup.quantity, 0, 0, LensType.SingleLens)

export const selectAreCamerasLoading = (state: RootState): boolean => Object.values(state.cameraRecording.statuses).some(status => status === Status.Pending)

export default cameraRecordingSlice.reducer