import Select from 'components/FormComponents/Select/Select'

const EditableSelectCell = ({
  value,
  row: { index },
  column: { id },
  updateMyData,
  options,
  isDisabled,
  inputCreator
}: EditableSelectCellProps) => {
  const handleInputChange = value => {
    if (!updateMyData) {
        return
    }

    if (inputCreator) {
      updateMyData(index, id, inputCreator(value))
    } else {
      updateMyData(index, id, value)
    }
  }

  return <Select options={options} handleDataChange={handleInputChange} isSmall initialValue={value} isDisabled={isDisabled} />
}

export default EditableSelectCell

type EditableSelectCellProps = {
  value?: string
  row: { index: number }
  column: { id: number }
  updateMyData?: (index: number, id: number, value: string) => void
  isDisabled?: boolean
  options: { label: string; value: string }[]
  inputCreator?: (inputValue: string) => any
}
