import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import classNames from "classnames";

const MsdsModal = ({ id, content, showModal, onCloseButtonclick }: MsdsModalProps) => {

  return (
    <div id={id} className={classNames("msds-modal text-center", showModal ? "msds-modal--show" : "")} >
      <div onClick={onCloseButtonclick} className="msds-modal__close text-decoration-none msds-btn-floating msds-btn-floating--lg msds-btn-floating--secondary">
        <svg className="msds-icon">
          <use href={`${spritemap}#cross`} />
        </svg>
      </div>
      <div className="msds-modal__overlay container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          {content}
        </div>
      </div>
    </div>
  );
};

type MsdsModalProps = {
  id: string;
  content: JSX.Element;
  showModal: boolean;
  onCloseButtonclick: () => void;
};

export default MsdsModal;