import { configureStore } from "@reduxjs/toolkit";
import domainSlice from "./api/domain/domainSlice";
import projectsSlice from "./api/projects/projectsSlice";
import sitesSlice from "./api/sites/sitesSlice";
import user from "./userSlice";
import optionsSetup from "./api/optionsSetup/optionsSetupSlice";
import cameraRecordingSlice from "./api/cameraRecording/cameraRecordingSlice";
import workspaceSlice from "./api/workspace/workspaceSlice";
import siteDesignSlice from "./api/siteDesign/siteDesignSlice";

export const store = configureStore({
    reducer: {
        user,
        optionsSetup: optionsSetup,
        projects: projectsSlice,
        sites: sitesSlice,
        domain: domainSlice,
        cameraRecording: cameraRecordingSlice,
        siteDesign: siteDesignSlice,
        workspace: workspaceSlice

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
