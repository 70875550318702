import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { toShortDate } from "lib/helpers";
import { ProjectType, Nullable } from "lib/types";
import { RootState } from "reducers/store";
import { getProjectsThunk as getProjects, postProjectThunk as postProject, updateProjectThunk as updateProject, deleteProjectThunk as deleteProject, duplicateProject } from "./action";

export type ProjectesState = {
    data: Nullable<ProjectType[]>;
    projectsStatus: Status;
    backendErrors: {
        projectStatusErrorMsg: string;
    };
    upsertStatus: Status
};

const initialState: ProjectesState = {
    data: [],
    projectsStatus: Status.Idle,
    backendErrors: {
        projectStatusErrorMsg: "string",
    },
    upsertStatus: Status.Idle
};

const getProjectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.pending, (state, action) => {
                state.projectsStatus = Status.Pending;
            })
            .addCase(getProjects.rejected, (state, action) => {
                state.projectsStatus = Status.Rejected;
                state.backendErrors.projectStatusErrorMsg = action.payload;
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.projectsStatus = Status.Success;
                state.data = updateDataDate(action.payload.data.sort((a, b) => parseInt(b.id) - parseInt(a.id)));
                state.backendErrors.projectStatusErrorMsg = "";
            })
            .addCase(postProject.rejected, (state, action) => {
                state.upsertStatus = Status.Rejected;
                state.backendErrors.projectStatusErrorMsg = action.payload;
            })
            .addCase(postProject.pending, (state, action) => {
                state.upsertStatus = Status.Pending;
            })
            .addCase(postProject.fulfilled, (state, action) => {
                state.upsertStatus = Status.Success;
                state.data = updateDataDate([...state.data, action.payload].sort((a, b) => parseInt(b.id) - parseInt(a.id)));

                state.backendErrors.projectStatusErrorMsg = "";
            })
            .addCase(updateProject.rejected, (state, action) => {
                state.projectsStatus = Status.Rejected;
                state.backendErrors.projectStatusErrorMsg = action.payload;
            })
            .addCase(updateProject.pending, (state, action) => {
                state.upsertStatus = Status.Pending;
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                const newStateData = [...state.data]
                const projectIndex = newStateData.findIndex(project => project.id === action.payload.id)
                newStateData[projectIndex] = action.payload;
                state.upsertStatus = Status.Success;
                state.data = updateDataDate(newStateData.sort((a, b) => parseInt(b.id) - parseInt(a.id)));
                state.backendErrors.projectStatusErrorMsg = "";
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.projectsStatus = Status.Rejected;
                state.backendErrors.projectStatusErrorMsg = action.payload;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                const newStateData = state.data.filter(d => d.id !== action.meta.arg.projectId);
                state.data = newStateData;
                state.backendErrors.projectStatusErrorMsg = "";
            })
            .addCase(duplicateProject.rejected, (state, _) => {
                state.upsertStatus = Status.Rejected;
            })
            .addCase(duplicateProject.pending, (state, _) => {
                state.upsertStatus = Status.Pending;
            })
            .addCase(duplicateProject.fulfilled, (state, action) => {
                state.upsertStatus = Status.Success;
                const newState = [...state.data, action.payload].sort((a, b) => parseInt(b.id) - parseInt(a.id))
                state.data = updateDataDate(newState);
            })
        },
});

export const selectAreProjectsLoading = (state: RootState): boolean => state.projects.projectsStatus === Status.Pending || state.projects.upsertStatus === Status.Pending

const updateDataDate = (projects: ProjectType[]) => {
    projects.forEach(p => p.formattedUtc = toShortDate(p.createdUtc))
    return projects
}

export default getProjectsSlice.reducer;
