export enum Status {
    Idle = "idle",
    Pending = "pending",
    Rejected = "rejected",
    Success = "success",
}
export enum ButtonStyles {
    Primary = "msds-btn--primary",
    Secondary = "msds-btn--secondary",
    SecondaryDark = "msds-btn--secondary-dark",
    Tertiary = "msds-btn--tertiary",
    Gray = "msds-btn--gray",
    Danger = "msds-btn--danger",
}

export enum ButtonSize {
    Large = "msds-btn--lg",
    Medium = "",
    Small = "msds-btn--sm",
}

export enum ButtonGroupAlignment {
    Left = "",
    Center = "msds-btn-group--center",
    Right = "msds-btn-group--right",
}

export enum ModalPopupSize {
    Large = "col-12",
    Medium = "col-12 col-md-10 col-lg-8",
    Small = "col-12 col-md-8 col-lg-6"
}

export enum DomainName {
    XProtectPlatform = "xprotectplatform",
    RaidType = "raidtype",
    StorageDeviceType = "storagedevicetype",
    Codec = "codec",
    ImageComplexity = "imagecomplexity",
    ImageResultion = "imageresolution",
    ConnectionType = "connectiontype",
    MachineType = "machinetype",
    StorageDevice = "storagedevice",
    FailoverMode = 'failovermode'
}

export enum LensType {
    SingleLens = 'Single Lens',
    MultiLensParent = 'Multi Lens',
    MultiLensChild = 'Multi Lens :hidden'
}

export enum RecordingType {
    Constant = 'Constant',
    Event = 'Event',
    Speedup = 'Speedup'
}

export enum ManagementServerConfig {
    Auto,
    MsConfig1,
    MsConfig2,
    MsConfig3,
    MsConfig4
}

export enum ServerRoles {
    Recording = "Recording",
    Failover = "RecordingFailover",
    Management = "Management"
}

export enum StorageRoles {
    System = "System",
    Live = "Live",
    Archive = "Archive"
}