import { createSlice } from "@reduxjs/toolkit";
import { UserData } from "lib/types";
import type { RootState } from "reducers/store";

type UserReducerState = {
  isAuthenticated?: boolean;
  userData: UserData
};

const initialState: UserReducerState = {
  isAuthenticated: null,
  userData: {} as UserData
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserData: (state, action: { type: string, payload: UserData }) => {
        state.userData = action.payload;
    }
  },
});

export const selectIsAuthenticated = (state: RootState) =>
  state.user.isAuthenticated;

export const { setAuthenticated, setUserData } = userSlice.actions;

export default userSlice.reducer;
