const ProposalTable = ({ title, head, body, forceAlign }: ProposalTableProps) => {
    const getDefaultValue = (value: string | number): string | number => value != null ? value.toString() : 'NO DATA'

    const forceAlignCssClass = forceAlign ? "msds-table__head-row msds-table__header msds-table__header-align" : "msds-table__head-row msds-table__header";

    return (
        <div className="mb-4">
            {title && <div className="msds-text-header-5 py-4">{title}</div>}
            <div className="msds-table msds-table--small">
                <table>
                    <thead className="msds-table__head">
                        <tr>
                            {head?.map((header, index) =>
                                <th className={forceAlignCssClass} key={index}>
                                    <div className="msds-table__header-headline">{header}</div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="msds-table__body">
                        {body?.map((row, index) =>
                            <tr className="msds-table__body-row" key={index}>
                                {row?.map((cell, index) =>
                                    <td className="msds-table__body-row-data" key={index}>{getDefaultValue(cell)}</td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

type ProposalTableProps = {
    title?: string,
    head: (string | number)[],
    body: (string | number)[][],
    forceAlign?: boolean
}

export default ProposalTable