import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { API_URL } from 'config'
import { getProjects, postProject, updateProject } from 'lib/api'
import { ProjectType } from 'lib/types'

export const getProjectsThunk = createAsyncThunk<
  AxiosResponse<ProjectType[]>,
  void, // args type
  { rejectValue: string } // rejection response value type
>('projects/getProjects', async (_, { rejectWithValue }) => {
  try {
    return await getProjects()
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export const postProjectThunk = createAsyncThunk<
  ProjectType, // response type
  string, // args type
  { rejectValue: string } // rejection response value type
>('projects/postProject', async (projectName: string, { rejectWithValue }) => {
  try {
    return await postProject({
      name: projectName
    })
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export const updateProjectThunk = createAsyncThunk<
  ProjectType, // response type
  ProjectType, // args type
  { rejectValue: string } // rejection response value type
>('projects/updateProject', async (project: ProjectType, { rejectWithValue }) => {
  try {
    return await updateProject(project)
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export type ProjectPayload = {
  data?: ProjectType
  error?: string
}

export const deleteProjectThunk = createAsyncThunk<
  ProjectPayload, // response type
  { projectId: string }, // args type
  { rejectValue: string } // rejection response value type
>('projects/deleteProject', async (args, { rejectWithValue }) => {
  try {
    await axios.delete(`${API_URL()}/project/${args.projectId}`)
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})

export const duplicateProject = createAsyncThunk<ProjectType, { projectId: number }, { rejectValue: string }>('projects/duplicate', async ({ projectId }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${API_URL()}/project/${projectId}/copy`)
    return response.data
  } catch (err) {
    let error: AxiosError<string> = err
    return rejectWithValue(error.response.data)
  }
})
