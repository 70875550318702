import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import { useEffect, useState } from "react";

const Checkbox = ({ id, label, handleChange, initialValue, className, isDisabled }: CheckBoxProps) => {
  const [value, setValue] = useState(initialValue ? initialValue : false)

  useEffect(() => {
    setValue(initialValue ? initialValue : false)
  }, [initialValue])

  const handleInputChange = () => {
    const newValue = !value
    setValue(newValue)
    handleChange && handleChange(newValue)
  }

  return (
    <div className={`msds-checkbox ${className ? className : ''} pt-7`}>
      <input
        id={id}
        className="msds-checkbox__input"
        type="checkbox"
        onChange={handleInputChange}
        checked={value}
        disabled={isDisabled}
      />
      <label className="msds-checkbox__label msds-text-body-1 msds-text-gray-10" htmlFor={id}>
        <svg className="msds-checkbox__checkmark">
          <use href={`${spritemap}#checkmark`} />
        </svg>
        {label}
      </label>
    </div>
  )
}

type CheckBoxProps = {
  id: string
  label: string,
  initialValue?: boolean,
  handleChange?: (boolean) => void,
  className?: string,
  isDisabled?: boolean
}

export default Checkbox