import classNames from "classnames";
import { ButtonProps } from "lib/types";
import { ButtonStyles, ButtonSize } from "lib/enums";
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";

const Button = ({
  id,
  label,
  href,
  size,
  styling,
  cssClass,
  tabIndex,
  icon,
  //eventCategory,
  //eventLabel,
  onClickHandler,
}: ButtonProps) => {
  return (
    <a
      id={id}
      className={classNames(
        "msds-btn",
        size ? size : ButtonSize.Medium,
        styling ? styling : ButtonStyles.Primary,
        cssClass ? cssClass : "",
        //(eventCategory && eventLabel) ? "googlelinktracking" : ""
      )}
      onClick={onClickHandler}
      href={href}
      // data-enable-google-event-tracking={(eventCategory && eventLabel) ? true : false}
      // data-category={(eventCategory && eventLabel) ? eventCategory : ""}
      // data-label={(eventCategory && eventLabel) ? eventLabel : ""}
      tabIndex={tabIndex}
    >
      {label}
      {icon &&
        <div className="msds-icon">
          <svg >
            <use href={spritemap + "#" + icon} />
          </svg>
        </div>}
    </a>
  );
};

export default Button;