import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DomainName, Status } from "lib/enums"
import { enumToObject } from "lib/helpers"
import { DomainType } from "lib/types"
import { RootState } from "reducers/store"
import { getDomain, GetDomainPayload } from "./domainActions"

export type DomainState = {
    domains: { [key in DomainName]: DomainType[] },
    statuses: { [key in DomainName]: Status }
}

const initialState: DomainState = {
    domains: enumToObject(DomainName, []),
    statuses: enumToObject(DomainName, Status.Idle)
};

const domainSlice = createSlice({
    name: "domain",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getDomain.pending, (state, action: PayloadAction<GetDomainPayload, string, { arg: DomainName }>) => {
                state.statuses[action.meta.arg] = Status.Pending;
            })
            .addCase(getDomain.fulfilled, (state, action: PayloadAction<GetDomainPayload>) => {
                state.statuses[action.payload.domainName] = Status.Success;
                state.domains[action.payload.domainName] = action.payload.data
            })
            .addCase(getDomain.rejected, (state, action: PayloadAction<GetDomainPayload>) => {
                state.statuses[action.payload.domainName] = Status.Rejected;
                console.log(action.payload.error)
            })
    },
});

export const selectAreDomainsLoading = (state: RootState): boolean => Object.values(state.domain.statuses).some(status => status === Status.Pending)

export default domainSlice.reducer;
