import { getCameraRecording } from 'reducers/api/cameraRecording/cameraRecordingActions';
import { getOptionsSetup } from 'reducers/api/optionsSetup/optionsSetupActions';
import { useApiData } from 'reducers/hooks';
import { WorkspaceDetails } from 'reducers/api/workspace/workspaceSlice'
import { ManagementServerConfig } from 'lib/enums'
import { ManagementServerSpecificationProposal } from 'lib/types'

const ProposalManagementPdf = ({ workspace }: ProposalManagementPdfProps) => {
    const { workspaceId } = workspace ?? {}

    let managmentProposal;

    const optionsSetup = useApiData(
        getOptionsSetup(workspaceId),
        state => state.optionsSetup.data[workspaceId],
        state => state.optionsSetup.statuses[workspaceId]
    )

    const cameraRecordings = useApiData(
        getCameraRecording(workspaceId),
        state => state.cameraRecording.data[workspaceId],
        state => state.cameraRecording.statuses[workspaceId]
    )

    const managementProposals: { [id: number]: ManagementServerSpecificationProposal } = {
        [ManagementServerConfig.MsConfig1]: {
            serverQuantity: 1,
            serverProcessorType: 'Intel XEON 3408u',
            serverOsApplication: '2 x 300GB Raid 1',
            serverDiskType: 'SSD',
            serverRam: '16GB RAM',
            dbQuantity: 2,
            dbDiskType: 'SSD',
            dbDiskSize: '300GB',
            dbRaidType: 'RAID1',
            dbRaidDiskSize: '600GB',
            maxCameras: 300,
            sqlEdition: 'SQL Express or higher'
        },
        [ManagementServerConfig.MsConfig2]: {
            serverQuantity: 1,
            serverProcessorType: 'Intel XEON 4410y',
            serverOsApplication: '2 x 300GB Raid 1',
            serverDiskType: 'SSD',
            serverRam: '32GB RAM',
            dbQuantity: 4,
            dbDiskType: 'SSD',
            dbDiskSize: '300GB',
            dbRaidType: 'RAID10',
            dbRaidDiskSize: '1.2TB',
            maxCameras: 1200,
            sqlEdition: 'SQL Standard or higher'

        },
        [ManagementServerConfig.MsConfig3]: {
            serverQuantity: 1,
            serverProcessorType: 'Intel XEON 4416+',
            serverOsApplication: '2 x 300GB Raid 1',
            serverDiskType: 'SSD',
            serverRam: '32GB RAM',
            dbQuantity: 8,
            dbDiskType: 'SSD',
            dbDiskSize: '300GB',
            dbRaidType: 'RAID10',
            dbRaidDiskSize: '2.4TB',
            maxCameras: 2500,
            sqlEdition: 'SQL Standard or higher'
        },
        [ManagementServerConfig.MsConfig4]: {
            serverQuantity: 1,
            serverProcessorType: 'Dual Intel XEON 4416+',
            serverOsApplication: '2 x 300GB Raid 1',
            serverDiskType: 'SSD',
            serverRam: '64GB RAM',
            dbQuantity: 12,
            dbDiskType: 'SSD',
            dbDiskSize: '300GB',
            dbRaidType: 'RAID10',
            dbRaidDiskSize: '3.6TB',
            maxCameras: 5000,
            sqlEdition: 'SQL Standard or higher',
            sqlEditionAdditionalInfo: 'Please contact presales for an optimized design.'
        }
    }

    const getManagmentConfig = (numberOfDevices: number): ManagementServerConfig => {
        if (numberOfDevices === ManagementServerConfig.Auto) {
            const numberOfCameras = cameraRecordings?.reduce((sum, recording) => sum + recording.quantity, 0) ?? 0
            if (numberOfCameras < 301) {
                return ManagementServerConfig.MsConfig1
            } else if (numberOfCameras < 1201) {
                return ManagementServerConfig.MsConfig2
            } else if (numberOfCameras < 2501) {
                return ManagementServerConfig.MsConfig3
            }
            return ManagementServerConfig.MsConfig4
        }

        return numberOfDevices
    }

    if (optionsSetup) {
        managmentProposal = managementProposals[getManagmentConfig(optionsSetup.managementServerNumberOfDevices)]
    }

    return (
        <>
            {optionsSetup?.managementServerOnBoM && (
                <div className="px-10 print-page-break">
                    <div className="msds-text-header-3 msds-text-clear-blue py-4 mt-2">Management Server</div>
                    <div className='row xsd-proposals-pdf'>
                        <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
                            <div className="xsd-proposals-pdf__server-setting-col">
                                <div className="server-settings__header msds-text-body-2-bold mt-2">Management Server Specification</div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                                    <div>Quantity of servers:</div>
                                    <div>{managmentProposal.serverQuantity}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Processor Type:</div>
                                    <div>{managmentProposal.serverProcessorType}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>O/S Application: </div>
                                    <div>{managmentProposal.serverOsApplication}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Disk Type: </div>
                                    <div>{managmentProposal.serverDiskType}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>RAM: </div>
                                    <div>{managmentProposal.serverRam}</div>
                                </div>
                                <div className="msds-text-body-2-bold xsd-proposals-pdf__card-info">
                                    <div>Max Supported Cameras: </div>
                                    <div>{managmentProposal.maxCameras}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>OS Version please <a href="#osVersion">see information below</a></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
                            <div className="xsd-proposals-pdf__server-setting-col">
                                <div className="server-settings__header msds-text-body-2-bold mt-2">Database Storage Information</div>

                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                                    <div>Quantity:</div>
                                    <div>{managmentProposal.dbQuantity}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Disk Type: </div>
                                    <div>{managmentProposal.dbDiskType}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Disk Size: </div>
                                    <div>{managmentProposal.dbDiskSize}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Raid Type: </div>
                                    <div>{managmentProposal.dbRaidType}</div>
                                </div>
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>SQL Edition: </div>
                                    <div>{managmentProposal.sqlEdition}</div>
                                </div>
                                {managementProposals[ManagementServerConfig.MsConfig4]?.sqlEditionAdditionalInfo && (
                                    <div className="msds-text-body-2 xsd-proposals-pdf__card-info">{managementProposals[ManagementServerConfig.MsConfig4].sqlEditionAdditionalInfo}</div>
                                )}
                                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                                    <div>Supported SQL version and configuration please <a href="#sqlInformation">see information below</a></div>
                                </div>
                            </div>
                        </div>
                        <div className='xsd-proposals-pdf__divider mt-4'></div>
                    </div>
                </div>
            )}
        </>
    )
}

type ProposalManagementPdfProps = {
    workspace: WorkspaceDetails
}

export default ProposalManagementPdf