import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg"
import { useNavigate } from "react-router-dom"
import "./row-action.scss"

export const NavigateAction = ({ url }) => {
    const navigate = useNavigate()

    return (
        <button className="row-action" type="button" onClick={() => navigate(url)} title="Edit">
            <svg className="msds-icon">
                <use href={`${spritemap}#edit`} />
            </svg>
        </button>
    )
}