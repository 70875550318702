import { useServerSettingsData } from './useServerSettingsData'
import { useApiData } from 'reducers/hooks'
import { ManagementServerConfig } from 'lib/enums'
import { getCameraRecording } from 'reducers/api/cameraRecording/cameraRecordingActions';

const ServerSettingsPdf = ({ workspaceId }: ServerSettingsProps) => {
  const { optionsSetup, isOptionsSetupLoaded, supportedCamerasSelectedOption } = useServerSettingsData(workspaceId)

  const cameraRecordings = useApiData(
    getCameraRecording(workspaceId),
    state => state.cameraRecording.data[workspaceId],
    state => state.cameraRecording.statuses[workspaceId]
  )

  const supportedCameras = [
    { label: "Auto", value: ManagementServerConfig.Auto.toString() },
    { label: "1 - 300", value: ManagementServerConfig.MsConfig1.toString() },
    { label: "301 - 1200", value: ManagementServerConfig.MsConfig2.toString() },
    { label: "1201 - 2500", value: ManagementServerConfig.MsConfig3.toString() },
    { label: "2501 - 5000", value: ManagementServerConfig.MsConfig4.toString() }
  ]

  const getSupportedCamerasRange = () => {
    const numberOfCameras = cameraRecordings?.reduce((sum, recording) => sum + recording.quantity, 0)
    if (numberOfCameras) {
      if (numberOfCameras < 301) {
        return supportedCameras[1].label
      } else if (numberOfCameras < 1201) {
        return supportedCameras[2].label
      } else if (numberOfCameras < 2501) {
        return supportedCameras[3].label
      } else {
        return supportedCameras[4].label
      }
    }
  }


  let supportedCamerasforRender = (supportedCamerasSelectedOption === supportedCameras[0].label)
    ? supportedCamerasSelectedOption
    : getSupportedCamerasRange();

  return (
    <div className="px-10 print-page-break">
      <div className="msds-text-header-2 py-1 mt-5">User Specified Request</div>
      <div className="msds-text-header-3 msds-text-clear-blue py-2 mt-1">Recording Server Settings</div>
      {
        isOptionsSetupLoaded && (
          <div className='row xsd-proposals-pdf'>
            <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
              <div className="xsd-proposals-pdf__server-setting-col">
                <div className="server-settings__header msds-text-body-2-bold mt-2">Main Settings</div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                  <div> System overhead (%): </div>
                  <div> {optionsSetup?.systemOverheadPercentage}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Max cameras per server*:</div>
                  <div> {optionsSetup?.maxCameras}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Concurrent Smart Client Streams: </div>
                  <div>{optionsSetup?.viewedStreams}</div>
                </div>

                <div className="server-settings__header msds-text-body-2-bold mt-2">Advanced Settings</div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                  <div>Machine Type:</div>
                  <div> {optionsSetup?.machineType?.name}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Server Disk Quantity:</div>
                  <div>{optionsSetup?.maxDisksPerServer ? optionsSetup?.maxDisksPerServer : 'Auto select'}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Raid OS: </div>
                  <div>{optionsSetup?.raiD1ForOS ? 'Yes' : 'No'}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Archiving: </div>
                  <div> {optionsSetup?.archiving ? 'Yes' : 'No'}</div>
                </div>

                {supportedCamerasSelectedOption &&
                  <>
                    <div className="server-settings__header msds-text-body-2-bold mt-2">Managment Server Settings</div>
                    <div className="msds-text-body-2 xsd-proposals-pdf__card-info my-4">
                      <div>Supported Cameras:</div>
                      <div> {supportedCamerasforRender}</div>
                    </div>
                  </>
                }

                <div className="msds-text-body-2 xsd-proposals-pdf__card-info my-4">
                  <div>*Additional servers might be required, due to maximum amount of cameras exceeded.</div>
                </div>
              </div>
            </div>

            <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
              <div className="xsd-proposals-pdf__server-setting-col">
                <div className="server-settings__header msds-text-body-2-bold mt-2">Live Database</div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                  <div>Storage Connectivity:</div>
                  <div>{optionsSetup?.liveStorageSetup?.connectionType.name}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Raid Array:</div>
                  <div>{optionsSetup?.liveStorageSetup?.raidType.name}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Disk Type:</div>
                  <div>{optionsSetup?.liveStorageSetup?.deviceType.displayName}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Disk Size Min (TB):</div>
                  <div>{optionsSetup?.liveStorageSetup?.deviceMinSize.bytes / 1000000000000}</div>
                </div>
                <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                  <div>Disk Size Max (TB):</div>
                  <div>{optionsSetup?.liveStorageSetup?.deviceMaxSize.bytes / 1000000000000}</div>
                </div>
              </div>
            </div>

            {optionsSetup?.archiving && (
              <div className="col-4 xsd-proposals-pdf__server-setting-col-wrapper">
                <div className="xsd-proposals-pdf__server-setting-col">
                  <div className="server-settings__header msds-text-body-2-bold mt-2">Archiving</div>
                  <div className="msds-text-body-2 xsd-proposals-pdf__card-info mt-2">
                    <div>Archive Frequency (times per day):</div>
                    <div>{optionsSetup?.archivesPerDay}</div>
                  </div>
                  <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                    <div>Storage Connectivity:</div>
                    <div>{optionsSetup?.archiveStorageSetup?.connectionType?.name}</div>
                  </div>
                  <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                    <div>Raid Array:</div>
                    <div>{optionsSetup?.archiveStorageSetup?.raidType?.name}</div>
                  </div>
                  <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                    <div>Disk Type:</div>
                    <div>{optionsSetup?.archiveStorageSetup?.deviceType.displayName}</div>
                  </div>
                  <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                    <div>Disk Size Min (TB):</div>
                    <div>{optionsSetup?.archiveStorageSetup?.deviceMinSize.bytes / 1000000000000}</div>
                  </div>
                  <div className="msds-text-body-2 xsd-proposals-pdf__card-info">
                    <div>Disk Size Max (TB):</div>
                    <div>{optionsSetup?.archiveStorageSetup?.deviceMaxSize.bytes / 1000000000000}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }
      <div className='xsd-proposals-pdf__divider mt-4'></div>
    </div >
  )
}

export default ServerSettingsPdf

type ServerSettingsProps = {
  workspaceId: number
}
