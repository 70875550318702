import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { toShortDate } from "lib/helpers";
import { SiteType } from "lib/types";
import { RootState } from "reducers/store";
import { getSitesThunk as getSites, postSiteThunk as postSite, updateSiteThunk as updateSite, deleteSiteThunk as deleteSite, duplicateSite } from "./action";

export type SitesState = {
    data: { [id: number]: SiteType[] },
    sitesStatus: { [id: number]: Status },
    backendErrors: {
        siteStatusErrorMsg: string;
    };
    upsertStatus: Status
};

const initialState: SitesState = {
    data: {},
    sitesStatus: {},
    backendErrors: {
        siteStatusErrorMsg: "string",
    },
    upsertStatus: Status.Idle
};

const getSitesSlice = createSlice({
    name: "sites",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSites.pending, (state, action) => {
                state.sitesStatus[action.meta.arg] = Status.Pending;
            })
            .addCase(getSites.rejected, (state, action) => {
                state.sitesStatus[action.meta.arg] = Status.Rejected;
                state.backendErrors.siteStatusErrorMsg = action.payload;
            })
            .addCase(getSites.fulfilled, (state, action) => {
                state.sitesStatus[action.meta.arg] = Status.Success;
                state.data[action.meta.arg] = updateDataDate(action.payload.sort((a, b) => parseInt(b.id) - parseInt(a.id)));
                state.backendErrors.siteStatusErrorMsg = "";
            })
            .addCase(postSite.rejected, (state, action) => {
                state.backendErrors.siteStatusErrorMsg = action.payload;
            })
            .addCase(postSite.pending, (state, action) => {
                state.upsertStatus = Status.Pending
            })
            .addCase(postSite.fulfilled, (state, action) => {
                state.data[action.meta.arg.projectId] = updateDataDate([...state.data[action.meta.arg.projectId], action.payload].sort((a, b) => parseInt(b.id) - parseInt(a.id)));
                state.backendErrors.siteStatusErrorMsg = "";
                state.upsertStatus = Status.Success
            })
            .addCase(updateSite.rejected, (state, action) => {
                state.backendErrors.siteStatusErrorMsg = action.payload;
            })
            .addCase(updateSite.pending, (state, action) => {
                state.upsertStatus = Status.Pending
            })
            .addCase(updateSite.fulfilled, (state, action) => {
                const newStateData = { ...state.data }
                const siteIndex = newStateData[action.meta.arg.project?.id].findIndex(site => site.id === action.payload.id)
                newStateData[action.meta.arg.project?.id][siteIndex] = action.payload;
                state.data[action.meta.arg.project?.id] = updateDataDate(newStateData[action.meta.arg.project?.id].sort((a, b) => parseInt(b.id) - parseInt(a.id)));
                state.backendErrors.siteStatusErrorMsg = "";
                state.upsertStatus = Status.Success
            }).addCase(deleteSite.rejected, (state, action) => {
                state.backendErrors.siteStatusErrorMsg = action.payload;
            })
            .addCase(deleteSite.fulfilled, (state, action: PayloadAction< {error?: string}, string, { arg: {projectId: string, siteId: string} }>) => {
                state.data[action.meta.arg.projectId] = state.data[action.meta.arg.projectId].filter((site: SiteType) => site.id !== action.meta.arg.siteId)
                state.backendErrors.siteStatusErrorMsg = "";
            })
            .addCase(duplicateSite.rejected, (state, _) => {
                state.upsertStatus = Status.Rejected
            })
            .addCase(duplicateSite.pending, (state, _) => {
                state.upsertStatus = Status.Pending
            })
            .addCase(duplicateSite.fulfilled, (state, action) => {
                const newState = [...state.data[action.meta.arg.projectId], action.payload].sort((a, b) => parseInt(b.id) - parseInt(a.id))
                state.data[action.meta.arg.projectId] = updateDataDate(newState);
                state.upsertStatus = Status.Success
            });
    },
});

const updateDataDate = (sites: SiteType[]) => {
    sites.forEach(p => p.formattedUtc = toShortDate(p.createdUtc))
    return sites
}

export const selectSites = (projectId: string) => (state: RootState): SiteType[] => state.sites.data[projectId] ? state.sites.data[projectId] : []
export const selectStatus = (projectId: string) => (state: RootState): Status => state.sites.sitesStatus[projectId] ? state.sites.sitesStatus[projectId] : Status.Idle
export const selectAreSitesLoading = (state: RootState): boolean => Object.values(state.sites.sitesStatus).some(status => status === Status.Pending) || state.sites.upsertStatus === Status.Pending

export default getSitesSlice.reducer;