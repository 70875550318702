import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Status } from "lib/enums"
import { Calculation } from "lib/types"
import { RootState } from "reducers/store"
import { getCalculation, GetCalculationPayload, getWorkspaces, GetWorkspacesPayload } from "./workspaceActions"
import { serverClassReplacements, desktopClassReplacements, cpuCountReplacement } from "reducers/clientSidePatch"

export type WorkspaceDetails = {
    workspaceId: number,
    siteDesignName: string,
    siteId: string,
    projectId: string,
    updatedUtc: string,
    projectName: string,
    siteName: string
}

export type WorkspaceState = {
    proposals: { [id: number]: Calculation },
    proposalStatuses: { [id: number]: Status },
    workspaces: { [id: number]: WorkspaceDetails },
    workspacesStatus: Status,
    postStatus: Status
}

const initialState: WorkspaceState = {
    proposals: {},
    proposalStatuses: {},
    workspaces: {},
    workspacesStatus: Status.Idle,
    postStatus: Status.Idle
}

const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCalculation.pending, (state, action: PayloadAction<GetCalculationPayload, string, { arg: { workspaceId: number } }>) => {
                state.proposalStatuses[action.meta.arg.workspaceId] = Status.Pending;
            })
            .addCase(getCalculation.fulfilled, (state, action: PayloadAction<GetCalculationPayload, string, { arg: { workspaceId: number } }>) => {
                state.proposalStatuses[action.meta.arg.workspaceId] = Status.Success;
                // state.proposals[action.meta.arg.workspaceId] = action.payload.data

                /** Start patch work for CPU names **/
                const cpuName = action.payload.data.preferredSolution.servers[0].cpuName;
                const cpuCount = action.payload.data.preferredSolution.servers[0].cpuCount;

                let updatedCpuName = cpuName;
                if (cpuName === "Xeon Bronze 3204 @ 1.90GHz") {
                    updatedCpuName = serverClassReplacements.Bronze3408u
                } else if (cpuName === "Xeon Silver 4208 @ 2.10GHz" || cpuName === "Xeon Silver 4210 @ 2.20GHz") {
                    updatedCpuName = serverClassReplacements.Silver4410y
                } else if (cpuName === "i3-8100 @ 3.60GHz" || "Celeron G4900 @ 3.10GHz") {
                    updatedCpuName = desktopClassReplacements.Corei3
                } else if (cpuName === "Pentium G5400 @ 3.70GHz") {
                    updatedCpuName = desktopClassReplacements.Corei5
                }

                if (cpuCount === 2) {
                    updatedCpuName = cpuCountReplacement.dual + updatedCpuName;
                }

                state.proposals[action.meta.arg.workspaceId] = {
                    ...action.payload.data,
                    preferredSolution: {
                        ...action.payload.data.preferredSolution,
                        servers: [
                            {
                                ...action.payload.data.preferredSolution.servers[0],
                                cpuName: updatedCpuName
                            }
                        ]
                    }
                };
                /** End patch work for CPU names **/

            })
            .addCase(getCalculation.rejected, (state, action: PayloadAction<GetCalculationPayload, string, { arg: { workspaceId: number } }>) => {
                state.proposalStatuses[action.meta.arg.workspaceId] = Status.Rejected;
                console.log(action.payload.error)
            })
            .addCase(getWorkspaces.pending, (state, action: PayloadAction<GetWorkspacesPayload>) => {
                state.workspacesStatus = Status.Pending;
            })
            .addCase(getWorkspaces.fulfilled, (state, action: PayloadAction<GetWorkspacesPayload>) => {
                state.workspacesStatus = Status.Success;
                state.workspaces = action.payload.data.reduce((map, workspace) => {
                    map[workspace.siteDesignVersion.siteDesign.id] = {
                        workspaceId: workspace.id,
                        siteDesignName: workspace.siteDesignVersion.siteDesign.name,
                        siteId: workspace.siteDesignVersion.siteDesign.site.id,
                        projectId: workspace.siteDesignVersion.siteDesign.site.project.id,
                        updatedUtc: workspace.siteDesignVersion.siteDesign.updatedUtc ?? workspace.siteDesignVersion.siteDesign.createdUtc,
                        projectName: workspace.siteDesignVersion.siteDesign.site.project.name,
                        siteName: workspace.siteDesignVersion.siteDesign.site.name
                    }
                    return map
                }, {} as { [id: number]: WorkspaceDetails })
            })
            .addCase(getWorkspaces.rejected, (state, action: PayloadAction<GetWorkspacesPayload>) => {
                state.workspacesStatus = Status.Rejected;
                console.log(action.payload.error)
            })
    },
})

export const selectAreWorkspacesLoading = (state: RootState): boolean => Object.values(state.workspace.workspacesStatus).some(status => status === Status.Pending)
    || state.workspace.postStatus === Status.Pending
    || state.workspace.workspacesStatus === Status.Pending

export default workspaceSlice.reducer
