import { useApiData, useApiDomainData, useAppSelector } from 'reducers/hooks'
import { OriginalRow, actionCell, doubleSelectCell, doubleTextCell, pdfCell, singleCell, singleSelectCell, singleTextCell } from './CameraGroupTableCells'
import { useMemo } from 'react'
import { enumToOptionList } from 'lib/helpers'
import { getCameraRecording } from 'reducers/api/cameraRecording/cameraRecordingActions'
import { DomainName, LensType, RecordingType } from 'lib/enums'
import { CameraRecordingComplexCell, CameraRecordingDataRow } from 'lib/types'
import Input from 'components/FormComponents/Input/Input'
import Select from 'components/FormComponents/Select/Select'

export const columnAcessors = {
  quantity: 'quantity',
  lensTypes: 'lensTypes',
  recordingType: 'recording',
  hoursPerDay: 'hoursPerDay',
  retentionDays: 'retentionDays',
  codec: 'codec',
  fps: 'fps',
  motion: 'motion',
  complexity: 'complexity',
  resolution: 'resolution',
  bitrateKbit: 'bitrateKbit'
}

const { quantity, lensTypes, recordingType, hoursPerDay, retentionDays, codec, fps, motion, complexity, resolution, bitrateKbit } = columnAcessors

type CameraGroupData = {
  cameraRecordings: CameraRecordingDataRow[]
  cameraConfigId: number
  pageTableColumns: any[]
  pdfTableColumns: {
    header: string
    cell: (cameraRow: CameraRecordingDataRow, cellValue: CameraRecordingComplexCell<string> | string) => JSX.Element | JSX.Element[],
    accessor: string
  }[]
  bulkApplyElements: any
  bulkApplyOptions: { value: string; label: string }[]
}

export const useCameraGroupData = (workspaceId: number): CameraGroupData => {
  const cameraRecordings = useApiData(
    getCameraRecording(workspaceId),
    state => state.cameraRecording.data[workspaceId],
    state => state.cameraRecording.statuses[workspaceId]
  )
  const cameraConfigId = useAppSelector(state => state.cameraRecording.workspaceToConfigMap[workspaceId])
  const codecs = useApiDomainData(DomainName.Codec)
  const imageComplexity = useApiDomainData(DomainName.ImageComplexity)
  const imageResolution = useApiDomainData(DomainName.ImageResultion)
  const lensTypesOptions = enumToOptionList(LensType)
  const recordings = enumToOptionList(RecordingType)

  const isVisibleBaseOnMultiLensChild = (row: OriginalRow) => row.original.lensTypes !== LensType.MultiLensChild

  const tableColumns = useMemo(
    () => [
      { Header: 'actions', Cell: actionCell(isVisibleBaseOnMultiLensChild), PdfCell: singleCell(isVisibleBaseOnMultiLensChild) },
      {
        Header: 'Cameras',
        accessor: quantity,
        Cell: singleTextCell(isVisibleBaseOnMultiLensChild),
        pdfCell: pdfCell(isVisibleBaseOnMultiLensChild),
        bulkApplyElement: (updateState, initialValue) => <Input handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Lens types',
        accessor: lensTypes,
        Cell: singleSelectCell(isVisibleBaseOnMultiLensChild, lensTypesOptions),
        pdfCell: pdfCell(isVisibleBaseOnMultiLensChild),
        bulkApplyElement: (updateState, initialValue) => <Select options={lensTypesOptions} handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Recording',
        accessor: recordingType,
        Cell: singleSelectCell(() => true, recordings),
        pdfCell: pdfCell(() => true),
        bulkApplyElement: (updateState, initialValue) => <Select options={recordings} handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Operation (Hours)',
        accessor: hoursPerDay,
        Cell: singleTextCell(() => true),
        pdfCell: pdfCell(() => true),
        bulkApplyElement: (updateState, initialValue) => <Input handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Days',
        accessor: retentionDays,
        Cell: singleTextCell(() => true),
        pdfCell: pdfCell(() => true),
        bulkApplyElement: (updateState, initialValue) => <Input handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Codec',
        accessor: codec,
        Cell: singleSelectCell(() => true, codecs),
        pdfCell: pdfCell(() => true, codecs),
        bulkApplyElement: (updateState, initialValue) => <Select options={codecs} handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'FPS',
        accessor: fps,
        Cell: doubleTextCell(fps, ['', 'Baseline', 'Speedup']),
        pdfCell: pdfCell(() => true),
        bulkApplyElement: (updateState, initialValue) => <Input handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Motion/ Event(%)',
        accessor: motion,
        Cell: doubleTextCell(motion, null, [(row: OriginalRow) => row.original.recording === RecordingType.Constant, () => true, () => false]),
        pdfCell: pdfCell(() => true),
        bulkApplyElement: (updateState, initialValue) => <Input handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Image Complexity',
        accessor: complexity,
        Cell: doubleSelectCell(complexity, imageComplexity),
        pdfCell: pdfCell(() => true, imageComplexity),
        bulkApplyElement: (updateState, initialValue) => <Select options={imageComplexity} handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Resolution',
        accessor: resolution,
        Cell: doubleSelectCell(resolution, imageResolution),
        pdfCell: pdfCell(() => true, imageResolution),
        bulkApplyElement: (updateState, initialValue) => <Select options={imageResolution} handleDataChange={updateState} initialValue={initialValue} />
      },
      {
        Header: 'Data Rate (Kbit/Sec)',
        accessor: bitrateKbit,
        Cell: doubleTextCell(bitrateKbit),
        pdfCell: pdfCell(() => true),
        bulkApplyElement: (updateState, initialValue) => <Input handleDataChange={updateState} initialValue={initialValue} />
      }
    ],
    [codecs, lensTypesOptions, recordings, imageComplexity, imageResolution]
  )

  const pageTableColumns = tableColumns.map(column => ({ Header: column.Header, accessor: column.accessor, Cell: column.Cell }))

  const pdfTableColumns = tableColumns.filter(column => column.accessor).map(column => ({ header: column.Header, cell: column.pdfCell, accessor: column.accessor }))

  const bulkApplyElements = tableColumns
    .filter(column => column.bulkApplyElement && column.accessor)
    .reduce((elementsMap, column) => {
      elementsMap[column.accessor] = column.bulkApplyElement
      return elementsMap
    }, {})
  const bulkApplyOptions = tableColumns.filter(column => column.bulkApplyElement && column.accessor).map(column => ({ label: column.Header, value: column.accessor }))

  return {
    cameraRecordings,
    cameraConfigId,
    pageTableColumns,
    pdfTableColumns,
    bulkApplyElements,
    bulkApplyOptions
  }
}
