import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg"
import "./row-action.scss"

export const DuplicateRowAction = ({    
    rowIndex,
    duplicateClickHandler
}) => {
    return (
        <button className="row-action" type="button" onClick={() => duplicateClickHandler(rowIndex)} title="Duplicate">
            <svg className="msds-icon">
                <use href={`${spritemap}#copy`} />
            </svg>
        </button>
    )
}