import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import "./BackButton.scss";
import { ButtonProps } from "lib/types";

const BackButton = ({ id, label, href, onClickHandler, cssClass }: ButtonProps) => {
    return (
        <a onClick={onClickHandler} id={id} className={`back-button msds-text-body-1 ${cssClass ? cssClass : ''}`} href={href}>
            <svg>
                <use href={spritemap + "#right-arrow"} />
            </svg>
            {label}
        </a>

    )
}


export default BackButton;
