import { AddRowAction } from 'components/Table/AddRowAction'
import { DeleteRowAction } from 'components/Table/DeleteRowAction'
import { DuplicateRowAction } from 'components/Table/DuplicateRowAction'
import EditableSelectCell from 'components/Table/EditableSelectCell'
import { EditableTextCell } from 'components/Table/EditableTextCell'
import { RecordingType } from 'lib/enums'
import { CameraRecordingComplexCell, CameraRecordingDataRow } from 'lib/types'

export type OriginalRow = {
  original?: CameraRecordingDataRow
  index: number
}

type CellProps = {
  row: OriginalRow
  value?: any
  column: { id: number }
  updateMyData?: (index: number, id: number, value: string) => void
  deleteClickHandler?: () => void
  addClickHandler?: () => void
  duplicateClickHandler?: () => void
}

const inputDataCreator = (changedValue: string, isConstant: boolean, originalData: any, isBaseline?: boolean): CameraRecordingComplexCell<number | string> => {
  return isConstant || isBaseline ? { continuous: changedValue, motion: originalData.motion } : { continuous: originalData.continuous, motion: changedValue }
}

const optionValueToLabel = (value: string, options?: { value: string; label: string }[]) => (options == null ? value : options.find(option => option.value === value)?.label)

export const singleTextCell = (isVisible: (row: OriginalRow) => boolean, customValue?: any, label?: string, isDisabled?: boolean, inputCreator?: (value: string) => any): typeof EditableTextCell => {
  return ({ value, row, column, updateMyData }: CellProps) =>
    isVisible(row) && (
      <EditableTextCell
        value={customValue !== undefined ? customValue : value}
        row={row}
        column={column}
        updateMyData={updateMyData}
        label={label}
        isDisabled={isDisabled}
        inputCreator={inputCreator}
      />
    )
}

export const singleSelectCell = (
  isVisible: (row: OriginalRow) => boolean,
  options: { label: string; value: string }[],
  customValue?: any,
  isDisabled?: boolean,
  inputCreator?: (value: string) => any
): typeof EditableSelectCell => {
  return ({ value, row, column, updateMyData }: CellProps) =>
    isVisible(row) && (
      <EditableSelectCell
        value={customValue !== undefined ? customValue : value}
        row={row}
        column={column}
        updateMyData={updateMyData}
        options={options}
        isDisabled={isDisabled}
        inputCreator={inputCreator}
      />
    )
}

export const doubleTextCell = (valueFieldName: string, labels?: string[], isDisabled?: ((row: OriginalRow) => boolean)[]): typeof EditableTextCell => {
  return ({ value, row, column, updateMyData }: CellProps) => (
    <>
      {singleTextCell(
        () => row.original.recording !== RecordingType.Speedup,
        row.original.recording === RecordingType.Constant ? value.continuous : value.motion,
        labels ? labels[0] : '',
        isDisabled ? isDisabled[0](row) : false,
        value => inputDataCreator(value, row.original.recording === RecordingType.Constant, row.original[valueFieldName])
      )({ value, row, column, updateMyData })}
      {singleTextCell(
        () => row.original.recording === RecordingType.Speedup,
        value.continuous,
        labels ? labels[1] : '',
        isDisabled ? isDisabled[1](row) : false,
        value => inputDataCreator(value, true, row.original[valueFieldName])
      )({ value, row, column, updateMyData })}
      {singleTextCell(
        () => row.original.recording === RecordingType.Speedup,
        value.motion,
        labels ? labels[2] : '',
        isDisabled ? isDisabled[2](row) : false,
        value => inputDataCreator(value, false, row.original[valueFieldName])
      )({ value, row, column, updateMyData })}
    </>
  )
}

export const doubleSelectCell = (valueFieldName: string, options: { label: string; value: string }[]): typeof EditableTextCell => {
  return ({ value, row, column, updateMyData }: CellProps) => (
    <>
      {singleSelectCell(
        () => row.original.recording !== RecordingType.Speedup,
        options,
        row.original.recording === RecordingType.Constant ? value.continuous : value.motion,
        false,
        value => inputDataCreator(value, row.original.recording === RecordingType.Constant, row.original[valueFieldName])
      )({ value, row, column, updateMyData, options })}
      {singleSelectCell(
        () => row.original.recording === RecordingType.Speedup,
        options,
        value.continuous,
        false,
        value => ({ continuous: value, motion: value })
      )({ value, row, column, updateMyData, options })}
      {singleSelectCell(
        () => row.original.recording === RecordingType.Speedup,
        options,
        value.motion,
        true,
        value => ({ continuous: value, motion: value })
      )({ value, row, column, updateMyData, options })}
    </>
  )
}

export const singleCell = (
  isVisible: (row: OriginalRow) => boolean,
  valueTransformation?: (value: string) => string,
  customValue?: string,
  label?: string
): (({ value, row }: CellProps) => JSX.Element) => {
  return ({ value, row }: CellProps) =>
    isVisible(row) && (
      <>
        {label ? `${label}: ` : ''}
        {valueTransformation ? valueTransformation(customValue !== undefined ? customValue : value) : customValue !== undefined ? customValue : value}
      </>
    )
}

export const doubleCell = (valueTransformation?: (value: string) => string, labels?: string[]): (({ value, row }: CellProps) => JSX.Element) => {
  return ({ value, row, column }: CellProps) => (
    <>
      {singleCell(
        () => row.original.recording !== RecordingType.Speedup,
        valueTransformation,
        row.original.recording === RecordingType.Constant ? value.continuous : value.motion
      )({ value, row, column })}
      <div>{singleCell(() => row.original.recording === RecordingType.Speedup, valueTransformation, value.continuous, labels ? labels[0] : '')({ value, row, column })}</div>
      <div>{singleCell(() => row.original.recording === RecordingType.Speedup, valueTransformation, value.motion, labels ? labels[1] : '')({ value, row, column })}</div>
    </>
  )
}

export const actionCell =
  (isVisible: (row: OriginalRow) => boolean) =>
    ({ row, deleteClickHandler, addClickHandler, duplicateClickHandler }: CellProps) =>
      isVisible(row) && (
        <div className="row-action-container">
          <DeleteRowAction rowIndex={row.index} deleteClickHandler={deleteClickHandler} />
          <DuplicateRowAction rowIndex={row.index} duplicateClickHandler={duplicateClickHandler} />
          <AddRowAction rowIndex={row.index} addClickHandler={addClickHandler} />
        </div>
      )

export const pdfCell =
  (isVisible: (row: OriginalRow) => boolean, options?: { value: string; label: string }[]) =>
    (cameraRow: CameraRecordingDataRow, cellValue: CameraRecordingComplexCell<string> | string) => {
      if (isVisible({ original: cameraRow, index: 0 }))
        if (typeof cellValue === 'object' && cameraRow.recording === RecordingType.Speedup) {
          return (
            <>
              <div>{optionValueToLabel(cellValue.continuous, options)}</div>
              <div>{optionValueToLabel(cellValue.motion, options)}</div>
            </>
          )
        } 
        else if (typeof cellValue === 'object' && cameraRow.recording === RecordingType.Constant){
          return <div>{optionValueToLabel(cellValue.continuous, options)}</div>
        }
        else if (typeof cellValue === 'object') {
          return <div>{cameraRow.recording === RecordingType.Speedup || cameraRow.fps ? optionValueToLabel(cellValue.motion, options) : optionValueToLabel(cellValue.continuous, options)} </div>
        }
        else {
          return <div>{optionValueToLabel(cellValue, options)} </div>
        }
    }