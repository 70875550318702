import '@milestone-sys/web-design-system/main.css'
import './footer.scss'
import { ReactComponent as MakeTheWorldSee } from './make-the-world-see.svg'

function NavigationFooter() {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer container-fluid">
      <div className="row">
        <div className="footer__body col-12"> 
          <MakeTheWorldSee className="footer__body-make-the-worl-see" />
          <div className="footer__content-wrapper">
            <div className="footer__content">Copyright © {currentYear} Milestone systems A/S. All rights reserved.</div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default NavigationFooter
