import { useCameraGroupData } from './useCameraGroupData'

const CamerasGroupTablePdf = ({ workspaceId }: CameraGroupTableProps) => {
  const { cameraRecordings, pdfTableColumns } = useCameraGroupData(workspaceId)

  return (
    <div className="px-10">
      <div className="msds-text-header-3 msds-text-clear-blue py-4 mt-5">Calculation camera information</div>
      {cameraRecordings && pdfTableColumns && (
        <>
          <div className="xsd-proposals-pdf__calculation-header pb-4 d-flex">
            {pdfTableColumns.map(column => (
              <div className="xsd-proposals-pdf__calculation-header-cell msds-text-body-5-bold text-uppercase" key={column.header}>
                {column.header}
              </div>
            ))}
          </div>
          <div className="xsd-proposals-pdf__divider-bottom"></div>
          {cameraRecordings.map(cameraRow => (
            <div className={"xsd-proposals-pdf__row d-flex pt-4" +
              ((cameraRow.lensTypes.includes("Multi Lens") &&
                !cameraRow.lensTypes.includes(":hidden")) ? " xsd-proposals-pdf__row--multilines" : " pb-4")}
              key={cameraRow.id}>
              {pdfTableColumns.map(column => (
                <div className="xsd-proposals-pdf__calculation-body-cell msds-text-body-5" key={cameraRow.id + column.accessor}>
                  {column.cell(cameraRow, cameraRow[column.accessor])}
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

type CameraGroupTableProps = {
  workspaceId: number
}

export default CamerasGroupTablePdf
