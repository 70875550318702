import { useEffect, useState } from "react";

const Input = ({
  id,
  type,
  placeholder,
  label,
  initialValue,
  isDisabled,
  onEventType = "onBlur",
  handleDataChange,
  isSmall,
  className,
  maxValue
}: InputProps) => {
  const [value, setValue] = useState(initialValue ?? "");


  useEffect(() => {
    if (initialValue != null) setValue(initialValue);
  }, [initialValue]);

  const handleInputChange = (eventType: string) => (event) => {
    const newValue = maxValue ? Math.min(event.target.value, maxValue) : event.target.value

    if (onEventType === eventType) {
        handleDataChange(newValue)
    }
    setValue(newValue);
  }

  return (
    <div className={`msds-input ${className ? className : ''} ${isSmall ? 'msds-input--small' : ''}`}
    >
      <div className="msds-input__icon-wrapper">
        <input
          id={id}
          // Avoid autocomplete of forms if line below is active
          autoComplete="no"
          type={type ?? "text"}
          className="msds-input__text-input"
          placeholder={placeholder}
          value={value}
          disabled={isDisabled}
          onChange={handleInputChange("onChange")}
          onBlur={handleInputChange("onBlur")}
        />
        <label htmlFor="text-input-lg" className="msds-input__label">
          {label}
        </label>
      </div>
    </div>
  );
};

export default Input;

type InputProps = {
  id?: string;
  type?: "text" | "email" | "password" | "number" | "hidden";
  placeholder?: string;
  label?: string;
  initialValue?: string;
  isDisabled?: boolean;
  initialMessage?: { info?: string; validation: string };
  data?: { [key: string]: any };
  onEventType?: "onChange" | "onBlur";
  handleDataChange?: (newValue: any, isValid?: boolean) => void;
  isSmall?: boolean;
  className?: string
  maxValue?: number
};
