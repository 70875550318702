import { AsyncThunkAction } from "@reduxjs/toolkit";
import { DomainName, Status } from "lib/enums";
import { DomainType } from "lib/types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDomain } from "./api/domain/domainActions";
import type { RootState, AppDispatch } from "./store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: <ReturnType>(selector: (state: RootState) => ReturnType) => ReturnType = useSelector;

export const useApiData = <ReturnType>(action: AsyncThunkAction<any, any, { rejectValue: any }>, dataSelector: (state: RootState) => ReturnType, statusSelector: (state: RootState) => Status) => {
    const dispatch = useAppDispatch()
    const status = useAppSelector(statusSelector)
    const data = useAppSelector(dataSelector)

    useEffect(() => {
        if (status === Status.Idle || status === undefined) {
            dispatch(action)
        }
    }, [status, dispatch, action])

    return data
}

export const useApiDomainData = (domainName: DomainName, customSelector?: (domain: DomainType) => { value: string, label: string }): { value: string, label: string, originalData?: DomainType}[] =>
    useApiData(getDomain(domainName), state => state.domain.domains[domainName], state => state.domain.statuses[domainName])
        .map(customSelector ? customSelector : domain => ({ value: domain.domainValueId.toString(), label: domain.name, originalData: domain }))
