import Input from "components/FormComponents/Input/Input";
import { useState } from "react";

const GlobalFilter = ({ data, handleFilterData }) => {
  const [value, setValue] = useState("");
  const onChangeHandler = (newValue) => {
    setValue(newValue);
    const newData = data.filter((d) => {
      return Object.values(d).some((val) => {
        return (
          val !== null &&
          typeof val === "string" &&
          val.toLowerCase().includes(newValue.toLowerCase())
        );
      });
    });
    handleFilterData(newData);
  };

  return (
    <Input
      id="global-filter__e2e-search-input"
      placeholder="Search..."
      label={"Search"}
      initialValue={value}
      handleDataChange={onChangeHandler}
      onEventType="onChange"
    />
  );
};

export default GlobalFilter;
