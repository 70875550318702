import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {  Status } from "lib/enums"
import { OptionsSetup } from "lib/types"
import { RootState } from "reducers/store"
import { deleteFailoverConfig, FailoverConfigurationPayload, getOptionsSetup, OptionsSetupPayload, postFailoverConfig, putFailoverConfig, putOptionsSetup } from "./optionsSetupActions"

type OptionsSetupState = {
    data: { [id: number]: OptionsSetup},
    statuses: { [id: number]: Status}
}

const initialState: OptionsSetupState = {
    data: {},
    statuses: {}
};

const optionsSetupSlice = createSlice({
    name: "optionsSetup",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getOptionsSetup.pending, (state, action: PayloadAction<OptionsSetupPayload, string, { arg: number }>) => {
                state.statuses[action.meta.arg] = Status.Pending;
            })
            .addCase(getOptionsSetup.fulfilled, (state, action: PayloadAction<OptionsSetupPayload>) => {
                state.statuses[action.payload.workspaceId] = Status.Success;
                state.data[action.payload.workspaceId] = action.payload.data
            })
            .addCase(getOptionsSetup.rejected, (state, action: PayloadAction<OptionsSetupPayload>) => {
                state.statuses[action.payload.workspaceId] = Status.Rejected;
                console.log(action.payload.error)
            })
            .addCase(putOptionsSetup.fulfilled, (state, action: PayloadAction<OptionsSetupPayload>) => {
                state.data[action.payload.workspaceId] = action.payload.data
            })
            .addCase(putOptionsSetup.rejected, (_, action: PayloadAction<OptionsSetupPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(putFailoverConfig.fulfilled, (state, action: PayloadAction<FailoverConfigurationPayload>) => {
                state.data[action.payload.workspaceId].failoverConfiguration = action.payload.data
            })
            .addCase(putFailoverConfig.rejected, (_, action: PayloadAction<FailoverConfigurationPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(postFailoverConfig.fulfilled, (state, action: PayloadAction<FailoverConfigurationPayload>) => {
                state.data[action.payload.workspaceId].failoverConfiguration = action.payload.data
            })
            .addCase(postFailoverConfig.rejected, (_, action: PayloadAction<FailoverConfigurationPayload>) => {
                console.log(action.payload.error)
            })
            .addCase(deleteFailoverConfig.fulfilled, (state, action: PayloadAction<FailoverConfigurationPayload>) => {
                state.data[action.payload.workspaceId].failoverConfiguration = action.payload.data
            })
            .addCase(deleteFailoverConfig.rejected, (_, action: PayloadAction<FailoverConfigurationPayload>) => {
                console.log(action.payload.error)
            })
    },
});

export const selectAreOptionsLoading = (state: RootState): boolean => Object.values(state.optionsSetup.statuses).some(status => status === Status.Pending)

export default optionsSetupSlice.reducer;
