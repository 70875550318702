import { EditableTextCell } from 'components/Table/EditableTextCell'
import { DeleteRowAction } from 'components/Table/DeleteRowAction'
import { NavigateAction } from 'components/Table/NavigateAction'
import { DuplicateRowAction } from 'components/Table/DuplicateRowAction'

export const ProjectsColumns = [
  {
    Header: 'actions',
    Cell: ({ row, deleteClickHandler, duplicateClickHandler }) => (
      <div className="row-action-container">
        <DeleteRowAction rowIndex={row.index} deleteClickHandler={deleteClickHandler} />
        <DuplicateRowAction rowIndex={row.index} duplicateClickHandler={duplicateClickHandler} />
        <NavigateAction url={`/project/${row.original.id}/site`} />
      </div>
    )
  },
  {
    Header: () => 'Project',
    accessor: 'name',
    Cell: EditableTextCell
  },
  {
    Header: () => 'Date Created',
    accessor: 'formattedUtc'
  }
]
