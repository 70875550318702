import { AccordionItem } from "./AccordionItem";

const Accordion = ({ children }: AccordionProps) => {
    return (
        <div className="msds-accordion">
            {children}
        </div>
    )
}

type AccordionProps = {
    children: AccordionItem[] | AccordionItem
}

export default Accordion