import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg"
import "./row-action.scss"

export const AddRowAction = ({    
    rowIndex,    
    addClickHandler, 
}) => {
    return (
        <button className="row-action" type="button" onClick={() => addClickHandler(rowIndex)} title="Add">
            <svg className="msds-icon">
                <use href={`${spritemap}#plus`} />
            </svg>
        </button>
    )
}

