import '@milestone-sys/web-design-system/main.css'
import { AuthenticationContext } from 'components/Authentication/AuthenticationProvider'
import { MILESTONE_URL } from 'config'
import { useAppSelector } from 'reducers/hooks'
import { useContext } from 'react'
import { selectIsAuthenticated } from 'reducers/userSlice'
import './navigation.scss'
import { ReactComponent as MilestoneLogo } from './milestone-logo.svg'
import { ReactComponent as XsdLogo } from './xprotect-solution-designer.svg'
import { Link, NavLink } from 'react-router-dom'

const Navigation = () => {
  const authenticationContext = useContext(AuthenticationContext)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  return (
    <>
      <header className="navigation">
        <div className='navigation__logos'>
          <a rel="noopener noreferrer" href={MILESTONE_URL()}>
            <MilestoneLogo className="navigation__milestone-logo" />
          </a>
          <div className='navigation__logo-divider'></div>
          <XsdLogo className="navigation__xsd-logo" />
        </div>
        <ul className="navigation__list"></ul>
        <button
          type="button"
          className="navigation__login-button msds-btn msds-btn--gray msds-btn--sm"
          onClick={authenticationContext.handleLoginButtonClick}>
          {isAuthenticated ? 'Log out' : 'Log In'}
        </button>
      </header>
      <div className="navigation__header msds-text-body-2-bold">
        <NavLink to="/" className='navigation__header-links'>
          MY PROJECTS
        </NavLink >
        <NavLink to="/release-notes/" className='navigation__header-links'>
          RELEASE NOTES
        </NavLink >
        <NavLink to="/disclaimer/" className='navigation__header-links'>
          DISCLAIMER
        </NavLink >
      </div>
    </>
  )
}

export default Navigation
