import axios, { AxiosResponse } from "axios";
import { API_URL } from "config";
import { ProjectType } from "./types";

//Get - Read all projects
export const getProjects = async (): Promise<AxiosResponse<ProjectType[]>> => {
    const response = await axios.get(`${API_URL()}/project`);
    return response;
};

//Post - Create new project
export const postProject = async (projectData): Promise<ProjectType> => {
    const response = await axios.post(`${API_URL()}/project`,
        projectData
    );
    return response.data;
};

//Update - Update existing project
export const updateProject = async (projectData): Promise<ProjectType> => {
    const response = await axios.put(`${API_URL()}/project/${projectData.id}`,
        projectData
    );
    return response.data;
};