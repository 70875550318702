import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import { UserData } from "lib/types";

const ProposalContactCard = ({ userData }: ProposalContactCardProps) => {
    return (
        <div className="msds-contact-card msds-contact-card--without-image msds-contact-card--vertically-aligned">
            <div className="msds-contact-card__overlay"></div>
            <div className="msds-contact-card__headline">
                Integrator Details
            </div>
            <div className="msds-contact-card__body">
                <div className="msds-contact-card__body-contact-person">
                    {userData.name} {userData.lastName}
                </div>
                <div className="msds-contact-card__body-contact-position">
                    {userData.companyName}
                </div>
                <div className="msds-contact-card__body-contact-details">                    
                    {userData.email &&                     
                        <a className="msds-contact-card__link" href={`mailto: ${userData.email}`}>
                            <svg className="msds-contact-card__icon">
                                <use href={`${spritemap}#email`} />
                            </svg>
                            {userData.email}
                        </a>           
                    }
                    {userData.phone && 
                        <a className="msds-contact-card__link" href={`tel: ${userData.phone}`}>
                            <svg className="msds-contact-card__icon">
                                <use href={`${spritemap}#phone`} />
                            </svg>
                            {userData.phone}
                        </a>
                    }                    
                </div>
            </div>
        </div>
    )
}

type ProposalContactCardProps = {
    userData: UserData
}

export default ProposalContactCard