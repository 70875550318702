import { useContext, useEffect } from 'react'
import { useAppSelector } from 'reducers/hooks'
import { selectIsAuthenticated } from 'reducers/userSlice'
import { AuthenticationContext } from './AuthenticationProvider'
import { RedirectionType } from './types'
import { redirectToIDP } from './authenticationUtilities'

//Provides authorization as requirement to visit a page passed in props as "children" page component.
//Authorization is simple: if you are authenticated you are authorized.
//TODO If required: user role based authorization
export const RequireAuthorization = ({ children }) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const authenticationContextValue = useContext(AuthenticationContext)
  const isAuthorized = isAuthenticated

  useEffect(() => {
    if (isAuthenticated != null && !isAuthorized) {
      redirectToIDP(authenticationContextValue.authConfig, RedirectionType.Signin, true)
    }
  }, [authenticationContextValue.authConfig, isAuthenticated, isAuthorized])

  if (isAuthenticated != null && isAuthorized) {
    return children
  } else {
    return <></>
  }
}
