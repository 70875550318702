import { UserManagerSettings } from "oidc-client"

export type AuthenticationProviderProps = {
    config: UserManagerSettings,
    children?: JSX.Element
}

export type AuthenticationContextType = {
    handleLoginButtonClick: () => void,
    authConfig: UserManagerSettings,
    authCallbackConfig: UserManagerSettings,
    authCallbackRoute: JSX.Element
}

export enum RedirectionType {
    Signin = "signin",
    Signout = "signout",
    SigninCallback = "signinCallback"
}