const ProposalsDisclaimerPdf = () => {
    return (
        <div className='p-10 print-page-break'>
            <div className='msds-text-header-3-light msds-text-gray-10 my-2'>
                Information
            </div>

            <div className='msds-text-header-6 msds-text-gray-10 mt-5'>
                Live and archive recording guidelines
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                If you're sizing your recording server to contain all the video that's being recorded, typically you only need a single live storage array. There are however instances where you may need or want to archive video to an external NAS/SAN. Video recording arrays should be configured to use a large drive strip size of  512KB and the Live and Archive Windows volumes should be configured using the 64K segment size, to provide the best performance. Array controllers should also be configured with battery backed cache, with write back cache settings enabled.
                If archiving is implemented, storage size assumes archiving 6 times per day. If archive periods will be more frequent, or less frequent, then the Live Database storage array numbers, or the Archive Database storage array numbers may need to be adjusted.
                <br /> <br />
                If you are archiving to external storage, then we recommend that your Live array is large enough to continue recording in the event of the external storage having an issue. I.e, so you can record until the external storage issue is resolved. We however always recommend having failover recording servers in place whenever recordings are mission critical.
            </div>

            <div className='msds-text-header-6 msds-text-gray-10 mt-5'>
                SSD drive guidelines
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                SSD drives are typically used for Live drive storage when you need archiving. When using SSD drives, it’s important to ensure that you are living up to the SSD manufacture´s best practices for DWPD. (Disk writes per day) This is a metric that ensures the SSD drives lifespan. A method of reducing DWPD, is to ensure the SSD array is dimensioned larger than otherwise required. Please discuss this with your SSD drive provider.
            </div>

            <div className='msds-text-header-6 msds-text-gray-10 mt-5'>
                Virtualization guidelines
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                XProtect components can be virtualized, though typically the recording server is not, due to its resource demands. If sufficient resources are present, it can also be virtualized. The Milestone Solution design tool assumes installation on physical servers, virtualized environments require VM resources to exceed those specifications. Virtualized recording servers need thick provisioned storage with dedicated resources. The management server's resources can be shared. Contact the Presales team for virtualization guidance.
            </div>


            <div className='msds-text-header-6 msds-text-gray-10 mt-5'>
                Speedup guidelines
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                Baseline is the constant “none event” framerate (here we recommend 1 FPS, also known as keyframe recording) and then speedup is your event FPS of choice. This guarantees the smoothest transition on FPS change due to how the H.264/5 codec works.
            </div>

            <div className='msds-text-header-6 msds-text-gray-10 mt-5'>
                Concurrent Smart client streams
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                The concurrent smart client stream number is the anticipated number of concurrent smart client streams “per” recording server.
            </div>

            <div className='msds-text-header-6 msds-text-gray-10 mt-5'>
                Camera split
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                All recording and failover recording server designs assume that there is an even mixture of cameras on each server. Example: If a proposal contains 2 or more servers and there are 100 * 4k Cameras and 200 * 1080p cameras then it’s assumed that there is an even split of the cameras on the servers.
            </div>

            <div className='msds-text-header-6 msds-text-gray-10 mt-5 print-page-break'>
                Network guidelines
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                Milestone recommends segmenting camera and recording server traffic. Design a network infrastructure that uses physical network or VLAN segmentation.
            </div>

            <div id='osVersion' className='msds-text-header-6 msds-text-gray-10 mt-5'>
                Operating System for recording and Management Servers
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                Please ensure to use a supported operating system. For detailed information please review the: <a href='https://www.milestonesys.com/support/help-and-documentation/system-requirements/'>System Requirements</a>.
            </div>

            <div id='sqlInformation' className='msds-text-header-6 msds-text-gray-10 mt-5'>
                Management server SQL Information
            </div>
            <div className='msds-text-body-2 msds-text-gray-9'>
                The management server is the central VMS component. It stores the configuration of the surveillance system in a SQL database, either on a SQL Server on the management server or on a separate SQL Server. For detailed information please review the: <a href='https://doc.milestonesys.com/mc/pdf/2023r2/en-US/MilestoneXProtectVMSproducts_AdministratorManual_en-US.pdf'>XProtect VMS administrator manual</a>.
                <br />  <br />
                XProtect includes an instance of Microsoft SQL Server Express which is free to use, and typically supports up to 300 cameras. For installations over 300 cameras, Milestone recommends considering Microsoft SQL Server Essentials, Standard or Enterprise, on a dedicated server. These editions can handle larger databases, offer improved performance and offer more comprehensive management and monitoring options. For a listing of our supported SQL versions please review: <a href='https://www.milestonesys.com/support/help-and-documentation/system-requirements/'>Supported SQL versions</a>.
            </div>

            <div className='msds-text-header-3-light msds-text-gray-10 mt-10 mb-2 print-page-break'>
                Terms and Conditions associated with the use of Milestone Systems' "XProtect Solution Designer.”
            </div>

            <div className='msds-text-body-2 msds-text-gray-9'>
                Milestone Systems makes no express or implied warranties or representations directly or indirectly as to the accuracy or completeness of this XProtect Solution Designer (“Solution Designer”), including, but not limited to, server configuration calculations and installation advice provided by this Solution Designer (“Information”). This Solution Designer and information provided via this tool is provided “as is” without warranty of any kind, either expressed or implied, including, without limitation, the implied warranties of accuracy, reliability, suitability, fitness for a particular purpose, or non-infringement. The information is for general informational purposes only and does not constitute legal, sales, technical, installation, or other professional advice. By applying this Solution Designer and information, you acknowledge and agree that Milestone Systems and its affiliates shall not be held responsible or liable for any errors, omissions, or inaccuracies, nor for any consequences or damages resulting from the use of the provided information. Furthermore, Milestone Systems and its affiliates shall not be liable for loss of data, loss of production, loss of profit, loss of use, loss of contracts, or for any other consequential, economic, or indirect loss whatsoever in respect of delivery, use, or disposition from the tool and information.
                <br />
                Collection and Use of Information via the "XProtect Solution Designer.”
                <br />
                By providing information via this XProtect Solution Designer, you acknowledge and agree to the collection, use, and storage of non-personally identifiable data by Milestone Systems for the purpose of performance of this XProtect Solution Designer as well as Milestone’s analysis purposes. Rest assured that Milestone Systems will handle the collected data with utmost care and respect for confidentiality. All data will be anonymized, aggregated, and securely stored by Milestone for analysis purposes.
            </div>
        </div>
    )
}

export default ProposalsDisclaimerPdf