import { createAsyncThunk } from "@reduxjs/toolkit"
import axios, { AxiosError } from "axios"
import { API_URL } from "config"
import { DomainName } from "lib/enums"
import { DomainType } from "lib/types"

export type GetDomainPayload = {
    data?: DomainType[],
    domainName: DomainName,
    error?: string
}

export const getDomain = createAsyncThunk<GetDomainPayload, DomainName, { rejectValue: GetDomainPayload }>("domain/getDomain", async (domainName: DomainName, { rejectWithValue }) => {
    try {
        const apiResponse = await axios.get(`${API_URL()}/domain/${domainName}`)
        return { data: apiResponse.data, domainName }
    }
    catch (err) {
        const error: AxiosError<string> = err;
        return rejectWithValue({ error: error.message, domainName })
    }
})