import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import { useEffect, useState } from "react";

const AccordionItemComponent = ({ title, children, isDisabled, forceExpand }: AccordionItemProps): AccordionItem => {
    const [isExpanded, setExpanded] = useState(false)

    useEffect(() => {
        forceExpand && setExpanded(true)
    }, [forceExpand])

    useEffect(() => {
        isDisabled && setExpanded(false)
    }, [isDisabled])

    return (
        <div className="msds-accordion__list-item msds-accordion__list-item--animate-icon-rotate">
            <input 
                type="checkbox" 
                className="msds-accordion__list-item-input" 
                id={title} 
                disabled={isDisabled}
                checked={isExpanded}
                onChange={() => setExpanded(!isExpanded)}
                />
            <label className="msds-accordion__list-item-header" htmlFor={title}>
                <span className="msds-accordion__list-item-header-title">{title}</span>
                <span className="msds-accordion__list-item-header-icon">
                    <svg>
                        <use href={`${spritemap}#right-arrow`} />
                    </svg>
                </span>
            </label>
            <div className="msds-accordion__list-item-body">
                {children}
            </div>
        </div>
    )
}

export interface AccordionItem extends JSX.Element {}

type AccordionItemProps = {
    title: string,
    children: JSX.Element | JSX.Element[] | string,
    isDisabled?: boolean,
    forceExpand?: boolean
}

export default AccordionItemComponent