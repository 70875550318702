import { createAsyncThunk } from "@reduxjs/toolkit"
import axios, { AxiosError } from "axios"
import { API_URL } from "config"
import { FailoverConfiguration, OptionsSetup, StorageSetup } from "lib/types"

export const defaultStorageSetup: StorageSetup = {
    storageDevices: [],
    connectionType: { domainValueId: 1 },
    raidType: { domainValueId: 4 },
    deviceMaxSize: { bytes: 20000000000000 },
    deviceMinSize: { bytes: 0 },
    deviceType: { domainValueId: 1 }
}

export const defaultOptionsSetup: OptionsSetup = {
    systemOverheadPercentage: 0,
    archivesPerDay: 6,
    archiveStorageSetup: null,
    archiving: false,
    failoverConfiguration: null,
    liveStorageSetup: defaultStorageSetup,
    machineType: { domainValueId: 2 },
    maxCameras: 200,
    viewedStreams: 1,
    managementServerOnBoM: false,
    managementServerNumberOfDevices: 0,
    maxDisksPerServer: 0,
    raiD1ForOS: true
}

const defaultFailoverConfig: FailoverConfiguration = {
    liveStorageSetup: defaultStorageSetup,
    failoverMode: { domainValueId: 1 },
    numberOfFailoverServers: 1,
    retentionDays: 7
}

export type OptionsSetupPayload = {
    data?: OptionsSetup,
    workspaceId: number,
    error?: string
}

export type FailoverConfigurationPayload = {
    data?: FailoverConfiguration,
    workspaceId: number,
    error?: string
}

export const getOptionsSetup = createAsyncThunk<OptionsSetupPayload, number, { rejectValue: OptionsSetupPayload }>("optionsSetup/getOptionsSetup",
    async (workspaceId: number, { rejectWithValue }) => {
        try {
            const apiResponse = await axios.get(`${API_URL()}/user/workspace/${workspaceId}/optionssetup`)
            return { data: apiResponse.data, workspaceId }
        }
        catch (err) {
            const error: AxiosError<string> = err
            return rejectWithValue({ error: error.message, workspaceId })
        }
    })

export const putOptionsSetup = createAsyncThunk<OptionsSetupPayload, { workspaceId: number, optionsSetup: OptionsSetup }, { rejectValue: OptionsSetupPayload }>("optionsSetup/putOptionsSetup",
    async (args, { rejectWithValue }) => {
        try {
            const apiResponse = await axios.put(`${API_URL()}/user/workspace/${args.workspaceId}/optionssetup`, args.optionsSetup)
            return { data: apiResponse.data, workspaceId: args.workspaceId }
        }
        catch (err) {
            const error: AxiosError<string> = err
            return rejectWithValue({ error: error.message, workspaceId: args.workspaceId })
        }
    })

export const putFailoverConfig = createAsyncThunk<FailoverConfigurationPayload, { workspaceId: number, failoverConfig: FailoverConfiguration }, { rejectValue: FailoverConfigurationPayload }>("optionsSetup/putFailoverConfig",
    async (args, { rejectWithValue }) => {
        try {
            const apiResponse = await axios.put(`${API_URL()}/user/workspace/${args.workspaceId}/optionssetup/failoverconfiguration`, args.failoverConfig)
            return { data: apiResponse.data, workspaceId: args.workspaceId }
        }
        catch (err) {
            const error: AxiosError<string> = err
            return rejectWithValue({ error: error.message, workspaceId: args.workspaceId })
        }
    })

export const postFailoverConfig = createAsyncThunk<FailoverConfigurationPayload, { workspaceId: number, failoverConfig?: FailoverConfiguration }, { rejectValue: FailoverConfigurationPayload }>("optionsSetup/postFailoverConfig",
    async (args, { rejectWithValue }) => {
        try {
            const apiResponse = args.failoverConfig == null
                ? await axios.post(`${API_URL()}/user/workspace/${args.workspaceId}/optionssetup/failoverconfiguration`, defaultFailoverConfig)
                : await axios.put(`${API_URL()}/user/workspace/${args.workspaceId}/optionssetup/failoverconfiguration`, defaultFailoverConfig)
            return { data: apiResponse.data, workspaceId: args.workspaceId }
        }
        catch (err) {
            const error: AxiosError<string> = err
            return rejectWithValue({ error: error.message, workspaceId: args.workspaceId })
        }
    })

export const deleteFailoverConfig = createAsyncThunk<FailoverConfigurationPayload, { workspaceId: number, failoverConfig: FailoverConfiguration }, { rejectValue: FailoverConfigurationPayload }>("optionsSetup/deleteFailoverConfig",
    async (args, { rejectWithValue }) => {
        try {
            const apiResponse = await axios.put(`${API_URL()}/user/workspace/${args.workspaceId}/optionssetup/failoverconfiguration`, {
                numberOfFailoverServers: 0
            })
            return { data: apiResponse.data, workspaceId: args.workspaceId }
        }
        catch (err) {
            const error: AxiosError<string> = err
            return rejectWithValue({ error: error.message, workspaceId: args.workspaceId })
        }
    })