import { useNavigate } from 'react-router-dom';
import BackButton from 'components/Button/BackButton';

export const Discalimer = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className="container msds-block-padding-small">
            <div className="row">
                <div className='col-12 mb-8'>
                    <BackButton id={"goBack"} onClickHandler={goBack} label={"Back"}></BackButton>
                </div>
                <div className='col-12'>
                    <div className='msds-text-display-3-regular msds-text-display-3 msds-text-gray-10 mb-9'>Disclaimer</div>
                </div>

                <div className='col-12'>
                    <div className="msds-divider msds-divider--gray-5 mb-6"></div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>What's New</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        All information, to include but not limited to, documentation, configuration calculations, installation and trouble-shooting advice, consultancy and support services which may be provided, are delivered 'as is' without warranty of any kind. Unless otherwise agreed in writing between you and Milestone Systems A/S or its Affiliates, system integrator, as the recipient, agree to assume the entire risk as to the results and performance achieved or not achieved by reliance on such information.
                        <br /> <br />
                        Milestone Systems A/S and its Affiliates shall, to the extent allowed by law, assume no liability for the recipient’s reliance on such information, and disclaims all warranties, whether expressed, or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, or any warranty arising out of any proposal, specification, or sample with respect to the email.
                        <br /> <br />
                        Furthermore, Milestone Systems A/S and its Affiliates shall not be liable for loss of data, loss of production, loss of profit, loss of use, loss of contracts, or for any other consequential, economic, or indirect loss whatsoever in respect of delivery, use or disposition from the content of this solution design tools output.
                        <br /> <br />
                        All design figures assume an even distribution of cameras (quantity and resolution) across all Recording Servers.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Live database & SSD Disks</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        Live Database storage size assumes archiving 6 times per day. If archive periods will be more frequent, or less frequent, then the Live Database storage array numbers, or the Archive Database storage array numbers may need to be adjusted.
                        <br /> <br />
                        Arrays should be configured to use a large drive strip size of 256KB or 512KB and the Live and Archive Windows volumes should be configured using the 64K segment size, to provide the best performance. Array controllers should also be configured with battery backed cache, with write back cache settings enabled.
                        <br /> <br />
                        <b>DWPD</b> (Disk Writes per day), when using SSD disks, are important to ensure that you are living up to the SSD manufactures best practice for DWPD This is a metric that ensures the SSD disk’s lifespan.
                        <br /> <br />
                        A method of reducing the DWPD, is to ensure the SSD array is dimensioned larger than otherwise required. Please discuss this with your SSD disk provider.
                        <br /> <br />
                        If you are archiving to external storage, then we recommend that your Live array is ample large enough to continue recording in the event of the external storage having an issue. I.e so you can record until the external storage issue is resolved. We however always recommend having failover recording servers in place whenever recordings are mission critical.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Disk Notes</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        Enterprise grade drives designed with a minimum sustained transfer rate of 184 MB/s (1TB) to 269 MB/s (20TB).
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>Network Notes</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        Bandwidth calculations are done assuming 1 camera being viewed simultaneously from each server. Milestone recommends using at least two network cards so the client network and camera network can be sepa-rated. A minimum of two network cards are used in this configuration for that reason.
                        <br /> <br />
                    </div>

                    <div className='msds-text-body-0-bold msds-text-clear-blue'>SQL Maintenance Notes</div>
                    <div className='msds-text-body-0 msds-text-gray-10'>
                        SQL server should be set to a regular backup schedule and transaction logs truncated as part of the sched-uled backup process.
                        <br /> <br />
                    </div>
                </div>
            </div>
        </div>
    )
}
