import { useEffect, useState } from "react";
import { useTable, useSortBy, useExpanded, usePagination } from "react-table";
import GlobalFilter from "./GlobalFilter";
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import ModalPopup from "components/ModalPopup/ModalPopup";
import { ModalPopupSize, ButtonStyles, ButtonSize, ButtonGroupAlignment } from "lib/enums";
import "./Table.scss";
import Button from "components/Button/Button";
import classNames from "classnames";

function Table({
  initialData,
  tableColumns,
  disableSearch,
  addButtonLabel,
  addButtonLabelIcon,
  customButtons,
  handleAddDataRow,
  handleUpdateDataRow,
  handleDeleteDataRow,
  handleAddSubRow,
  handleDuplicateRow,
  isDoubleCell,
  deleteModalHeadline,
  deleteModalDescription,
  disablePagination }: TableProps) {
  const columns = tableColumns
  const [data, setData] = useState(initialData);


  useEffect(() => {
    setData(initialData)
  }, [initialData])

  //Delete Event Handler -------------------------------------------------------------   
  const [showModal, setShowModal] = useState(false);
  const [deleteActionRowIndex, setdeleteActionRowIndex] = useState(-1);
  const deleteClickHandler = (rowIndex) => {
    setShowModal(true)
    setdeleteActionRowIndex(rowIndex);
  };

  const onDeleteProject = () => {
    setShowModal(false)
    const dataRowId = data[deleteActionRowIndex].id
    handleDeleteDataRow && handleDeleteDataRow(dataRowId)
  };
  //Delete Event Handler -------------------------------------------------------------

  const handleFilterData = (newData) => {
    setData(newData);
  };

  const updateMyData = (rowIndex, columnId, value) => {
    if (handleUpdateDataRow) {
      handleUpdateDataRow({
        ...data[rowIndex],
        [columnId]: value
      }, columnId)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: disablePagination ? 1000 : 10, hiddenColumns: ["id"] },
      updateMyData
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  return (
    <>
      <div className="row">
        {addButtonLabel && <div className="msds-btn-group col-6 col-md-8">
          <Button
            label={addButtonLabel}
            icon={addButtonLabelIcon}
            onClickHandler={() => handleAddDataRow && handleAddDataRow()}
          />
          {customButtons}
        </div>}
        {disableSearch ? null : (
          <div className="col-6 col-md-4 mb-2 search-bar">
            <GlobalFilter
              data={initialData}
              handleFilterData={handleFilterData}
            />
          </div>
        )}
        <ModalPopup id="DeleteModal"
          headline={deleteModalHeadline}
          description={deleteModalDescription}
          size={ModalPopupSize.Small}
          showModal={showModal}
          onCloseButtonclick={() => setShowModal(false)}
          button1Style={ButtonStyles.Danger}
          button1Size={ButtonSize.Small}
          button1Label="yes, delete"
          button1OnClickHandler={onDeleteProject}
          button2Style={ButtonStyles.Tertiary}
          button2Size={ButtonSize.Small}
          button2Label="cancel"
          button2OnClickHandler={() => setShowModal(false)}
          buttonGroupAlignment={ButtonGroupAlignment.Center}
        />
        <div className="col-12">
          <div className={`msds-table msds-table--small ${isDoubleCell ? ' msds-table--double-cell' : ''}`}>
            <table {...getTableProps()} className="msds-table__table-style">
              <thead className="msds-table__head">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    key={index}
                    className="msds-table__head-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          key={index}
                          className={classNames(
                            "msds-table__header",
                            column.id === "actions" && "table__actions-header"
                          )}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div id={"xsd__e2e-table-column-" + column.id}
                            className=
                            "msds-table__header-headline">
                            {column.id !== "actions" ? column.render("Header") : ""}
                            {column.id !== "actions" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <span className="msds-table__header-order-icon">
                                    <svg className="msds-table__header-descending-order-icon">
                                      <use href={spritemap + "#right-arrow"} />
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="msds-table__header-order-icon">
                                    <svg className="msds-table__header-acesnding-order-icon">
                                      <use href={spritemap + "#right-arrow"} />
                                    </svg>
                                  </span>
                                )
                              ) : (
                                <span className="msds-table__header-order-icon">
                                  <svg className="msds-table__header-descending-order-icon">
                                    <use href={spritemap + "#right-arrow"} />
                                  </svg>
                                  <svg className="msds-table__header-acesnding-order-icon">
                                    <use href={spritemap + "#right-arrow"} />
                                  </svg>
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="msds-table__body" {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      id={"xsd__e2e-table-click-row-" + row.id}
                      key={index}
                      className="msds-table__body-row"
                      {...row.getRowProps()}
                    >
                      {(row.canExpand || columns[0].id !== "expander") &&
                        row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className="msds-table__body-row-data msds-table__body-row-clickable"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell", { 
                                deleteClickHandler, 
                                addClickHandler: (rowIndex: number) => handleAddSubRow(data[rowIndex].id), 
                                duplicateClickHandler: (rowIndex: number) => handleDuplicateRow(data[rowIndex].id) })}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {!disablePagination && <div className="msds-table__pagination msds-text-gray-10">
            <button
              id="xsd__e2e-pagination-go-to-first-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>{" "}
            <button
              id="xsd__e2e-pagination-go-to-previous-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>{" "}
            <span className="msds-table__pagination-indicator d-none d-md-inline-block">
              <span className="msds-table__pagination-indicator-status">
                {"Show"}{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <span>
                | {"Go to page"}:{" "}
                <input
                  id="xsd__e2e-pagination-go-to-page"
                  className="msds-input__text-input"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </span>{" "}
            </span>
            <button
              id="xsd__e2e-pagination-go-to-next-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>{" "}
            <button
              id="xsd__e2e-pagination-go-to-last-page"
              className="msds-table__pagination-prev-next-buttons msds-btn msds-btn--sm msds-btn--tertiary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span className="msds-table__pagination-indicator d-block d-md-none">
              <span className="msds-table__pagination-indicator-status">
                {"Show"}{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <span>
                | {"Go to"}:{" "}
                <input
                  id="xsd__e2e-pagination-mobile-go-to-page"
                  className="msds-input__text-input"
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </span>{" "}
            </span>
            <div className="msds-table__pagination-show-row-numbers">
              <select
                id="xsd__e2e-pagination-show-number-of-pages"
                className="msds-select-input__select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {"Show"} {pageSize}
                  </option>
                ))}
              </select>
              <div className="msds-select-input__icon">
                <svg>
                  <use href={spritemap + "#right-arrow"} />
                </svg>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
}

export default Table;

type TableProps = {
  initialData: any,
  tableColumns: any,
  disableSearch?: boolean,
  disablePagination?: boolean,
  addButtonLabel?: string,
  addButtonLabelIcon?: string,
  customButtons?: JSX.Element[],
  handleAddDataRow?: () => void,
  handleUpdateDataRow?: (dataRow: {}, columnId) => void,
  handleDeleteDataRow?: (dataRowId: string) => void,
  handleAddSubRow?: (dataRowId: string) => void,
  rowClickBaseUrl?: string,
  isDoubleCell?: boolean, 
  deleteModalDescription?: string,
  deleteModalHeadline?: string,
  handleDuplicateRow?: (dataRowId: number) => void
}