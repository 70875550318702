import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { API_URL } from 'config'
import { RecordingType } from 'lib/enums'
import { CameraConfig, CameraGroup, CameraRecording, CameraRecordingDataRow, CameraStream } from 'lib/types'

export const defaultRecording: CameraRecording = {
  codec: { domainValueId: 3 },
  hoursPerDay: 24,
  continuousFps: 0,
  continuousComplexity: { domainValueId: 2 },
  continuousResolution: { domainValueId: 8 },
  startTime: {},
  motionFps: 15,
  motionComplexity: { domainValueId: 2 },
  motionResolution: { domainValueId: 8 },
  continuousEnabled: false,
  motionEnabled: true,
  continuousUseUserBitrate: false,
  motionUseUserBitrate: true,
  motionPercentage: 40,
  continuousUserBitrate: { bitPerSec: 2702000, to_kbps: 2702 },
  motionUserBitrate: { bitPerSec: 2702000, to_kbps: 2702 }
}

export const defaultGroup: CameraGroup = {
  name: '',
  quantity: 1
}

export const defaultStream: CameraStream = {
  name: '',
  retentionDays: 30
}

//recording
export type CameraRecordingGetPayload = {
  data?: CameraConfig[]
  workspaceId: number
  error?: string
}
export const getCameraRecording = createAsyncThunk<CameraRecordingGetPayload, number, { rejectValue: CameraRecordingGetPayload }>(
  'cameraRecording/getCameraRecording',
  async (workspaceId: number, { rejectWithValue }) => {
    try {
      const apiResponse = await axios.get(`${API_URL()}/user/workspace/${workspaceId}/cameraconfig`)
      return { data: apiResponse.data, workspaceId }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId })
    }
  }
)

export type CameraRecordingUpsertPayload = {
  data?: CameraRecording
  workspaceId: number
  error?: string
  recording: CameraRecordingDataRow
}
export const putCameraRecording = createAsyncThunk<CameraRecordingUpsertPayload, { workspaceId: number; recording: CameraRecordingDataRow }, { rejectValue: CameraRecordingUpsertPayload }>(
  'cameraRecording/putCameraRecording',
  async (args, { rejectWithValue }) => {
    try {
      const apiResponse = await axios.put(
        `${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.recording.cameraConfigId}/cameragroup/${args.recording.cameraGroupId}/camerastream/${
          args.recording.cameraStreamId
        }/camerarecording/${args.recording.id}`,
        recordingDataRowToDto(args.recording)
      )
      return { data: apiResponse.data, workspaceId: args.workspaceId, recording: args.recording }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: args.workspaceId, recording: args.recording })
    }
  }
)

export const putCameraRecordingBitrate = createAsyncThunk<CameraRecordingUpsertPayload, { workspaceId: number; recording: CameraRecordingDataRow }, { rejectValue: CameraRecordingUpsertPayload }>(
  'cameraRecording/putCameraRecordingBitrate',
  async (args, { rejectWithValue }) => {
    try {
      const recordingPayload = recordingDataRowToDto(args.recording)
      
      if (recordingPayload.continuousEnabled) {
        const bitratePayload = {
          complexityId: recordingPayload.continuousComplexity.domainValueId,
          codecId: recordingPayload.codec.domainValueId,
          resolutionId: recordingPayload.continuousResolution.domainValueId,
          continuousFramerate: recordingPayload.continuousFps
        }
        const bitrateResponse = await axios.post(`${API_URL()}/tools/calculatebitrate`, bitratePayload)
        if (bitrateResponse.data.bitsPerSec !== -1) {
          recordingPayload.continuousUserBitrate = { bitPerSec: bitrateResponse.data.bitsPerSec }
        }
      }

      if (recordingPayload.motionEnabled) {
        const bitratePayload = {
          complexityId: recordingPayload.motionComplexity.domainValueId,
          codecId: recordingPayload.codec.domainValueId,
          resolutionId: recordingPayload.motionResolution.domainValueId,
          motionEventFramerate: recordingPayload.motionFps
        }
        const bitrateResponse = await axios.post(`${API_URL()}/tools/calculatebitrate`, bitratePayload)
        if (bitrateResponse.data.bitsPerSec !== -1) {
          recordingPayload.motionUserBitrate = { bitPerSec: bitrateResponse.data.bitsPerSec }
        }
      }

      const recordingResponse = await axios.put(
        `${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.recording.cameraConfigId}/cameragroup/${args.recording.cameraGroupId}/camerastream/${
          args.recording.cameraStreamId
        }/camerarecording/${args.recording.id}`,
        recordingPayload
      )
      return { data: recordingResponse.data, workspaceId: args.workspaceId, recording: args.recording }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: args.workspaceId, recording: args.recording })
    }
  }
)

//camera group
export type CameraGroupPostPayload = {
  recording?: CameraRecording
  stream?: CameraStream
  group?: CameraGroup
  workspaceId: number
  cameraConfigId: number
  error?: string
}
export const postCameraGroup = createAsyncThunk<CameraGroupPostPayload, { workspaceId: number; cameraConfigId: number }, { rejectValue: CameraGroupPostPayload }>(
  'cameraRecording/postCameraGroup',
  async (args, { rejectWithValue }) => {
    try {
      const groupResponse = await axios.post(`${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.cameraConfigId}/cameragroup`, defaultGroup)
      const streamResponse = await axios.post(`${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.cameraConfigId}/cameragroup/${groupResponse.data.id}/camerastream`, defaultStream)
      const recordingResponse = await axios.post(
        `${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.cameraConfigId}/cameragroup/${groupResponse.data.id}/camerastream/${streamResponse.data.id}/camerarecording`,
        defaultRecording
      )
      return { workspaceId: args.workspaceId, recording: recordingResponse.data, stream: streamResponse.data, group: groupResponse.data, cameraConfigId: args.cameraConfigId }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: args.workspaceId, cameraConfigId: args.cameraConfigId })
    }
  }
)

export type CameraGroupPutPayload = {
  data?: CameraGroup
  workspaceId: number
  error?: string
  recording: CameraRecordingDataRow
}
export const putCameraGroup = createAsyncThunk<CameraGroupPutPayload, { workspaceId: number; recording: CameraRecordingDataRow }, { rejectValue: CameraGroupPutPayload }>(
  'cameraRecording/putCameraGroup',
  async (args, { rejectWithValue }) => {
    try {
      const requestPayload: CameraGroup = {
        quantity: args.recording.quantity,
        name: ''
      }
      const apiResponse = await axios.put(`${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.recording.cameraConfigId}/cameragroup/${args.recording.cameraGroupId}`, requestPayload)
      return { data: apiResponse.data, workspaceId: args.workspaceId, recording: args.recording }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: args.workspaceId, recording: args.recording })
    }
  }
)

export type CameraGroupDeletePayload = {
  groupId: number
  configId: number
  workspaceId: number
  error?: string
}
export const deleteCameraGroup = createAsyncThunk<CameraGroupDeletePayload, { workspaceId: number; cameraGroupId: number; cameraConfigId: number }, { rejectValue: CameraGroupDeletePayload }>(
  'cameraRecording/deleteCameraGroup',
  async (args, { rejectWithValue }) => {
    try {
      const apiResponse = await axios.delete(`${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.cameraConfigId}/cameragroup/${args.cameraGroupId}`)
      return { data: apiResponse.data, workspaceId: args.workspaceId, groupId: args.cameraGroupId, configId: args.cameraConfigId }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: args.workspaceId, groupId: args.cameraGroupId, configId: args.cameraConfigId })
    }
  }
)

export type CameraGroupCopyPayload = {
  group?: CameraGroup
  workspaceId: number
  cameraConfigId: number
  error?: string
}
export const copyCameraGroup = createAsyncThunk<CameraGroupCopyPayload, { workspaceId: number; cameraConfigId: number; cameraGroupId: number }, { rejectValue: CameraGroupCopyPayload }>(
  'cameraRecording/copyCameraGroup',
  async ({ workspaceId, cameraConfigId, cameraGroupId }, { rejectWithValue }) => {
    try {
      const groupResponse = await axios.post(`${API_URL()}/user/workspace/${workspaceId}/cameraconfig/${cameraConfigId}/cameragroup/${cameraGroupId}/copy`)

      return { workspaceId: workspaceId, group: groupResponse.data, cameraConfigId: cameraConfigId }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: workspaceId, cameraConfigId: cameraConfigId })
    }
  }
)

//camera stream
export type CameraStreamPutPayload = {
  data?: CameraStream
  workspaceId: number
  error?: string
  recording: CameraRecordingDataRow
}
export const putCameraStream = createAsyncThunk<CameraStreamPutPayload, { workspaceId: number; recording: CameraRecordingDataRow }, { rejectValue: CameraStreamPutPayload }>(
  'cameraRecording/putCameraStream',
  async (args, { rejectWithValue }) => {
    try {
      const requestPayload: CameraStream = {
        retentionDays: args.recording.retentionDays,
        name: ''
      }
      const apiResponse = await axios.put(
        `${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.recording.cameraConfigId}/cameragroup/${args.recording.cameraGroupId}/camerastream/${args.recording.cameraStreamId}`,
        requestPayload
      )
      return { data: apiResponse.data, workspaceId: args.workspaceId, recording: args.recording }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: args.workspaceId, recording: args.recording })
    }
  }
)

export type CameraStreamPostPayload = {
  recording?: CameraRecording
  stream?: CameraStream
  workspaceId: number
  error?: string
  cameraGroupId: number
  cameraConfigId: number
}
export const postCameraStream = createAsyncThunk<CameraStreamPostPayload, { workspaceId: number; cameraGroupId: number; cameraConfigId: number }, { rejectValue: CameraStreamPostPayload }>(
  'cameraRecording/postCameraStream',
  async (args, { rejectWithValue }) => {
    try {
      const streamResponse = await axios.post(`${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.cameraConfigId}/cameragroup/${args.cameraGroupId}/camerastream/`, defaultStream)
      const recordingResponse = await axios.post(
        `${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.cameraConfigId}/cameragroup/${args.cameraGroupId}/camerastream/${streamResponse.data.id}/camerarecording`,
        defaultRecording
      )
      return { recording: recordingResponse.data, stream: streamResponse.data, workspaceId: args.workspaceId, cameraGroupId: args.cameraGroupId, cameraConfigId: args.cameraConfigId }
    } catch (err) {
      const error: AxiosError<string> = err
      return rejectWithValue({ error: error.message, workspaceId: args.workspaceId, cameraGroupId: args.cameraGroupId, cameraConfigId: args.cameraConfigId })
    }
  }
)

export type CameraStreamDeletePayload = {
  streamIds: number[]
  workspaceId: number
  cameraGroupId: number
  error?: string
}
export const deleteCameraStream = createAsyncThunk<
  CameraStreamDeletePayload,
  { workspaceId: number; cameraGroupId: number; cameraConfigId: number; streamIds: number[] },
  { rejectValue: CameraStreamDeletePayload }
>('cameraRecording/deleteCameraStream', async (args, { rejectWithValue }) => {
  try {
    for (const streamId of args.streamIds) {
      await axios.delete(`${API_URL()}/user/workspace/${args.workspaceId}/cameraconfig/${args.cameraConfigId}/cameragroup/${args.cameraGroupId}/camerastream/${streamId}`)
    }

    return { streamIds: args.streamIds, workspaceId: args.workspaceId, cameraGroupId: args.cameraGroupId }
  } catch (err) {
    const error: AxiosError<string> = err
    return rejectWithValue({ error: error.message, streamIds: args.streamIds, workspaceId: args.workspaceId, cameraGroupId: args.cameraGroupId })
  }
})

const recordingDataRowToDto = (recording: CameraRecordingDataRow): CameraRecording => {
  const continuousEnabled = recording.recording === RecordingType.Constant || recording.recording === RecordingType.Speedup
  const motionEnabled = recording.recording === RecordingType.Event || recording.recording === RecordingType.Speedup

  return {
    startTime: {},
    hoursPerDay: recording.hoursPerDay,
    codec: recording.codec ? { domainValueId: parseInt(recording.codec) } : null,
    continuousFps: recording.fps.continuous,
    continuousResolution: recording.resolution.continuous ? { domainValueId: parseInt(recording.resolution.continuous) } : null,
    continuousComplexity: recording.complexity.continuous ? { domainValueId: parseInt(recording.complexity.continuous) } : null,
    continuousEnabled: continuousEnabled,
    motionFps: recording.fps.motion,
    motionResolution: recording.resolution.motion ? { domainValueId: parseInt(recording.resolution.motion) } : null,
    motionComplexity: recording.complexity.motion ? { domainValueId: parseInt(recording.complexity.motion) } : null,
    motionEnabled: motionEnabled,
    motionPercentage: motionEnabled ? recording.motion.motion : recording.motion.continuous,
    continuousUserBitrate: { bitPerSec: recording.bitrateKbit.continuous * 1000 },
    continuousUseUserBitrate: continuousEnabled,
    motionUserBitrate: { bitPerSec: recording.bitrateKbit.motion * 1000 },
    motionUseUserBitrate: motionEnabled
  }
}
