import { useEffect, useCallback, useContext } from 'react'
import { AuthenticationContext } from './AuthenticationProvider'
import { RedirectionType } from './types'
import { useAuthentication, redirectToIDP } from './authenticationUtilities'
import { useAppSelector } from 'reducers/hooks'
import { selectIsAuthenticated } from 'reducers/userSlice'

function AuthenticationCallbackPage() {
  const setAuthorization = useAuthentication()
  const authenticationContext = useContext(AuthenticationContext)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const requestToken = useCallback(async () => {
    if (!isAuthenticated) {
      await redirectToIDP(authenticationContext.authCallbackConfig, RedirectionType.SigninCallback)
      setAuthorization(authenticationContext.authConfig, true)
    }
  }, [authenticationContext, setAuthorization, isAuthenticated])

  //Request access token on the page load. Takes authorization code sent by IDP (SSO) and exchange it for access token.
  useEffect(() => {
    requestToken()
  }, [requestToken])

  return <></>
}

export default AuthenticationCallbackPage
