import Table from 'components/Table/Table';
import BackButton from "components/Button/BackButton"
import { getSitesThunk, deleteSiteThunk, updateSiteThunk, postSiteThunk, duplicateSite } from 'reducers/api/sites/action';
import { selectSites, selectStatus } from 'reducers/api/sites/sitesSlice'
import { useApiData, useAppDispatch } from 'reducers/hooks'
import { SitesColumns } from './SitesColumns';
import { SiteType } from 'lib/types';
import { useParams, useNavigate } from 'react-router-dom';
import { getProjectsThunk } from 'reducers/api/projects/action';
import { getWorkspaces } from 'reducers/api/workspace/workspaceActions';

const Sites = () => {
    const search = useParams()
    const navigate = useNavigate()
    const sitesData = useApiData(getSitesThunk(search.projectId), selectSites(search.projectId), selectStatus(search.projectId));   
    const dispatch = useAppDispatch();  
    const projectsData = useApiData(getProjectsThunk(), state => state.projects.data, state => state.projects.projectsStatus);
    const project = projectsData.find(project => project.id.toString() === search.projectId)
    const navigateBackToProjects = () => {
        navigate("/");
    }
    const addSite = () => {
        dispatch(postSiteThunk({ projectId: search.projectId, siteName:`Site ${sitesData.length + 1}`}))
    }  
    const deleteSite = (_siteId:string) => {       
        dispatch(deleteSiteThunk({ projectId: search.projectId, siteId:_siteId }))
    }
    const updateSite = (site: SiteType) => {
        dispatch(updateSiteThunk(site))
    }
    const duplicate = (siteId: number) => {
        dispatch(duplicateSite({ siteId, projectId: parseInt(search.projectId) }))
            .then(() => dispatch(getWorkspaces()))
    }

    return (
        <div className="container msds-block-padding-small">
            <div className="row">
                <div className="col-12">
                    <BackButton id={search.projectId} onClickHandler={navigateBackToProjects} label={"Back to Projects"}></BackButton>
                </div>
                <h1 className="msds-text-header-1" >{project?.name}</h1>
                <Table
                    tableColumns={SitesColumns}
                    initialData={sitesData}
                    rowClickBaseUrl={"/sites/"}
                    addButtonLabel="Add site"
                    addButtonLabelIcon="plus"
                    handleAddDataRow={addSite}
                    handleUpdateDataRow={updateSite}
                    handleDeleteDataRow={deleteSite}
                    handleDuplicateRow={duplicate}
                    deleteModalHeadline="Are you sure you want to delete this site?"
                    deleteModalDescription="By deleting this site, you will remove this site's configuration and all site designs that have been setup for this site"
                />
            </div>
        </div>
    )
}

export default Sites