export const toShortDate = (dateString) => {
  if (dateString) {
    let date = new Date(dateString);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0");
    let yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  }
  return "";
};

export const enumToObject = <EnumType, ObjectType>(enumType: EnumType, defaultValue: any): ObjectType => {
  const enumKeys = Object.values(enumType)
  const object = enumKeys.reduce((domains, domainName) => {
    domains[domainName] = defaultValue
    return domains
  }, {})

  return object as ObjectType
}

export const formatValueString = (mbValueString, isBitPerSec, digitNumber) => {
  if (mbValueString) {
    if (mbValueString >= 1000000) {
      return toTbValueString(mbValueString / 1000000, isBitPerSec, digitNumber);
    }

    if (mbValueString >= 1000) {
      return toGbValueString(mbValueString / 1000, isBitPerSec, digitNumber);
    }

    return toMbValueString(mbValueString, isBitPerSec, digitNumber);
  }

  return mbValueString;
};

export const toMbValueString = (mbString, isBitPerSec, digitNumber) => {
  if (mbString) {
    const mbValueString = (digitNumber > 0 ? mbString.toFixed(digitNumber) : mbString) + (isBitPerSec ? "Mbps" : "MBps");
    return mbValueString;
  }
  return mbString;
};

export const toGbValueString = (gbString, isBitPerSec, digitNumber) => {
  if (gbString) {
    const gbValueString = (digitNumber > 0 ? gbString.toFixed(digitNumber) : gbString) + (isBitPerSec ? "Gbps" : "GB");
    return gbValueString;
  }
  return gbString;
};

export const toTbValueString = (tbString, isBitPerSec, digitNumber) => {
  if (tbString) {
    const mbValueString = (digitNumber > 0 ? tbString.toFixed(digitNumber) : tbString) + (isBitPerSec ? "Tbps" : "TB");
    return mbValueString;
  }
  return tbString;
};


export const enumToOptionList = <EnumType>(enumType: EnumType): { label: string, value: string }[] =>
  Object.values(enumType).filter(value => !value.includes(':hidden')).map(key => ({ label: key, value: key }))

export const quantityLabel = (connectivity) => {    
  if(connectivity && connectivity.domainValueId && connectivity.domainValueId == 1)
  {
    return "quantity per server";
  }

  return "quantity";
}