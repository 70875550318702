import React, { useState } from "react"
import { TabItemType } from "./TabItem"

const Tabs = ({ children }: TabsProps) => {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

    return (
        <div className="tabs msds-tabs msds-shadow-medium">
            <div className="msds-tabs__container">
                <div className="msds-tabs__tabs-container container-fluid">
                    {React.Children.map(children, (child, index) =>
                        <div
                            className={`msds-tabs__tab ${index === activeTabIndex ? 'msds-tabs__tab--active' : ''}`}
                            onClick={() => setActiveTabIndex(index)}>
                            <div className="msds-tabs__tab-text">{child.props.tabTitle}</div>
                        </div>
                    )}
                </div>
            </div>
            {React.Children.map(children, (child, index) =>
                    <div className={`msds-tabs__content-container p-4 ${index === activeTabIndex ? 'msds-tabs__content-container--visible' : ''}`}>
                        {child.props.children}
                    </div>
                )}
        </div>
    )
}

type TabsProps = {
    children: TabItemType[]
}

export default Tabs