import './proposals.scss'
import '../print.scss'
import { ManagementServerConfig, ServerRoles, StorageRoles } from 'lib/enums'
import { Server, Storage } from 'lib/types'
import { getCameraRecording } from 'reducers/api/cameraRecording/cameraRecordingActions'
import { getOptionsSetup } from 'reducers/api/optionsSetup/optionsSetupActions'
import { getCalculation } from 'reducers/api/workspace/workspaceActions'
import { useApiData, useAppSelector } from 'reducers/hooks'
import ProposalContactCard from './ProposalContactCard'
import ProposalTable from './ProposalTable'
import { WorkspaceDetails } from 'reducers/api/workspace/workspaceSlice'
import { toMbValueString, toGbValueString, toTbValueString, quantityLabel } from 'lib/helpers'

const Proposals = ({ workspace }: ProposalsProps) => {
  const { workspaceId, siteDesignName, updatedUtc } = workspace ?? {}
  const proposals = useApiData(
    getCalculation({ workspaceId }),
    state => state.workspace.proposals[workspaceId],
    state => state.workspace.proposalStatuses[workspaceId]
  )
  const optionsSetup = useApiData(
    getOptionsSetup(workspaceId),
    state => state.optionsSetup.data[workspaceId],
    state => state.optionsSetup.statuses[workspaceId]
  )
  const cameraRecordings = useApiData(
    getCameraRecording(workspaceId),
    state => state.cameraRecording.data[workspaceId],
    state => state.cameraRecording.statuses[workspaceId]
  )
  const userData = useAppSelector(state => state.user.userData)
  const getServer = (serverRole: ServerRoles): Server => proposals?.preferredSolution?.servers?.find(server => server.role.name === serverRole)
  const getStorageDevice = (serverRole: ServerRoles, storageRole: StorageRoles): Storage => getServer(serverRole)?.storageConfigurations?.find(device => device.role.name === storageRole)
  const recording = getServer(ServerRoles.Recording)
  //const recordingSystem = getStorageDevice(ServerRoles.Recording, StorageRoles.System);
  const recordingLive = getStorageDevice(ServerRoles.Recording, StorageRoles.Live)
  const archivingLive = getStorageDevice(ServerRoles.Recording, StorageRoles.Archive)
  // const failover = getServer(ServerRoles.Failover)
  // const failoverSystem = getStorageDevice(ServerRoles.Failover, StorageRoles.System)
  // const failoverLive = getStorageDevice(ServerRoles.Failover, StorageRoles.Live)
  const tableHeaders = {
    managmentServers: ['processor type', 'os disk', 'disk type', 'ram'],
    databaseStorage: ['quantity', 'disk type', 'disk size', 'raid type'],
    sqlEdition: optionsSetup?.managementServerNumberOfDevices < 4
      ? ['SQL Edition', '']
      : ['SQL Edition', 'Please contact presales for an optimized design']
  }

  const managementProposals: {
    [id: number]: {
      system: string[][];
      storage: string[][];
      sqlEdition: string[][]
    }
  } = {
    [ManagementServerConfig.MsConfig1]: {
      system: [['Intel XEON 3408u', '2 x 300GB Raid 1', 'SSD', '16GB RAM']],
      storage: [['2', 'SSD', '300GB', 'RAID1']],
      sqlEdition: [['SQL Express or higher', '']]
    },
    [ManagementServerConfig.MsConfig2]: {
      system: [['Intel XEON 4410y', '2 x 300GB Raid 1', 'SSD', '32GB RAM']],
      storage: [['4', 'SSD', '300GB', 'RAID10']],
      sqlEdition: [['SQL Standard or higher', '']]
    },
    [ManagementServerConfig.MsConfig3]: {
      system: [['Intel XEON 4416+', '2 x 300GB Raid 1', 'SSD', '32GB RAM']],
      storage: [['8', 'SSD', '300GB', 'RAID10']],
      sqlEdition: [['SQL Standard or higher', '']]
    },
    [ManagementServerConfig.MsConfig4]: {
      system: [['Dual Intel XEON 4416+', '2 x 300GB Raid 1', 'SSD', '64GB RAM']],
      storage: [['12', 'SSD', '300GB', 'RAID10']],
      sqlEdition: [['SQL Standard or higher', '']]
    }
  }

  const getManagmentConfig = (numberOfDevices: number): ManagementServerConfig => {
    if (numberOfDevices === ManagementServerConfig.Auto) {
      const numberOfCameras = cameraRecordings?.reduce((sum, recording) => sum + recording.quantity, 0) ?? 0
      if (numberOfCameras < 301) {
        return ManagementServerConfig.MsConfig1
      } else if (numberOfCameras < 1201) {
        return ManagementServerConfig.MsConfig2
      } else if (numberOfCameras < 2501) {
        return ManagementServerConfig.MsConfig3
      }
      return ManagementServerConfig.MsConfig4
    }

    return numberOfDevices
  }

  const getDate = (): string => {
    const date = new Date(updatedUtc)
    const [month, day, year] = [date.toLocaleString('default', { month: 'long' }), date.getDate(), date.getFullYear()]
    return `${month} ${day}, ${year}`
  }

  const printPdf = () => {
    window.print()
  }

  const isError = !['Success', undefined].includes(proposals?.status)  

  return (
    <div className="xsd__proposal">
      {isError && (
        <div className="msds-shadow-medium my-8 px-6 py-6 msds-text-body-0-bold msds-text-danger-red-1">
          <div>We have run into an error while trying to calculate proposals</div>
          <div>{proposals?.error}</div>
        </div>
      )}
      <div className="msds-shadow-medium my-8 px-6 py-4">
        <div className="row py-4">
          <div className="col-6">
            <div className="msds-text-header-2-bold py-4">Proposal</div>
            <div className="msds-text-body-0 pb-2">Configuration Date: {getDate()}</div>
            <div className="msds-text-body-0 pb-2">Configuration Name: {siteDesignName}</div>
            <div className="msds-text-body-0 pb-2">Product: {optionsSetup?.xProtectPlatform?.name}</div>
            <div className="msds-text-body-0 pb-2">System Overhead: {optionsSetup?.systemOverheadPercentage}%</div>
          </div>
          <div className="col-3 offset-3">
            <ProposalContactCard userData={userData} />
          </div>
        </div>
        <div className="msds-text-header-3 py-2">Recording server</div>
        <div className="row">
          <div className="col-6">
            <ProposalTable
              title="recording server specification"
              head={['quantity', 'machine type', 'cpu type', 'os disk', 'ram']}
              body={[
                [
                  recording?.quantity,
                  optionsSetup?.machineType?.name,
                  recording?.cpuName,
                  `${recording?.systemDriveCount} x ${recording?.systemDriveCapacity?.to_GB?.toFixed(2)} GB ${recording?.systemDriveRedundancy ? 'RAID 1' : ''}`,
                  toGbValueString(recording?.memoryTotalSize?.to_GB, false, 0)
                ]
              ]}
            />
          </div>
          <div className="col-6">
            <ProposalTable
              title="network interface"
              head={['usage', 'speed', 'required bitrate', 'details']}
              body={recording?.networks?.map(network =>
                network.networkInterfaceCards.flatMap(nInterface => [
                  network.role.name,
                  toGbValueString(nInterface?.bandwidth?.to_Gbps, true, 2),
                  toMbValueString(network?.requiredBandwidth?.to_Mbps, true, 2),
                  (network.role.name?.toLowerCase() === "livedb" || network.role.name?.toLowerCase() === "archivedb") ?
                    (nInterface?.isFibreChannel ? "Fibre Channel" : "iSCSI") : ""
                ])
              )}
            />
          </div>
          <div className="col-6">
            <ProposalTable
              title="live database storage information"
              head={[quantityLabel(recordingLive?.connectivity), 'disk type', 'disk size']}
              body={[[recordingLive?.numberOfDevices,
              recordingLive?.device?.type?.name,
              toTbValueString(recordingLive?.device?.capacity?.to_TB, false, 2)]]}
              forceAlign={true}
            />
            <ProposalTable
              head={['raid type', 'Required Capacity', 'Formatted Capacity']}
              body={[[recordingLive?.raidType?.name == "RAID 10" ? "Raid 1/ Raid 10" : recordingLive?.raidType?.name,
              toTbValueString(recordingLive?.requiredCapacity?.to_TB, false, 3),
              toTbValueString(recordingLive?.totalCapacity?.to_TB, false, 2)]]}
              forceAlign={true}
            />
            <ProposalTable
              head={['connectivity', 'iops', 'mb/s']}
              body={[[recordingLive?.connectivity?.name,
              recordingLive?.requiredIOPS,
              toMbValueString(recordingLive?.requiredSpeed?.to_MBps, false, 2)]
              ]}
              forceAlign={true}
            />
          </div>
          <div className="col-6">
            {optionsSetup?.archiving && (
              <>
                <ProposalTable
                  title="archive database storage information"
                  head={[quantityLabel(archivingLive?.connectivity), 'disk type', 'disk size']}
                  body={[[archivingLive?.numberOfDevices,
                  archivingLive?.device?.type?.name,
                  toTbValueString(archivingLive?.device?.capacity?.to_TB, false, 2)]]}
                  forceAlign={true}
                />
                <ProposalTable
                  head={['raid type', 'Required Capacity', 'Formatted Capacity']}
                  body={[[archivingLive?.raidType?.name,
                  toTbValueString(archivingLive?.requiredCapacity?.to_TB, false, 3),
                  toTbValueString(archivingLive?.totalCapacity?.to_TB, false, 2)]]}
                  forceAlign={true}
                />
                <ProposalTable
                  head={['connectivity', 'iops', 'mb/s']}
                  body={[[
                    archivingLive?.connectivity?.name,
                    archivingLive?.requiredIOPS,
                    toMbValueString(archivingLive?.requiredSpeed?.to_MBps, false, 2)]]}
                  forceAlign={true}
                />
              </>
            )}
          </div>
        </div>
        {/* <div className="row justify-content-center">
                    <div className="msds-divider col-10 mt-8 mb-8"></div>
                </div> */}
        {/* <div className="msds-text-header-3 py-2">Failover Recording</div>
                <div className="msds-text-header-4 py-2">{optionsSetup?.failoverConfiguration?.failoverMode?.name}</div>
                <div className="row">
                    <div className="col-6">
                        <ProposalTable
                            title="failover recording server specification"
                            head={['quantity', 'machine type', 'cpu type', 'os disk', 'ram']}
                            body={[[failover?.quantity, optionsSetup?.machineType?.name, failover?.cpuName, failoverSystem?.device.name, toGb(failover?.memoryTotalSize.bytes)]]}
                        />
                    </div
                    <div className="col-6">
                        <ProposalTable
                            title="network interface"
                            head={['usage', 'speed', 'required bitrate', 'details']}
                            body={failover?.networks?.map(network => network.networkInterfaceCards.flatMap(nInterface =>
                                [network.role.name, toGbps(nInterface?.bandwidth?.bitPerSec), toMbps(nInterface?.reqBandwidth?.bitPerSec), nInterface?.description]))}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <ProposalTable
                            title="live database storage information"
                            head={['quantity', 'disk type', 'disk size']}
                            body={[[failoverLive?.numberOfDevices, failoverLive?.device.name, toTb(failoverLive?.device.capacity.bytes)]]}
                        />
                    </div>                
                    <div className="col-6">
                        <ProposalTable
                            head={['raid type', 'Required Capacity', 'Formatted Capacity']}
                            body={[[failoverLive?.raidType.name, failoverLive?.device.name, toTb(failoverLive?.device.capacity.bytes * failoverLive?.numberOfDevices)]]}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <ProposalTable
                            head={['connectivity', 'iops', 'mb/s']}
                            body={[[failoverLive?.connectivity.name, failoverLive?.totalIOPS, toMbps(failoverLive?.requiredSpeed.bitPerSec)]]}
                        />
                    </div>
                </div> 
                */}
        {optionsSetup?.managementServerOnBoM && (
          <>
            <div className="row justify-content-center">
              <div className="msds-divider col-10 mt-8 mb-8"></div>
            </div>
            <div className="msds-text-header-3 py-2">Management server</div>
            <div className="row">
              <div className="col-6">
                <ProposalTable
                  title="management server specification"
                  head={tableHeaders.managmentServers}
                  body={managementProposals[getManagmentConfig(optionsSetup.managementServerNumberOfDevices)].system}
                />
              </div>
              <div className="col-6">
                <ProposalTable
                  title="database storage information"
                  head={tableHeaders.databaseStorage}
                  body={managementProposals[getManagmentConfig(optionsSetup.managementServerNumberOfDevices)].storage}
                  forceAlign={true}
                />
                <ProposalTable head={tableHeaders.sqlEdition}
                  body={managementProposals[getManagmentConfig(optionsSetup.managementServerNumberOfDevices)].sqlEdition}
                  forceAlign={true}
                />
              </div>
            </div>
          </>
        )}
        <div className="msds-btn-group msds-btn-group--right no-print-container">
          <button
            type="button"
            className="msds-btn msds-btn--tertiary msds-btn--lg"
            onClick={() => {
              printPdf()
            }}>
            print pdf
          </button>
        </div>
      </div>
    </div>
  )
}

export type ProposalsProps = {
  workspace: WorkspaceDetails
}

export default Proposals
